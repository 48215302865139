import axios from 'axios';
import Swal from 'sweetalert2';

const axiosInstance = axios.create({
    //baseURL: 'http://127.0.0.1:4000'
    baseURL: process.env.REACT_APP_BACKEND_SERVER_URL
});

axiosInstance.interceptors.request.use(
    (config) => {

        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        // Si hay un token, agrega el encabezado de autorización con el token Bearer
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        // Si hay un user_id, agrega el encabezado con el user_id
        if (userId) {
            config.headers['user-id'] = userId;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use((response) => response, (error) => {

    if (error.response.status === 401) {
        
        let timerInterval;
        Swal.fire({
            title: "Sesión Expirada",
            html: "Serás redirigido a la página de inicio de sesión en <br></br> <b></b> segundos.",
            timer: 5000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft() ? Swal.getTimerLeft() / 1000 : 0}`;
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result) => {
            window.location = '/login';
        });
    }
    return Promise.reject(error);
});


export default axiosInstance;