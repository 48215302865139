import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './sortableItem';

const SortableList = SortableContainer(({ items, fetchData }) => {

    return (
        <div className="widget-list rounded mb-4">
            {items.map((value, index) => (
                <SortableItem key={`item-${value.id}`} index={index} value={value} fetchData={fetchData} />
            ))}
        </div>
    );
});

export default SortableList;