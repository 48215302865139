import React, { useEffect, useState } from 'react';
import axiosClient from '../../../../config/axios.js';
import { Link } from 'react-router-dom';

import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import { useAuth } from '../../../../context/AuthContext.js';

import SortableList from './sortableList';

function ShieldingTypes() {

	const [loading, setLoading] = useState(true);

	const [shieldingTypes, setShieldingTypes] = useState([]);


	const onSortEnd = ({ oldIndex, newIndex }) => {

		const values = { oldOrder: oldIndex, newOrder: newIndex };

		axiosClient.put('/shieldingTypes/updateOrder', values).then(response => {
			
			const updatedShieldingTypes = [...shieldingTypes];
			const movedElement = updatedShieldingTypes[oldIndex];

			// Elimina el elemento movido de su posición anterior
			updatedShieldingTypes.splice(oldIndex, 1);

			// Inserta el elemento en su nueva posición
			updatedShieldingTypes.splice(newIndex, 0, movedElement);

			// Reajusta los índices de los elementos afectados
			for (let i = Math.min(oldIndex, newIndex); i < updatedShieldingTypes.length; i++) {
				updatedShieldingTypes[i].order = i;
			}
			setShieldingTypes(updatedShieldingTypes);

			fetchData();
			/*Swal.fire({
				title: 'Registro exitoso.',
				text: 'Se ha registrado la nueva Categoría de grúa',
				icon: 'success',
				confirmButtonText: 'Cerrar',
				cancelButtonText: 'Cancelar',
				showCancelButton: false,
			});
			navigate('/catalogs/shieldingTypes');*/
		}).catch(err => {
			console.log(err.response.data);
			
			let message = '';
			if (err.response.data.original.code === '23505') {
				message = 'El nombre de la Categoría de grúa ya existe. Ingresa uno diferente.';
			} else {
				message = err.response.data.message;
			}
			/*Swal.fire({
				title: 'Ups!',
				text: message,
				icon: 'error',
				confirmButtonText: 'Cerrar',
				cancelButtonText: 'Cancelar',
				showCancelButton: false,
			});*/
		});
	};



	const fetchData = async () => {
		try {
			const response = await axiosClient.get(`/shieldingTypes/all`);
			setShieldingTypes(response.data.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);



	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
						<li className="breadcrumb-item">Configuración</li>
						<li className="breadcrumb-item active">Categorías de Blindaje</li>
					</ol>
					<h1 className="page-header mb-0">Categorías de Blindaje</h1>
				</div>
				<div className="ms-auto">
					<Link to={'/catalogs/shieldingTypes/new'} className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Nueva Categoría de Blindaje</Link>
				</div>
			</div>



			<div className="row mb-3">
				<div className="col-xl-12">
					<Panel>
						<PanelHeader>Listado de Categorías de Blindaje</PanelHeader>
						<PanelBody>

							{shieldingTypes && shieldingTypes.length > 0 ? (
								<>
									<div className="note alert-primary mb-2">
										<div className="note-icon"><i className="fa fa-lightbulb"></i></div>
										<div className="note-content">
											<h4><b>¿Cómo ordenar las Categorías de Blindaje?</b></h4>
											<p>
												Puedes ordenar las Categorías de Blindaje seleccionando, arrastrando y soltando cada una de ellas.
											</p>
										</div>
									</div>

									<SortableList items={shieldingTypes} onSortEnd={onSortEnd} fetchData={fetchData} />
								</>

							) : (
								<div className="note alert-warning mb-2">
									<div className="note-icon"><i className="fa-regular fa-face-frown-open"></i></div>
									<div className="note-content">
										<h4><b>No hay Categorías de Blindaje registradas</b></h4>
										<p>
											Puedes registrar Nuevas Categorías de Blindaje dando clic en el botón "Nueva Categoría de Blindaje"
										</p>
									</div>
								</div>
							)}


						</PanelBody>
					</Panel>
				</div>

			</div>


		</div>

	);
}

export default ShieldingTypes;