import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import axiosClient from '../../../../config/axios.js';


function VehicleModelFields({ values, handleChange, touched, errors, setFieldValue }) {

    const [brands, setBrands] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);

    useEffect(() => {

        axiosClient.get('/brands/all').then(response => {
            const formattedOptions = response.data.data.map(brand => ({
                value: brand.id,
                label: brand.brand_name
            }));
            setBrands(formattedOptions);
        }).catch(error => {
            console.error('Error fetching brands:', error);
        });

        axiosClient.get('/vehicleTypes/all').then(response => {
            const formattedOptions = response.data.data.map(vehicleType => ({
                value: vehicleType.id,
                label: vehicleType.vehicle_type_name
            }));
            setVehicleTypes(formattedOptions);
        }).catch(error => {
            console.error('Error fetching vehicleTypes:', error);
        });


    }, []);

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Tipo de Vehículo</Form.Label>
                    <Select
                        className={errors.vehicle_type_id ? 'is-invalid' : ''}
                        classNamePrefix="select"
                        value={vehicleTypes.find(vehicleType => vehicleType.value === values.vehicle_type_id)}
                        isClearable={true}
                        isSearchable={true}
                        placeholder='Seleccione una tipo de vehículo'
                        name="vehicle_type_id"
                        options={vehicleTypes}
                        onChange={(e) => {
                            setFieldValue("vehicle_type_id", e.value);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.vehicle_type_id}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Marca</Form.Label>
                    <Select
                        className={errors.brand_id ? 'is-invalid' : ''}
                        classNamePrefix="select"
                        value={brands.find(brand => brand.value === values.brand_id)}
                        isClearable={true}
                        isSearchable={true}
                        placeholder='Seleccione una marca'
                        name="brand_id"
                        options={brands}
                        onChange={(e) => {
                            setFieldValue("brand_id", e.value);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.brand_id}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Nombre del Modelo de Vehículo</Form.Label>
                    <Form.Control
                        type="text"
                        name="model_name"
                        placeholder="Nombre del Modelo de Vehículo"
                        value={values.model_name}
                        onChange={handleChange}
                        isInvalid={!!errors.model_name}
                        autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.model_name}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
        </>


    );
}

export default VehicleModelFields;