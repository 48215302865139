import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { getGeocode, getLatLng } from 'use-places-autocomplete';


const MapSearchLocation = () => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [map, setMap] = useState(null);
  const [locations, setLocations] = useState([
    { description: '5 DE MAYO, OAXACA', km: 225 },
{ description: 'AGENCIA DE POLICIA GUEVARA, OAXACA', km: 138 },
{ description: 'AGUA FRIA, OAXACA', km: 166 },
{ description: 'AGUA PEÑAFIEL, OAXACA', km: 480 },
{ description: 'AGUA ZARCA, OAXACA', km: 470 },
{ description: 'AGUA ZARCA TLAXIACO, OAXACA', km: 312 },
{ description: 'AMOZOC, PUEBLA', km: 345 },
{ description: 'ANIMAS TRUJANO, OAXACA', km: 20 },
{ description: 'ARROYO CHOAPAM, OAXACA', km: 331 },
{ description: 'ASADERO Y MARISCOS SANTA ROSA, OAXACA', km: 104 },
{ description: 'ASUNCION NOCHIXTLAN, OAXACA', km: 100 },
{ description: 'ATZOMPA, OAXACA', km: 12 },
{ description: 'AYOQUEZCO DE ALDAMA, OAXACA', km: 80 },
{ description: 'BAJOS DE CHILA, OAXACA', km: 368 },
{ description: 'BARRANCA FIERRO AGUA FRIA CAMPANAL, OAXACA', km: 165 },
{ description: 'BARRANCA GRANDE, OAXACA', km: 255 },
{ description: 'BARRANCA LARGA, OAXACA', km: 98 },
{ description: 'CAMINO A LAS SEDAS, OAXACA', km: 60 },
{ description: 'CANDELARIA LOXICHA, OAXACA', km: 345 },
{ description: 'CAÑADA CANDELARIA, OAXACA', km: 285 },
{ description: 'CAÑADA DE LEON, OAXACA', km: 60 },
{ description: 'CAÑON ALASVERDES, OAXACA', km: 177 },
{ description: 'CAPIILLA SAN JUDAS TADEO EL CERRITO, OAXACA', km: 247 },
{ description: 'CAPILLA DE GUADALUPE, OAXACA', km: 465 },
{ description: 'CAPUFE PLAZA DE COBRO NO 79 HUITZO, OAXACA', km: 45 },
{ description: 'CAPUFE PLAZA DE COBRO NO 89 TEHUANTEPEC, OAXACA', km: 357 },
{ description: 'CAPUFE PLAZA DE COBRO NO. 90, OAXACA', km: 379 },
{ description: 'CAPUFE PLAZA DE COBRO NO.90, OAXACA', km: 382 },
{ description: 'CARNIZUELO, OAXACA', km: 355 },
{ description: 'CASCADA DE NIEBLA DE COMALTEPEC ENTRADA, OAXACA', km: 245 },
{ description: 'CDMX CENTRO, OAXACA', km: 570 },
{ description: 'CENTRAL HIDROELECTRICA, OAXACA', km: 188 },
{ description: 'CENTRO ECOTURISTICO LAS AZUCENAS, OAXACA', km: 81 },
{ description: 'CERRO DEL VIDRIO, OAXACA', km: 288 },
{ description: 'CERRO METATE, OAXACA', km: 253 },
{ description: 'CERRO VERDE, OAXACA', km: 480 },
{ description: 'CHAVIO, OAXACA', km: 70 },
{ description: 'CIENEGA DE ZIMATLAN, OAXACA', km: 46 },
{ description: 'CIENEGUILLA, OAXACA', km: 81 },
{ description: 'CIUDAD IXTEPEC, OAXACA', km: 390 },
{ description: 'COLONIA EL MANANTIAL, OAXACA', km: 32 },
{ description: 'COMEDOR COMUNITARIO CASACADA DE NIEBLA, OAXACA', km: 240 },
{ description: 'COMEDOR YOANA, OAXACA', km: 233 },
{ description: 'COMEDOR YULI, OAXACA', km: 208 },
{ description: 'CONCEPCION CARRIZAL, OAXACA', km: 450 },
{ description: 'CORRAL DE PIEDRA, OAXACA', km: 208 },
{ description: 'COSAHUICAHUATLA, OAXACA', km: 480 },
{ description: 'COXCATLAN, OAXACA', km: 480 },
{ description: 'COYOTEPEC, OAXACA', km: 118 },
{ description: 'CRUCERO DE SAN BALTAZAR GUELAVIA, OAXACA', km: 79 },
{ description: 'DEQUETOTO, OAXACA', km: 129 },
{ description: 'DESVIACION A SAN MARTIN SOYOLAPAM, OAXACA', km: 286 },
{ description: 'DESVIACION DIJI NUU, OAXACA', km: 150 },
{ description: 'DICONSA SA DE CV UNIDAD OPERATIVA, OAXACA', km: 390 },
{ description: 'DIMENSIONADOS ORO VERDE, OAXACA', km: 58 },
{ description: 'DOMINGUILLO, OAXACA', km: 115 },
{ description: 'EL BOQUERON, OAXACA', km: 372 },
{ description: 'EL CAMARON YAUTEPEC, OAXACA', km: 185 },
{ description: 'EL CERRITO, OAXACA', km: 396 },
{ description: 'EL CHACAL, OAXACA', km: 145 },
{ description: 'EL GARABATILLO, OAXACA', km: 109 },
{ description: 'EL GRAMAL, OAXACA', km: 174 },
{ description: 'EL GUAYABO, OAXACA', km: 276 },
{ description: 'EL MANZANAL, OAXACA', km: 281 },
{ description: 'EL MOLINO, OAXACA', km: 214 },
{ description: 'EL MORRITO, OAXACA', km: 470 },
{ description: 'EL PEDIMENTO DE LA VIRGEN DE JUQUILA, OAXACA', km: 314 },
{ description: 'EL PORTILLO, OAXACA', km: 251 },
{ description: 'EL PORTILLO PAXTLAN, OAXACA', km: 259 },
{ description: 'EL PUNTO, OAXACA', km: 85 },
{ description: 'EL RODEO, OAXACA', km: 200 },
{ description: 'EL ROSARIO SAN ATONIO DE LA CAL, OAXACA', km: 10 },
{ description: 'EL SARGENTO, OAXACA', km: 253 },
{ description: 'EL TEMASCAL, OAXACA', km: 257 },
{ description: 'EL TLACUACHE, OAXACA', km: 343 },
{ description: 'EL TRAPICHE, OAXACA', km: 343 },
{ description: 'EL VADO, OAXACA', km: 108 },
{ description: 'EL VADO, OAXACA', km: 125 },
{ description: 'EL VERGEL, OAXACA', km: 228 },
{ description: 'EL VIGIA, OAXACA', km: 381 },
{ description: 'ELOXOCHITLAN, OAXACA', km: 480 },
{ description: 'ESCUELA PRIMARIA 5 DE MAYO, OAXACA', km: 254 },
{ description: 'ESCUELA TELESECUNDARIA LUIS DONALDO COLOSIO, OAXACA', km: 480 },
{ description: 'ESTAFETA, OAXACA', km: 169 },
{ description: 'EUCALIPTOS Y YAHUICHE, OAXACA', km: 14 },
{ description: 'GESTORIA COMUNITARIA LA CIENEGUILLA, OAXACA', km: 59 },
{ description: 'GONZALEZ GUARDADO VICENTE GUERRERO, OAXACA', km: 30 },
{ description: 'GUADALUPE, OAXACA', km: 470 },
{ description: 'GUELATAO DE JUAREZ, OAXACA', km: 121 },
{ description: 'GUEVEA DE HUMBOLT, OAXACA', km: 470 },
{ description: 'HACIENDA BLANCA, OAXACA', km: 25 },
{ description: 'HEROICA CIUDA DE EJUTLA DE CRESPO, OAXACA', km: 90 },
{ description: 'HIERBA SANTA, OAXACA', km: 316 },
{ description: 'HUAJUAPAM DE LEON, OAXACA', km: 220 },
{ description: 'HUAUTLA DE JIMENEZ, OAXACA', km: 480 },
{ description: 'HUAYAPAM, OAXACA', km: 18 },
{ description: 'ITALIKA REFACCIONES, OAXACA', km: 164 },
{ description: 'ITUNYOSO, OAXACA', km: 470 },
{ description: 'IXTLAN DE JUAREZ, OAXACA', km: 126 },
{ description: 'JUCHITAN, OAXACA', km: 380 },
{ description: 'LA ARENA, OAXACA', km: 411 },
{ description: 'LA BOQUILLA, OAXACA', km: 390 },
{ description: 'LA CABAÑA, OAXACA', km: 165 },
{ description: 'LA CIENEGA, OAXACA', km: 120 },
{ description: 'LA CRUZ, OAXACA', km: 348 },
{ description: 'LA CUMBRE, OAXACA', km: 199 },
{ description: 'LA DONSELLA, OAXACA', km: 284 },
{ description: 'LA ESMERALDA, OAXACA', km: 61 },
{ description: 'LA ESPERANZA, OAXACA', km: 270 },
{ description: 'LA LOBERA, OAXACA', km: 89 },
{ description: 'LA PITIONA YOGANA, OAXACA', km: 96 },
{ description: 'LA PUERTA DEL SOL, OAXACA', km: 230 },
{ description: 'LA SANGUIJUELA, OAXACA', km: 342 },
{ description: 'LA Y GRIEGA, OAXACA', km: 480 },
{ description: 'LACHAO, OAXACA', km: 295 },
{ description: 'LACHIDOLA, OAXACA', km: 470 },
{ description: 'LACHIVIXA, OAXACA', km: 460 },
{ description: 'LAGOS DE CHACAHUA, OAXACA', km: 395 },
{ description: 'LAS ANIMAS, OAXACA', km: 174 },
{ description: 'LAS HUERTAS, OAXACA', km: 334 },
{ description: 'LAS JUNTAS, OAXACA', km: 316 },
{ description: 'LAS LETRAS DE TEQUISISTLAN, OAXACA', km: 254 },
{ description: 'LAS MALVINAS, OAXACA', km: 488 },
{ description: 'LAS MARGARITAS, OAXACA', km: 138 },
{ description: 'LAS RAMAS, OAXACA', km: 470 },
{ description: 'LLANO DE LUMBRE, OAXACA', km: 452 },
{ description: 'LLANO LAS FLORES, OAXACA', km: 176 },
{ description: 'LOMA DE YAVEGO, OAXACA', km: 48 },
{ description: 'LOS LIMONES, OAXACA', km: 371 },
{ description: 'LOS MORALES, OAXACA', km: 219 },
{ description: 'LOS PINOS, OAXACA', km: 337 },
{ description: 'LOXICHA, OAXACA', km: 331 },
{ description: 'MACUILCUAUTITLA AJALPAN, PUEBLA', km: 480 },
{ description: 'MAGADALENA APASCO, OAXACA', km: 45 },
{ description: 'MAGDALENA APASCO, OAXACA', km: 45 },
{ description: 'MAGDALENA OCOTLAN, OAXACA', km: 68 },
{ description: 'MAGDALENA TEQUISISTLAN, OAXACA', km: 255 },
{ description: 'MATIAS ROMERO, OAXACA', km: 470 },
{ description: 'MAZAHUA, OAXACA', km: 470 },
{ description: 'MAZUNTE, OAXACA', km: 400 },
{ description: 'MI BELLO MIRADOR, OAXACA', km: 480 },
{ description: 'MIAHUATLAN DE PORFIRIO DIAZ, OAXACA', km: 120 },
{ description: 'MIRADOR DE CRISTAL IXTLAN DE JUAREZ, OAXACA', km: 134 },
{ description: 'MIRADOR DEL CERRO DE LOS CORAZONES, OAXACA', km: 140 },
{ description: 'MIRADOR TEHUACAN OAXACA, OAXACA', km: 216 },
{ description: 'MIRAMAR, OAXACA', km: 295 },
{ description: 'MONJAS, OAXACA', km: 114 },
{ description: 'NACALTEPEC, OAXACA', km: 88 },
{ description: 'NIEVES IXPANTEPEC, OAXACA', km: 505 },
{ description: 'NIZA VIAHUI, OAXACA', km: 364 },
{ description: 'OCOTLAN DE MORELOS, OAXACA', km: 50 },
{ description: 'OXIFUEL SANTIAGO MATATLAN, OAXACA', km: 72 },
{ description: 'PALA, OAXACA', km: 480 },
{ description: 'PALO GRANDE, OAXACA', km: 115 },
{ description: 'PALO GRANDE, OAXACA', km: 114 },
{ description: 'PALOMARES, OAXACA', km: 500 },
{ description: 'PARADOR LASS GUIE E, OAXACA', km: 80 },
{ description: 'PARADOR MONTE DEL TORO, OAXACA', km: 73 },
{ description: 'PARAJE CRUZ DE JUAN, OAXACA', km: 22 },
{ description: 'PARAJE ZETA, OAXACA', km: 236 },
{ description: 'PARROQUIA DE SAN SEBASTIAN ALCOMUNGA, OAXACA', km: 480 },
{ description: 'PARROQUIA SAN JOSE LLANO GRANDE, OAXACA', km: 111 },
{ description: 'PIE DEL CERRO, OAXACA', km: 363 },
{ description: 'PINOTEPA, OAXACA', km: 480 },
{ description: 'PLAN DE BASURA, OAXACA', km: 480 },
{ description: 'PLAN DE GUADALUPE, OAXACA', km: 480 },
{ description: 'PLAN DE GUADALUPE, OAXACA', km: 335 },
{ description: 'PLUMA HIDALGO, OAXACA', km: 345 },
{ description: 'POLLOS ASADOS CG, OAXACA', km: 365 },
{ description: 'PORTILLO SAN LUIS DEL RIO, OAXACA', km: 132 },
{ description: 'PRESA LICENCIADO MATIAS ROMERO, OAXACA', km: 50 },
{ description: 'PUEBLA DE ZARAGOZA', km: 370 },
{ description: 'PUEBLO NUEVO, OAXACA', km: 12 },
{ description: 'PUENTE ESCONDIDO, OAXACA', km: 387 },
{ description: 'PUENTE SAN BERNARDO, OAXACA', km: 107 },
{ description: 'PUERTO ANGEL, OAXACA', km: 390 },
{ description: 'PUERTO ANGELITO, OAXACA', km: 377 },
{ description: 'PUERTO ESCONDIDO, OAXACA', km: 375 },
{ description: 'PUERTO SAN BARTOLO, OAXACA', km: 220 },
{ description: 'PUERTO SOLEDAD MEXICO 182, OAXACA', km: 480 },
{ description: 'PUTLA VILLA DE GUERRERO, OAXACA', km: 480 },
{ description: 'RANCHERIA SANTOS, OAXACA', km: 207 },
{ description: 'RANCHO DE LOS AMADORES, OAXACA', km: 53 },
{ description: 'RANCHO EL COLORADO LOS TOROS, OAXACA', km: 75 },
{ description: 'RANCHO LA CHARCA, OAXACA', km: 323 },
{ description: 'RANCHO LA SOLEDAD, OAXACA', km: 210 },
{ description: 'RESIDENCIAL SANTA MARIA, OAXACA', km: 25 },
{ description: 'RESTAURANTE CABAÑA LOS ENCINOS, OAXACA', km: 89 },
{ description: 'RESTAURANTE COLIBRI SIERRA NORTE, OAXACA', km: 81 },
{ description: 'REYES MANTECON, OAXACA', km: 40 },
{ description: 'RINCON DE TLACOPAYAN, OAXACA', km: 117 },
{ description: 'RIO GRANDE, OAXACA', km: 380 },
{ description: 'RIO HILO, OAXACA', km: 262 },
{ description: 'ROBERTO OLIVARES ARELLANO, OAXACA', km: 325 },
{ description: 'SALINA CRUZ, OAXACA', km: 361 },
{ description: 'SAN AGUSTIN ATENANGO, OAXACA', km: 424 },
{ description: 'SAN AGUSTIN LOXICHA, OAXACA', km: 313 },
{ description: 'SAN AGUSTIN YATARENI, OAXACA', km: 16 },
{ description: 'SAN ANDRES CHICAHUAXTLA, OAXACA', km: 380 },
{ description: 'SAN ANDRES SINAXTLA, OAXACA', km: 107 },
{ description: 'SAN ANTONINO CASTILLO VELASCO, OAXACA', km: 50 },
{ description: 'SAN ANTONINO EL ALTO, OAXACA', km: 289 },
{ description: 'SAN ANTONIO DE LA CAL, OAXACA', km: 10 },
{ description: 'SAN ANTONIO DE LA CAL Y XOXOCOTLAN, OAXACA', km: 12 },
{ description: 'SAN BARTOLO COYOTEPEC, OAXACA', km: 25 },
{ description: 'SAN CRISTOBAL SUCHIXTLAHUACA CASETA, OAXACA', km: 175 },
{ description: 'SAN DIONISIO OCOTLAN, OAXACA', km: 61 },
{ description: 'SAN FRANCISCO TELIXTLAHUACA, OAXACA', km: 60 },
{ description: 'SAN FRANCISCO TUTLA, OAXACA', km: 14 },
{ description: 'SAN FRANCISCO YOSOCUTA, OAXACA', km: 258 },
{ description: 'SAN GABRIEL MIXTEPEC, OAXACA', km: 335 },
{ description: 'SAN GUILLERMO, OAXACA', km: 105 },
{ description: 'SAN ISIDRO MONJAS, OAXACA', km: 18 },
{ description: 'SAN JACINTO AMILPAS, OAXACA', km: 12 },
{ description: 'SAN JACINTO TLACOTEPEC, OAXACA', km: 470 },
{ description: 'SAN JERONIMO OTLA, OAXACA', km: 183 },
{ description: 'SAN JERONIMO TECOATL, OAXACA', km: 480 },
{ description: 'SAN JOSE DE GRACIA, OAXACA', km: 156 },
{ description: 'SAN JOSE DEL PACIFICO, OAXACA', km: 270 },
{ description: 'SAN JOSE MIAHUATLAN, OAXACA', km: 230 },
{ description: 'SAN JOSE MONTE VERDE, OAXACA', km: 144 },
{ description: 'SAN JOSES DEL CHILAR, OAXACA', km: 123 },
{ description: 'SAN JUAN ATEPEC, OAXACA', km: 177 },
{ description: 'SAN JUAN BAUTISTA CUICATLAN, OAXACA', km: 132 },
{ description: 'SAN JUAN BAUTISTA VALLE NACIONAL, OAXACA', km: 300 },
{ description: 'SAN JUAN CHILATECA, OAXACA', km: 44 },
{ description: 'SAN JUAN DE LOS CUES, OAXACA', km: 267 },
{ description: 'SAN JUAN LACHAO, OAXACA', km: 316 },
{ description: 'SAN JUAN LAJARCIA, OAXACA', km: 206 },
{ description: 'SAN JUAN TEPOSCOLULA, OAXACA', km: 152 },
{ description: 'SAN JUAN YUCUITA, OAXACA', km: 110 },
{ description: 'SAN LORENZO, OAXACA', km: 30 },
{ description: 'SAN LORENZO TEXMELUCAN, OAXACA', km: 288 },
{ description: 'SAN LUCAS, OAXACA', km: 338 },
{ description: 'SAN MARCOS ARTEAGA, OAXACA', km: 291 },
{ description: 'SAN MARCOS MESONCITO, OAXACA', km: 468 },
{ description: 'SAN MARTIN DE LOS CANSECOS, OAXACA', km: 76 },
{ description: 'SAN MARTIN HUAMELULPAM, OAXACA', km: 190 },
{ description: 'SAN MARTIN LACHILA, OAXACA', km: 95 },
{ description: 'SAN MARTIN TILCAJETE, OAXACA', km: 42 },
{ description: 'SAN MARTIN TOXPALAN, OAXACA', km: 288 },
{ description: 'SAN MATEO RIO HONDO, OAXACA', km: 270 },
{ description: 'SAN MATEO YUCUCUI, OAXACA', km: 114 },
{ description: 'SAN MIGUEL MIXTEPEC, OAXACA', km: 181 },
{ description: 'SAN MIGUEL PERAS, OAXACA', km: 298 },
{ description: 'SAN MIGUEL SUCHIXTEPEC, OAXACA', km: 290 },
{ description: 'SAN PABLO HUITZO, OAXACA', km: 60 },
{ description: 'SAN PABLO HUIXTEPEC, OAXACA', km: 60 },
{ description: 'SAN PABLO VILLA DE MITLA, OAXACA', km: 60 },
{ description: 'SAN PEDRO EL ALTO, OAXACA', km: 305 },
{ description: 'SAN PEDRO GUEGOREXE, OAXACA', km: 39 },
{ description: 'SAN PEDRO JUCHATENGO, OAXACA', km: 265 },
{ description: 'SAN PEDRO MIXTEPEC, OAXACA', km: 355 },
{ description: 'SAN PEDRO POCHUTLA, OAXACA', km: 375 },
{ description: 'SAN PEDRO TETITLAN, OAXACA', km: 228 },
{ description: 'SAN PEDRO TOTOLAPA, OAXACA', km: 100 },
{ description: 'SAN RAYMUNDO JALPAN, OAXACA', km: 32 },
{ description: 'SAN SEBASTIAN DE LAS GRUTAS, OAXACA', km: 157 },
{ description: 'SAN VICENTE LACHIXIO, OAXACA', km: 198 },
{ description: 'SANTA ANA TLACOPAYAN, OAXACA', km: 71 },
{ description: 'SANTA ANNA, OAXACA', km: 120 },
{ description: 'SANTA CATARINA JUQUILA, OAXACA', km: 324 },
{ description: 'SANTA CATARINA QUIANE, OAXACA', km: 47 },
{ description: 'SANTA CRUZ AMILPAS, OAXACA', km: 12 },
{ description: 'SANTA CRUZ MIXTEPEC, OAXACA', km: 132 },
{ description: 'SANTA CRUZ XOXOCOTLAN, OAXACA', km: 12 },
{ description: 'SANTA GERTRUDIS, OAXACA', km: 66 },
{ description: 'SANTA INES DEL MONTE, OAXACA', km: 134 },
{ description: 'SANTA MARIA CHACHOAPAM, OAXACA', km: 120 },
{ description: 'SANTA MARIA GUIENAGATI, OAXACA', km: 470 },
{ description: 'SANTA MARIA HUATULCO, OAXACA', km: 375 },
{ description: 'SANTA MARIA JACATEPEC, OAXACA', km: 318 },
{ description: 'SANTA MARIA JALAPA DEL MARQUES, OAXACA', km: 287 },
{ description: 'SANTA MARIA JALATENGO, OAXACA', km: 320 },
{ description: 'SANTA MARIA JALTIANGUIS, OAXACA', km: 143 },
{ description: 'SANTA MARIA MIXTEQUILLA, OAXACA', km: 351 },
{ description: 'SANTA MARIA NATIVITAS, OAXACA', km: 144 },
{ description: 'SANTA MARIA SOLA, OAXACA', km: 202 },
{ description: 'SANTA MARIA TECOMAVACA, OAXACA', km: 223 },
{ description: 'SANTA MARIA TEOPOXCO, OAXACA', km: 480 },
{ description: 'SANTA MARIA ZANIZA, OAXACA', km: 450 },
{ description: 'SANTIAGO ASUNCION, OAXACA', km: 494 },
{ description: 'SANTIAGO BUENA VISTA, OAXACA', km: 105 },
{ description: 'SANTIAGO ETLA, OAXACA', km: 35 },
{ description: 'SANTIAGO HUAXOLOTIPAC, OAXACA', km: 298 },
{ description: 'SANTIAGO LA GALERA, OAXACA', km: 332 },
{ description: 'SANTIAGO LACHIGUIRI, OAXACA', km: 470 },
{ description: 'SANTIAGO LAOYAGA, OAXACA', km: 406 },
{ description: 'SANTIAGO MATATLAN, OAXACA', km: 70 },
{ description: 'SANTIAGO TEXTITLAN, OAXACA', km: 425 },
{ description: 'SANTO DOMINGO TONALA, OAXACA', km: 387 },
{ description: 'SANTO DOMINGO YANHUITLAN, OAXACA', km: 135 },
{ description: 'SANTO TOMAS JALIEZA, OAXACA', km: 43 },
{ description: 'SANTO TOMAS TAMAZULAPAM, OAXACA', km: 245 },
{ description: 'SANTOS REYES NOPALA, OAXACA', km: 324 },
{ description: 'SEBASTIAN TUTLA, OAXACA', km: 14 },
{ description: 'SERVICIO MECANICO AUTOMOTRIZ, OAXACA', km: 240 },
{ description: 'SOLEDAD PIEDRA, OAXACA', km: 329 },
{ description: 'SUCURSAL TAMAZULAPAN, OAXACA', km: 196 },
{ description: 'TEHUACAN, PUEBLA', km: 260 },
{ description: 'TEHUANTEPEC, OAXACA', km: 345 },
{ description: 'TELESECUNDARIA DEL AGUACATE, OAXACA', km: 303 },
{ description: 'TEMPLO DE SANTA MARIA YOLOTEPEC, OAXACA', km: 297 },
{ description: 'TEOTITLAN DE FLORES MAGON, OAXACA', km: 480 },
{ description: 'TEPEJILOTE, OAXACA', km: 470 },
{ description: 'TEPELMEME VILLA DE MORELOS, OAXACA', km: 195 },
{ description: 'TEPOSCOLULA, OAXACA', km: 155 },
{ description: 'TIERRA NEGRA, OAXACA', km: 355 },
{ description: 'TLACOLULA DE MATAMOROS, OAXACA', km: 45 },
{ description: 'TLACXITLA, OAXACA', km: 480 },
{ description: 'TLAXIACO, OAXACA', km: 220 },
{ description: 'TONALTEPEC, OAXACA', km: 98 },
{ description: 'TONAMECA, OAXACA', km: 400 },
{ description: 'TRAPICHE SANTA CRUZ, OAXACA', km: 104 },
{ description: 'TRES CRUCES, OAXACA', km: 432 },
{ description: 'TRES DE MAYO, OAXACA', km: 356 },
{ description: 'TUXTEPEC, OAXACA', km: 345 },
{ description: 'VADO, OAXACA', km: 125 },
{ description: 'VALDE FLORES, OAXACA', km: 100 },
{ description: 'VILLA DE ETLA, OAXACA', km: 40 },
{ description: 'VILLA DE TAMAZULAPAN DEL PROGRESO, OAXACA', km: 180 },
{ description: 'VILLA DE TEJUPAN, OAXACA', km: 170 },
{ description: 'VILLA DE ZACHILA, OAXACA', km: 40 },
{ description: 'VILLA DIAZ ORDAZ, OAXACA', km: 80 },
{ description: 'VILLA SOLA DE VEGA, OAXACA', km: 152 },
{ description: 'VIVERO LA MONTAÑA, OAXACA', km: 480 },
{ description: 'XIA IXTEPEJI, OAXACA', km: 112 },
{ description: 'YAHUICHE, OAXACA', km: 14 },
{ description: 'YEGOGENDA, OAXACA', km: 455 },
{ description: 'YOLOMECATL, OAXACA', km: 172 },
{ description: 'YOSOCAHANU, OAXACA', km: 358 },
{ description: 'ZIMATLAN DE ALVAREZ, OAXACA', km: 55 },
    // ... Agrega más ubicaciones aquí
  ]);

  const centerCoordinate = { lat: 17.062165, lng: -96.714167 };

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();

      const updatedLocations = async () => {
        const updated = await Promise.all(
          locations.map(async (location) => {
            try {
              const results = await getGeocode({ address: location.description });
              const { lat, lng } = await getLatLng(results[0]);
              bounds.extend({ lat, lng });
              const numberFound = results.length;

              // Get distance and duration using Distance Matrix API
              const distanceMatrixService = new window.google.maps.DistanceMatrixService();
              const response = await new Promise((resolve) =>
                distanceMatrixService.getDistanceMatrix(
                  {
                    origins: [centerCoordinate],
                    destinations: [{ lat, lng }],
                    travelMode: 'DRIVING',
                  },
                  (result, status) => {
                    if (status === 'OK') {
                      resolve(result);
                    } else {
                      console.error('Error fetching distance matrix:', status);
                      resolve(null);
                    }
                  }
                )
              );

              if (response) {
                const distance = response.rows[0].elements[0].distance.text;
                const duration = response.rows[0].elements[0].duration.text;
                return { ...location, lat, lng, numberFound, distance, duration };
              } else {
                return { ...location, lat, lng, numberFound };
              }
            } catch (error) {
              console.error(`Error fetching coordinates for ${location.description}:`, error);
              return location;
            }
          })
        );

        setLocations(updated);
      };

      updatedLocations();
      map.fitBounds(bounds);
    }
  }, [map, locations]);

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <div>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '400px' }}
          center={centerCoordinate}
          zoom={10}
          onLoad={onLoad}
        >
          {locations.map((location) => (
            <Marker
              key={location.description}
              position={{ lat: location.lat, lng: location.lng }}
              label={location.description}
            />
          ))}
        </GoogleMap>
        <ul>
          {locations.map((location) => (
            <li key={location.description}>
              {location.description}|{location.km}|{location.lat}|{location.lng}|{location.distance}|{location.duration}|{location.numberFound}
            </li>
          ))}
        </ul>
      </div>
    </LoadScript>
  );
};

export default MapSearchLocation;