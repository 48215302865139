import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete from 'use-places-autocomplete';
import Row from 'react-bootstrap/Row';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const libraries = ['places'];

function MapEditPosition({ initialLat, initialLng, onChange }) {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [draggingMarker, setDraggingMarker] = useState(false);
  const { suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete();
  const [map, setMap] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);
  const [zoom, setZoom] = useState(14);
  const [initialCenter, setInicialCenter] = useState({
    lat: 17.0731842,
    lng: -96.7265889
  })

  const handleMarkerDragEnd = (event) => {
    const lat = parseFloat(event.latLng.lat());
    const lng = parseFloat(event.latLng.lng());
    setDraggingMarker(false); // Indica que el arrastre ha terminado
    setSelectedMarker({ lat, lng }); // Actualiza el estado cuando el arrastre ha terminado
    onChange(lat, lng);
  };


  const handleMarkerDragStart = () => {
    setDraggingMarker(true); // Indica que el Marker se está arrastrando
  };

  useEffect(() => {
    
  }, [selectedMarker]);

  useEffect(() => {
    if (!initialLat || !initialLng) {
      initialLat = 17.0731842;
      initialLng = -96.7265889;
    }
    setInicialCenter({
      lat: parseFloat(initialLat),
      lng: parseFloat(initialLng)
    });


    /*setSelectedMarker({
      geometry: {
        location: {
          lat: () => initialLat,
          lng: () => initialLng
        }
      }
    });*/

    setSelectedMarker({
      lat: parseFloat(initialLat),
      lng: parseFloat(initialLng)
    });
  }, [initialLat, initialLng]);

  const mapRef = React.createRef();

  return (
    <Fragment>
      <div className="row gx-2 mb-3">
        <div className="col-md-12 pb-2">
          <div className="alert alert-primary alert-dismissible fade show h-100 mb-0">
            Pulse sobre el marcador, arrastre y suéltelo para ajustar su ubicación.
            <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
          </div>
        </div>
      </div>
      <Row className='mb-3'>
        <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
          <GoogleMap
            ref={mapRef}
            mapContainerStyle={containerStyle}
            center={selectedMarker || initialCenter}
            zoom={zoom}
            onLoad={onLoad}
          >

            {selectedMarker && (
              <Marker
                position={{
                  lat: selectedMarker.lat,
                  lng: selectedMarker.lng
                }}
                draggable={true}
                onDragStart={handleMarkerDragStart}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
          </GoogleMap>

        </LoadScript>
      </Row>
    </Fragment>

  );
};

export default MapEditPosition;