import React from 'react';
import { Link } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { slideToggle } from './../../composables/slideToggle.js';

function SidebarProfile() {
	
  
	return (
		<AppSettings.Consumer>
			{({appSidebarMinify}) => (
				<div className="menu">
					
					<div id="appSidebarProfileMenu" className="collapse">
						<div className="menu-item pt-5px">
							<Link to="/" className="menu-link">
								<div className="menu-icon"><i className="fa fa-cog"></i></div>
								<div className="menu-text">Settings</div>
							</Link>
						</div>
						<div className="menu-item">
							<Link to="/" className="menu-link">
								<div className="menu-icon"><i className="fa fa-pencil-alt"></i></div>
								<div className="menu-text"> Send Feedback</div>
							</Link>
						</div>
						<div className="menu-item pb-5px">
							<Link to="/" className="menu-link">
								<div className="menu-icon"><i className="fa fa-question-circle"></i></div>
								<div className="menu-text"> Helps</div>
							</Link>
						</div>
						<div className="menu-divider m-0"></div>
					</div>
				</div>
			)}
		</AppSettings.Consumer>
	)
}

export default SidebarProfile;