import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import './ComponentToPrint.css';
import OrderMapPrint from './orderMapPrint';
import OrderGoogleMapPrint from './orderGoogleMapPrint';

function OrderUbicacionPrint({ orderData }) {

    return (
        <>
            <form className="row row-cols-sm-auto align-items-center mb-0 mt-0" action="/" method="POST">
                {orderData?.order_locations && orderData?.order_locations.length > 0
                    ? orderData?.order_locations.map((order_location, index) => (
                        <Col sm={`${orderData?.order_locations.length > 1 ? 6 : 12}`} key={`tr_order_location_print_${order_location?.id}_${index}`}>

                            {(order_location?.cuadro_busqueda || order_location?.referencia || order_location?.direccion) && (
                                <Row className="mb-0 mt-0">
                                    <Form.Group as={Col} md="12" className='simple-text pe-2 ps-2'>
                                        <Form.Label>
                                            {orderData?.order_locations.length === 1 ? 'ORIGEN:' :
                                                (orderData?.order_locations.length === 2 ?
                                                    (index === 0 ? 'ORIGEN:' : 'DESTINO:')
                                                    : `UBICACIÓN ${index + 1}:`)
                                            }

                                        </Form.Label>
                                        <span>
                                            {order_location?.cuadro_busqueda && (
                                                ' ' + order_location?.cuadro_busqueda
                                            )}

                                            {order_location?.direccion && (
                                                ' (' + order_location?.direccion + ')'
                                            )}

                                            {order_location?.referencia && (
                                                ' (' + order_location?.referencia + ')'
                                            )}

                                            {order_location?.latitude && order_location?.longitude && (
                                                ' (' + order_location?.latitude + ', ' + order_location?.longitude + ')'
                                            )}
                                        </span>
                                    </Form.Group>
                                </Row>
                            )}
                            <Row>
                                {1 === 0 && (
                                    <OrderGoogleMapPrint
                                        longitude={order_location?.longitude ? parseFloat(order_location?.longitude) : null}
                                        latitude={order_location?.latitude ? parseFloat(order_location?.latitude) : null}
                                    />
                                )}
                                <Col sm='12'>
                                    <div
                                        className='ms-1 ps-1 pe-1 me-1'
                                    >
                                        <OrderMapPrint
                                            label={null}
                                            longitude={order_location?.longitude}
                                            latitude={order_location?.latitude}
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    ))
                    : null
                }

            </form >
        </>
    );
}

export default OrderUbicacionPrint;