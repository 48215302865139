import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel'
import Brands from './brands';
import VehicleModels from './vehicleModels';

function BrandsModels() {

    return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
						<li className="breadcrumb-item">Catálogos</li>
						<li className="breadcrumb-item active">Marcas y Modelos</li>
					</ol>
					<h1 className="page-header mb-0">Marcas y Modelos</h1>
				</div>
			</div>

			
		
			<div className="row mb-3">
				<div className="col-xl-12">
					<Panel>
						<PanelHeader>Listado de Marcas y Modelos de Vehículos</PanelHeader>
						<PanelBody>

							<Tabs
								defaultActiveKey="brand"
								className="mb-3"
							>
								<Tab eventKey="brand" title="Marcas">
									<Brands/>
								</Tab>
								<Tab eventKey="model" title="Modelos">
									<VehicleModels/>
								</Tab>
							</Tabs>



						</PanelBody>
					</Panel>
				</div>
				
			</div>
					
			
		</div>
      
    );
}

export default BrandsModels;