import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import DateTime from 'react-datetime';
import moment from 'moment';

function OrderStatusContacto({ fecha_hora_envio, fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB }) {
    const { id } = useParams();
    const { Formik } = formik;

    const fechaHoraEnvio = useRef(null);
    const fechaHoraContacto = useRef(null);

    return (
        <>
            {fecha_hora_envio && (
                <Formik
                    validationSchema={yup.object().shape({
                        fecha_hora_contacto: yup.date().required("La fecha y hora de contacto es requerida.").min(moment(fecha_hora_envio).add(1, 'second'), 'La fecha y hora de contacto debe ser posterior a la de envío'),
                        observaciones: yup.string()
                    })}
                    initialValues={{
                        fecha_hora_contacto: new Date(),
                        observaciones: ''
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);

                        axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
                            order_status_code: 'CONTACTO',
                            fecha_hora_contacto: moment(values.fecha_hora_contacto).format(),
                            observations: values.observaciones
                        }).then(async response => {
                            const result = await fetchCurrentOrderStatusByOrder();
                            setCurrentOrderStatus(result);
                            console.table("setCurrentOrderStatus", result);

                            if (modalInstance) {
                                modalInstance.hide();
                            }
                            reloadOrderDataFromDB();

                        }).catch(err => {
                            console.log(err.response);
                            Swal.fire({
                                title: 'Ups!',
                                text: 'Ha ocurrido un error',
                                icon: 'error',
                                confirmButtonText: 'Cerrar',
                                cancelButtonText: 'Cancelar',
                                showCancelButton: false,
                            });

                        });
                    }}

                >

                    {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                        <>
                            <div className="modal-header">
                                <h4 className="modal-title">Registrar Fecha y hora de Contacto</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                            </div>
                            <div className="modal-body">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-4">
                                        <Form.Group as={Col} md="12" >
                                            <Form.Label>Fecha y hora de envío registrada</Form.Label>
                                            <DateTime
                                                disabled={true}
                                                name="fecha_hora_envio"
                                                ref={fechaHoraEnvio}
                                                inputProps={{
                                                    placeholder: 'Fecha y hora de envío',
                                                    disabled: true
                                                }}
                                                locale="es"
                                                value={fecha_hora_envio}
                                                dateFormat="dddd DD/MMMM/YYYY"
                                                timeFormat="hh:mm A"
                                                initialViewMode="days"
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-1">
                                        <Form.Group as={Col} md="12" >
                                            <Form.Label>Fecha y hora de contacto</Form.Label>
                                            <DateTime
                                                name="fecha_hora_contacto"
                                                ref={fechaHoraContacto}
                                                inputProps={{ placeholder: 'Fecha y hora de contacto' }}
                                                closeOnSelect={false}
                                                locale="es"
                                                value={values.fecha_hora_contacto}
                                                className={errors.fecha_hora_contacto ? 'is-invalid' : ''}
                                                dateFormat="dddd DD/MMMM/YYYY"
                                                timeFormat="hh:mm A"
                                                initialViewMode="days"
                                                onOpen={(e) => {
                                                    fechaHoraContacto.current.navigate('days');
                                                    setFieldTouched('fecha_hora_contacto', true);
                                                }}
                                                onChange={(e) => {
                                                    setFieldValue("fecha_hora_contacto", e ? e : '');
                                                }}
                                                isInvalid={touched.fecha_hora_contacto && !!errors.fecha_hora_contacto}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.fecha_hora_contacto}
                                            </Form.Control.Feedback>


                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-1">
                                        <Form.Group as={Col} md="12" >
                                            <Form.Label>Observaciones</Form.Label>
                                            <textarea
                                                name="observaciones"
                                                className={`form-control ${errors.observaciones ? 'is-invalid' : ''}`}
                                                onChange={(e) => {
                                                    setFieldValue('observaciones', e.target.value);
                                                }}
                                                value={values.observaciones}
                                            ></textarea>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.observaciones}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                </Form>

                            </div>
                            <div className="modal-footer">
                                <Button type="button" data-bs-dismiss="modal" className='btn-white'>Cerrar</Button>
                                <Button type="button" onClick={handleSubmit} disabled={errors.fecha_hora_contacto}>Actualizar estatus</Button>
                            </div>
                        </>
                    )}
                </Formik>
            )}
        </>
    );
}

export default OrderStatusContacto;