import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import Swal from 'sweetalert2';
import 'react-phone-number-input/style.css'; // Importa los estilos de react-phone-number-input
import 'moment/locale/es';

import moment from 'moment';
import axiosClient from '../../../config/axios.js';
import OrderFields from './orderFields';
import { orderValidationSchema, orderInitialValues, opcionesEsCita } from './orderFieldsYupValidation';


function NewOrder() {

    const [operadores, setOperadores] = useState([]);
    const navigate = useNavigate();
    const { Formik } = formik;

    useEffect(() => {

        axiosClient.get('/users/allOperators').then(response => {

            const formattedOptions = response.data.data.map(operator => ({
                value: operator.id,
                label: operator.name + ' ' + operator.first_surname + ' ' + operator.second_surname,
            }));
            setOperadores(formattedOptions);
        }).catch(error => {
            console.error('Error fetching allOperators:', error);
        });

    }, []);

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/ubicaciones">Cartas de Servicio</Link></li>
                        <li className="breadcrumb-item active">Nueva Carta de Servicio</li>
                    </ol>
                    <h1 className="page-header mb-0">Registro de nueva Carta de Servicio</h1>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-xl-12">
                    <Formik
                        validationSchema={orderValidationSchema}
                        initialValues={orderInitialValues}
                        validateOnChange
                        onSubmit={(values, { setSubmitting }) => {
                            const formData = new FormData();
                            formData.append('photo', values.photo);

                            const formattedValues = {
                                fecha_hora_cita: values.fecha_hora_cita ? moment(values.fecha_hora_cita).format() : null,
                                fecha_hora_alerta_cita: values.fecha_hora_alerta_cita ? moment(values.fecha_hora_alerta_cita).format() : null,
                                fecha_hora_contacto: values.fecha_hora_contacto ? moment(values.fecha_hora_contacto).format() : null,
                                fecha_hora_entrega: values.fecha_hora_entrega ? moment(values.fecha_hora_entrega).format() : null,
                                // Resto de los valores
                                ...values,
                            };

                            setSubmitting(false);
                            console.log("datos a enviar", formattedValues);

                            axiosClient.post('/orders/save', formattedValues).then(response => {

                                Swal.fire({
                                    title: 'Registro exitoso.',
                                    text: `Se ha registrado la nueva Carta de Servicio con folio ${String(response.data.folio).padStart(5, '0')}`,
                                    icon: 'success',
                                    confirmButtonText: 'Cerrar',
                                    cancelButtonText: 'Cancelar',
                                    showCancelButton: false,
                                });
                                navigate('/orders');
                            }).catch(err => {
                                console.log(err.response.data);

                                Swal.fire({
                                    title: 'Ups!',
                                    text: 'Ha ocurrido un error',
                                    icon: 'error',
                                    confirmButtonText: 'Cerrar',
                                    cancelButtonText: 'Cancelar',
                                    showCancelButton: false,
                                });
                            });

                        }}

                    >

                        {({ handleSubmit, handleChange, handleBlur, validateForm, values, touched, errors, setFieldValue, setFieldTouched }) => (
                            <Form noValidate onSubmit={handleSubmit} encType="multipart/form-data">
                                <Row className="mb-3">
                                    <OrderFields
                                        operadores={operadores}
                                        values={values}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        touched={touched}
                                        errors={errors}
                                        opcionesEsCita={opcionesEsCita}
                                        validateForm={validateForm}
                                    />
                                </Row>
                            </Form>
                        )}
                    </Formik>

                </div>

            </div>


        </div >

    );
}

export default NewOrder;