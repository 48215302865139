import React from 'react';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Field, ErrorMessage } from 'formik';

function OrderLocationField({ indexNumber, values, setFieldValue, setFieldTouched, arrayHelpers, handleSearchAddress, editFlagForeignRate }) {

    return (
        <div className="timeline-item mb-3">

            <div className="timeline-icon">
                <a href="#/">{indexNumber + 1}</a>

            </div>
            <div className="timeline-content">

                <div className="timeline-body">

                    <Form.Group as={Col} md="12" className="d-flex align-items-center mb-1">
                        <div className="input-group" >
                            <Field
                                type="text"
                                name={`order_locations.${indexNumber}.cuadro_busqueda`}
                                /* value={values.cuadro_busqueda_origen}
                                onChange={(e) => {
                                     setFieldValue('cuadro_busqueda_origen', e.target.value);
                                     setFieldTouched('cuadro_busqueda_origen', true); // Marcar como tocado
                                 }}*/
                                placeholder="Escribe una coordenada, dirección, lugar o negocio"
                                className='form-control'
                                autoComplete="off"
                                disabled={values.edit_locations_finished || indexNumber + 1 < values.order_locations.length}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        // Capturar la tecla "Enter" y hacer clic en el botón
                                        e.preventDefault(); // Evitar que el Enter cause un salto de línea en un formulario
                                        handleSearchAddress(indexNumber);
                                    }
                                }}
                            />

                            <button type='button' key={`order_locations_button_search_${indexNumber}`} className="input-group-text btn btn-info me-1"
                                onClick={() => handleSearchAddress(indexNumber)}
                                disabled={values.edit_locations_finished || indexNumber + 1 < values.order_locations.length}
                            >
                                <i className="fa fa-search"></i>
                            </button>
                        </div>

                        {
                            (values.service_location_type_id.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false && (
                                <div className="d-flex align-items-center">
                                    <button
                                        type="button"
                                        className={`btn btn-icon ${values.order_locations[indexNumber].location_rate_id ? 'btn-info' : 'btn-danger'}`}
                                        onClick={() => {
                                            editFlagForeignRate(indexNumber);
                                        }}
                                        disabled={values.edit_locations_finished}
                                    >
                                        <i className="fa-solid fa-flag"></i>
                                    </button>

                                </div>
                            ))
                        }



                        {
                            !values.edit_locations_finished && values.order_locations[indexNumber].removable && indexNumber === values.order_locations.length - 1 && (
                                <div className="d-flex align-items-center ms-3">
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-icon btn-circle btn-sm"
                                        onClick={() => {
                                            arrayHelpers.remove(indexNumber); // Elimina la fila del FieldArray
                                        }}
                                    >
                                        <i className="fa-solid fa-minus"></i>
                                    </button>

                                </div>
                            )
                        }


                    </Form.Group>
                    <Form.Group as={Col} md="12" className='mb-1'>

                        <textarea
                            name={`order_locations.${indexNumber}.direccion`}
                            className='form-control'
                            placeholder='Dirección'
                            disabled={values.edit_locations_finished}
                            //className={`form-control ${errors.herramientas_observaciones ? 'is-invalid' : ''}`}
                            onChange={(e) => {
                                setFieldValue(`order_locations.${indexNumber}.direccion`, e.target.value);
                            }}
                            value={values.order_locations[indexNumber].direccion}
                        ></textarea>

                        <Form.Control.Feedback type="invalid">

                        </Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group as={Col} md="12">

                        <textarea
                            name={`order_locations.${indexNumber}.referencia`}
                            className='form-control'
                            placeholder='Referencia'
                            disabled={values.edit_locations_finished}
                            //className={`form-control ${errors.herramientas_observaciones ? 'is-invalid' : ''}`}
                            onChange={(e) => {
                                setFieldValue(`order_locations.${indexNumber}.referencia`, e.target.value);
                            }}
                            value={values.order_locations[indexNumber].referencia}
                        ></textarea>

                        <Form.Control.Feedback type="invalid">

                        </Form.Control.Feedback>

                    </Form.Group>
                </div>
            </div>
        </div>

    );
}

export default OrderLocationField;