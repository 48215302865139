import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as formik from 'formik';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import RatesByLocation from './ratesByLocation.js';
import RatesByInsurance from './ratesByInsurance';
import LocalRateMapCoordinates from './localRateMapCoordinates';


function LocalRates() {
	const navigate = useNavigate();
	const { Formik } = formik;

	const [initialCoordinates, setInitialCoordinates] = useState([]);

	useEffect(() => {
		reloadOrderDataFromDB();
	}, []);

	const reloadOrderDataFromDB = () => {
		axiosClient.get(`/rates/getLocalRateMapCoordinates`).then(response => {
			if (response?.data?.length > 0) {
				const newData = response.data.map(item => ({
					lat: parseFloat(item.lat),
					lng: parseFloat(item.lng)
				}));
				setInitialCoordinates(newData);
			}
		}).catch(error => {
			console.error('Error fetching order data:', error);
		});
	}

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
						<li className="breadcrumb-item">Configuración</li>
						<li className="breadcrumb-item">Tarifas</li>
						<li className="breadcrumb-item active">Locales</li>
					</ol>
					<h1 className="page-header mb-0">Tarifas locales</h1>
				</div>
			</div>



			<div className="row mb-3">
				<div className="col-xl-12">
					<Panel>
						<PanelHeader>Configuración de Tarifas locales</PanelHeader>
						<PanelBody>

							<Formik
								enableReinitialize
								validationSchema={yup.object().shape({

									coordinates: yup.array().of(
										yup.object().shape({
											latitude: yup.string(),
											longitude: yup.string()
										})
									),

								})}
								initialValues={{
									coordinates: initialCoordinates
								}}
								onSubmit={(values, { setSubmitting }) => {
									console.log(values);
									setSubmitting(false);

									axiosClient.post('/rates/saveLocalRateMapCoordinates', values).then(response => {

										Swal.fire({
											title: 'Registro exitoso.',
											text: `Se ha registrado el área para tarifas locales.`,
											icon: 'success',
											confirmButtonText: 'Cerrar',
											cancelButtonText: 'Cancelar',
											showCancelButton: false,
										});
										//navigate('/orders');
									}).catch(err => {

										Swal.fire({
											title: 'Ups!',
											text: err?.response?.data?.message,
											icon: 'error',
											confirmButtonText: 'Cerrar',
											cancelButtonText: 'Cancelar',
											showCancelButton: false,
										});
									});

								}}

							>

								{({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
									<Form noValidate onSubmit={handleSubmit} encType="multipart/form-data">
										<Row className='mb-2'>
											<LocalRateMapCoordinates
												values={values}
												setFieldValue={setFieldValue}
											/>
										</Row>
										<Row className='mb-2'>
											<Col md="12 " >
												<div className='float-end'>
													<Button type="submit">Guardar área de Tarifas Locales</Button>
												</div>

											</Col>

										</Row>


									</Form>
								)}
							</Formik>



						</PanelBody>
					</Panel>
				</div>

			</div>


		</div >

	);
}

export default LocalRates;


