import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import LiftTruckFields from './liftTruckFields';


function EditLiftTruck() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;

    const [initialValues, setInitialValues] = useState({
        lift_truck_number: '',
        description: ''
    });

    useEffect(() => {
        axiosClient.get(`/liftTrucks/${id}`).then(response => {
            const { lift_truck_number, description } = response.data;
            setInitialValues({ lift_truck_number, description });
        }).catch(error => {
            console.error('Error fetching lift trucks data:', error);
        });
    }, [id]);

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/locations">Montacargas</Link></li>
                        <li className="breadcrumb-item active">Edición de Montacargas</li>
                    </ol>
                    <h1 className="page-header mb-0">Edición de Montacargas</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Edición de Montacargas</PanelHeader>
                        <PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    lift_truck_number: yup.number("El número de Montacargas debe ser un número").required("Ingrese el número de Montacargas"),
                                    description: yup.string().required("Ingrese la descripción del Montacargas"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.put(`/liftTrucks/${id}`, values).then(response => {
                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'El Montacargas se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/liftTrucks');
                                    }).catch(err => {
                                        let message = '';
                                        console.log(err);
                                        message = err.response.data.message;
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={initialValues}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        
                                        <LiftTruckFields
                                            values={values}
                                            handleChange={handleChange}
                                            errors={errors}
                                        />

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar Montacargas</Button>{' '}
                                                <Link to={'/catalogs/liftTrucks'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default EditLiftTruck;