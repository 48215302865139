import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import axiosClient from '../../../../config/axios.js';
import PermissionSwitches from './permissionSwitches.js';

function RoleFields({ id, handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) {
    //const [categoryPermissions, setCategoryPermissions] = useState([]);

    useEffect(() => {
        axiosClient.get(`/roles/${id}`).then(response => {
            const { role_name, editable_role_name } = response.data;
            setFieldValue('role_name', role_name);
            setFieldValue('editable_role_name', editable_role_name);
            setFieldTouched('role_name');
        }).catch(error => {
            console.error('Error fetching role data:', error);
        });

        
        axiosClient.get(`/roles/findPermissions${id?'/'+id:''}`).then(response => {
            setFieldValue('categoryPermissions', response.data);
        }).catch(error => {
            console.error('Error fetching role data:', error);
        });

    }, [id]);

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Nombre del Rol</Form.Label>
                    <Form.Control
                        type="text"
                        name="role_name"
                        placeholder="Nombre del Rol"
                        value={values.role_name}
                        onChange={handleChange}
                        isInvalid={!!errors.role_name}
                        readOnly={!values.editable_role_name}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.role_name}
                    </Form.Control.Feedback>
                </Form.Group>

            </Row>

            <Row className="mb-3">


                {values.categoryPermissions && values.categoryPermissions.map((category, indexCategory) => (
                    <Row className="mb-3" key={indexCategory}>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="3">{category.category.category}</Form.Label>
                            <Col sm={9}>

                                {category.permissions.map((permission, indexPermission) => (
                                    <Form.Group as={Row} md="9" key={indexPermission}>
                                        <Form.Check
                                            type="switch"
                                            label={permission.description}
                                            name={`categoryPermissions.${indexCategory}.permissions.${indexPermission}.active`}
                                            checked={values.categoryPermissions[indexCategory].permissions[indexPermission].active}
                                            onChange={(e) => {
                                                setFieldValue(`categoryPermissions.${indexCategory}.permissions.${indexPermission}.active`, e.target.checked);
                                            }}
                                        />
                                    </Form.Group>
                                ))}

                            </Col>
                        </Form.Group>
                    </Row>
                ))}


            </Row>
        </>
    );
}

export default RoleFields;