import React, { useState, useEffect } from 'react';
import axiosClient from '../../../../config/axios.js';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import * as yup from 'yup';
import { useFormik } from 'formik';

function RatesByInsurance() {
	const navigate = useNavigate();

	const [insurers, setInsurers] = useState([]);
	const [insuranceSelected, setInsuranceSelected] = useState();

	const fetchInsurers = async () => {
		try {
			const response = await axiosClient.get(`/insurers/all`);

			const options = response.data.data.map(item => ({
				value: item.id,
				label: item.insurance_name
			}));
			setInsurers(options);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchInsurers();
	}, []);

	const formik = useFormik({
		initialValues: {
			insurance_id: '',
			fixed_rate: '',
			rate_kilometer: '',
			aux_vial_fixed_rate: '',
			aux_vial_rate_kilometer: ''
		},
		validationSchema: yup.object().shape({
			rate_kilometer: yup.number("Ingrese un monto"),
			fixed_rate: yup.number("Ingrese un monto"),
			aux_vial_rate_kilometer: yup.number("Ingrese un monto"),
			aux_vial_fixed_rate: yup.number("Ingrese un monto")
		}),
		onSubmit: (values, { setSubmitting }) => {
			setSubmitting(false);
			values.insurance_id = insuranceSelected.value;

			Swal.fire({
				title: '¿Está seguro que desea actualizar las tarifas de todas las ubicaciones de la aseguradora seleccionada?',
				text: "Esta acción no podrá deshacerse.",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Sí, actualizar las tarifas',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					
					axiosClient.post('/rates/saveByInsurance', values).then(response => {
						
						Swal.fire({
							title: 'Actualización exitosa.',
							text: 'Se han actualizado las tarifas de todas las ubicaciones de la Aseguradora seleccionada.',
							icon: 'success',
							confirmButtonText: 'Cerrar',
							cancelButtonText: 'Cancelar',
							showCancelButton: false,
						});
						navigate('/catalogs/rates');
					}).catch(err => {
						
						let message = err.response.data.message;
						
						Swal.fire({
							title: 'Ups!',
							text: message,
							icon: 'error',
							confirmButtonText: 'Cerrar',
							cancelButtonText: 'Cancelar',
							showCancelButton: false,
						});
					});


				}
			})

			/**/
		}
	});

	const handleInsuranceChange = selectedOption => {
		setInsuranceSelected(selectedOption);
		formik.setValues({
			...formik.values,
			insurance_id: selectedOption.value,
			fixed_rate: '',
			rate_kilometer: '',
			aux_vial_fixed_rate: '',
			aux_vial_rate_kilometer: ''

		});
	};

	return (
		<Form noValidate onSubmit={formik.handleSubmit}>

			<div className="row gx-2 mb-3">
				<div className="col-md-12 pb-2">
					<div className="alert alert-primary alert-dismissible fade show h-100 mb-0">
						Seleccione una Aseguradora para actualizar las tarifas de todas las ubicaciones registradas.
						<button type="button" className="btn-close" data-bs-dismiss="alert"></button>
					</div>
				</div>
			</div>

			<Row className="mb-3">
				<Form.Group as={Col} md="12">
					<Form.Label>Aseguradora</Form.Label>
					<Select
						value={insuranceSelected}
						options={insurers}
						name="insurance_id"
						onChange={handleInsuranceChange}
						placeholder="Seleccione una aseguradora"
					/>
					<Form.Control.Feedback type="invalid">
						{formik.errors.insurance_id}
					</Form.Control.Feedback>
				</Form.Group>
			</Row>

			{insuranceSelected && (
				<>

					<Row className="mb-3">
						<Form.Group as={Col} md="12">
							<Form.Label>Tarifa unitaria por kilómetro</Form.Label>
							<InputGroup className="mb-3" hasValidation>
								<InputGroup.Text>$</InputGroup.Text>
								<Form.Control
									type="text"
									name="rate_kilometer"
									placeholder=""
									value={formik.values.rate_kilometer}
									onChange={formik.handleChange}
									isInvalid={formik.touched.rate_kilometer && !!formik.errors.rate_kilometer}
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.rate_kilometer}
								</Form.Control.Feedback>
							</InputGroup>

						</Form.Group>
					</Row>
					<Row className="mb-3">
						<Form.Group as={Col} md="12">
							<Form.Label>Tarifa fija</Form.Label>
							<InputGroup className="mb-3" hasValidation>
								<InputGroup.Text>$</InputGroup.Text>
								<Form.Control
									type="text"
									name="fixed_rate"
									placeholder=""
									value={formik.values.fixed_rate}
									onChange={formik.handleChange}
									isInvalid={formik.touched.fixed_rate && !!formik.errors.fixed_rate}
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.fixed_rate}
								</Form.Control.Feedback>
							</InputGroup>

						</Form.Group>
					</Row>
					<Row className="mb-3">
						<Form.Group as={Col} md="12">
							<Form.Label className="mb-3">Auxilio Vial</Form.Label>

							<fieldset>
								<div className="row mb-3">
									<label className="form-label col-form-label col-md-5">Tarifa unitaria por kilómetro en Auxilio Vial</label>
									<div className="col-md-7">
										<InputGroup className="mb-3" hasValidation>
											<InputGroup.Text>$</InputGroup.Text>
											<Form.Control
												type="text"
												name="aux_vial_rate_kilometer"
												placeholder=""
												value={formik.values.aux_vial_rate_kilometer}
												onChange={formik.handleChange}
												isInvalid={formik.touched.aux_vial_rate_kilometer && !!formik.errors.aux_vial_rate_kilometer}
											/>
											<Form.Control.Feedback type="invalid">
												{formik.errors.aux_vial_rate_kilometer}
											</Form.Control.Feedback>
										</InputGroup>
									</div>
								</div>
								<div className="row mb-3">
									<label className="form-label col-form-label col-md-5">Tarifa fija en Auxilio Vial</label>
									<div className="col-md-7">
										<InputGroup className="mb-3" hasValidation>
											<InputGroup.Text>$</InputGroup.Text>
											<Form.Control
												type="text"
												name="aux_vial_fixed_rate"
												placeholder=""
												value={formik.values.aux_vial_fixed_rate}
												onChange={formik.handleChange}
												isInvalid={formik.touched.aux_vial_ixed_rate && !!formik.errors.aux_vial_fixed_rate}
											/>
											<Form.Control.Feedback type="invalid">
												{formik.errors.aux_vial_fixed_rate}
											</Form.Control.Feedback>
										</InputGroup>
									</div>
								</div>
							</fieldset>



						</Form.Group>
					</Row>
					<Row className="mb-3">
						<Col md="12">
							<Button type="submit">Actualizar tarifas</Button>{' '}
							<Link to={'/catalog/rates'} className="btn btn-info">Cancelar</Link>
						</Col>
					</Row>
				</>
			)}
		</Form>
	);
}

export default RatesByInsurance;