import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { LoadScript } from '@react-google-maps/api';
import OrderFieldsRegistrarUbicacionOrigenDestino from './orderFieldsRegistrarUbicacionOrigenDestino';
import MapMultipleDestination from '../orderLocations/mapMultipleDestination';


const libraries = ['places'];

function OrderFieldsUbicacionAtencion({ values, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors, tiposLocalizacionServicio, modalidadesTarifa }) {
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const [googleMapsLibraryLoaded, setGoogleMapsLibraryLoaded] = useState(false);

    const handleLoadScript = () => {

        setGoogleMapsLibraryLoaded(true)
        console.log(`Google Maps API loaded.`);

    };

    return (
        <>

            <Row className="mb-3">
                <Form.Group as={Col} md="12" >

                    <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries} onLoad={handleLoadScript}>

                        <MapMultipleDestination
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            touched={touched}
                            errors={errors}
                            googleMapsLibraryLoaded={googleMapsLibraryLoaded}
                            tiposLocalizacionServicio={tiposLocalizacionServicio}
                            modalidadesTarifa={modalidadesTarifa}
                        />
                    </LoadScript >

                </Form.Group>
            </Row>

            <Row className="mb-3">
                <OrderFieldsRegistrarUbicacionOrigenDestino
                    values={values}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    errors={errors}
                />
            </Row>
        </>
    );
}

export default OrderFieldsUbicacionAtencion