import React from 'react';
import moment from 'moment';


function OrderTimelineItemIncident({orderData}) {


    return (
        <>
            <tr>
                <td className="field">Fecha y hora del incidente</td>
                <td>
                    {moment(orderData.Incident.incident_datetime).format('DD [de] MMMM [de] YYYY [a las ] hh:mm A')}
                </td>
            </tr>
            <tr>
                <td className="field">Tipos de incidente</td>
                <td>
                    <ul className="list-unstyled">
                        {orderData.Incident.IncidentTypes.map((incidentType, index) => (
                            <li key={`incidentType_${index}`}>
                                {incidentType.incident_type}
                            </li>
                        ))}
                    </ul>
                </td>
            </tr>

            <tr>
                <td className="field">Descripción del incidente</td>
                <td>
                    {orderData.Incident.incident_description}
                </td>
            </tr>
            <tr>
                <td className="field">Solución al incidente</td>
                <td>
                    {orderData.Incident.resolution_actions}
                </td>
            </tr>
            <tr>
                <td className="field">Usuario o usuarios implicados en el incidente</td>
                <td>
                    <ul className="list-unstyled">
                        {orderData.Incident.IncidentUsers.map((incidentUser, index) => (
                            <li key={`incidentUser_${index}`}>
                                {incidentUser.User.name} {incidentUser.User.first_surname} {incidentUser.User.second_surname}
                            </li>
                        ))}
                    </ul>
                </td>
            </tr>

        </>


    );
}

export default OrderTimelineItemIncident;