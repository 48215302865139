import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';

const handleButtonClick = (event) => {
    // Detener la propagación del evento para evitar que active el drag

    event.stopPropagation();
};

const handleDeleteShieldingType = (event, id, fetchData) => {
    event.preventDefault();
    event.stopPropagation();
    Swal.fire({
        title: '¿Está seguro que desea borrar la Categoría de Blindaje seleccionada?',
        text: "Una vez que se haya borrado la Categoría de Blindaje, no podrá recuperarla.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar Categoría de Blindaje',
        cancelButtonText: 'Cancelar',
    }).then((result) => {
        if (result.isConfirmed) {
            axiosClient.delete(`/shieldingTypes/${id}`).then(response => {
                
                fetchData();
                Swal.fire(
                    'Categoría de Blindaje borrada',
                    'La Categoría de Blindaje se ha borrado exitosamente',
                    'success'
                )
            })

        }
    })
}

const SortableItem = SortableElement(({ value, fetchData }) => {
    return (
        <div className="widget-list-item">

            <div className="widget-list-content">
                <h4 className="widget-list-title"> {value.shielding_type}</h4>

            </div>

            <div className="widget-list-action text-end">
                <Link to={`/catalogs/shieldingTypes/edit/${value.id}`}> {/* Reemplaza 'tu_ruta' con la ruta que desees */}
                    <button className="btn btn-info" onClick={handleButtonClick}><i className='fa fa-pencil' /></button>
                </Link>

            </div>

            <div className="widget-list-action text-end">
                <a href='#'>{/* Reemplaza 'tu_ruta' con la ruta que desees */}
                    <button className="btn btn-danger" onClick={(event) => handleDeleteShieldingType(event, value.id, fetchData)} ><i className='fa fa-trash' /></button>
                </a>
            </div>

        </div>
    );
});



export default SortableItem;