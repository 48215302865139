import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [fullName, setFullName] = useState(localStorage.getItem('fullName') || '');
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [username, setUsername] = useState(localStorage.getItem('username') || '');

  useEffect(() => {
    localStorage.setItem('token', token);
    localStorage.setItem('fullName', fullName);
    localStorage.setItem('userId', userId);
    localStorage.setItem('username', username);
  }, [token, fullName, userId]);

  const login = (newToken, newFullName, newUserId, username) => {
    setToken(newToken);
    setFullName(newFullName);
    setUserId(newUserId);
    setUsername(username);
  };

  const logout = () => {
    setToken(null);
    setFullName('');
    setUsername('');
    setUserId(null);
    localStorage.removeItem('token');
    localStorage.removeItem('fullName');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
  };

  return (
    <AuthContext.Provider value={{ token, fullName, userId, login, logout, username }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};