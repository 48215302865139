import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as formik from 'formik';
import moment from 'moment';
import { orderInitialValues } from '../orderFieldsYupValidation';
import OrderStatusEvaluadoFields from '../orderStatus/orderStatusEvaluadoFields.js';
import './orderTimelineItemCustom.css';
import OrderTimelineItemIncident from './orderTimelineItemIncident';

function OrderTimelineItem({ orderHistory, orderData }) {

    function formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="timeline-item">
            <div className="timeline-time">
                <span className="date">{moment(orderHistory.date_status).format('DD [de] MMMM [de] YYYY')}</span>
                <span className="time">{moment(orderHistory.date_status).format('hh:mm A')}</span>
            </div>
            <div className="timeline-icon">
                <a href="#/">&nbsp;</a>
            </div>
            <div className="timeline-content">
                <div className="timeline-header">

                    <div className="username">
                        {orderHistory.OrderStatus.order_status}
                    </div>
                    <div>
                        <a href="#/" className="btn btn-lg border-0 rounded-pill w-40px h-40px p-0 d-flex align-items-center justify-content-center" data-bs-toggle="dropdown">
                            <i className="fa fa-ellipsis-h text-gray-600"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                            <a href="#/" className="dropdown-item d-flex align-items-center">
                                <i className="fa fa-fw fa-bookmark fa-lg"></i>
                                <div className="flex-1 ps-1">
                                    <div>Save Post</div>
                                    <div className="mt-n1 text-gray-500"><small>Add this to your saved items</small></div>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a href="#/" className="dropdown-item"><i className="fa fa-fw fa-edit fa-lg me-1"></i> Edit post</a>
                            <a href="#/" className="dropdown-item"><i className="fa fa-fw fa-user fa-lg me-1"></i> Edit audience</a>
                            <a href="#/" className="dropdown-item"><i className="fa fa-fw fa-bell fa-lg me-1"></i> Turn off notifications for this post</a>
                            <a href="#/" className="dropdown-item"><i className="fa fa-fw fa-language fa-lg me-1"></i> Turn off translations</a>
                            <a href="#/" className="dropdown-item"><i className="fa fa-fw fa-calendar-alt fa-lg me-1"></i> Turn date</a>
                            <div className="dropdown-divider"></div>
                            <a href="#/" className="dropdown-item"><i className="fa fa-fw fa-archive fa-lg me-1"></i> Move to archive</a>
                            <a href="#/" className="dropdown-item"><i className="fa fa-fw fa-trash-alt fa-lg me-1"></i> Move to Recycle bin</a>
                        </div>
                    </div>
                </div>
                <div className="timeline-body">
                    <div >
                        <div className="profile-content">

                            <div className="table-responsive form-inline">
                                <table className="table table-profile align-middle">

                                    <tbody>

                                        {orderHistory.OrderStatus.order_status_code === 'ASIGNADA_SIN_ENVIAR' && (
                                            <>
                                                <tr>
                                                    <td className="field">Operador{orderData.OrderOperators.length > 1 ? 'es' : ''}</td>
                                                    
                                                    <td> {orderData.OrderOperators.length > 0 ? orderData.OrderOperators[0].User.name + ' ' + orderData.OrderOperators[0].User.first_surname + ' ' + orderData.OrderOperators[0].User.second_surname : ''} </td>
                                                </tr>

                                                {orderData && orderData.OrderOperators && orderData.OrderOperators.length > 1 && orderData.OrderOperators.slice(1).map((orderOperator, index) => (
                                                    <tr key={`order_operator_${index}`}>
                                                        <td className="field"></td>
                                                        <td>{orderOperator.User.name + ' ' + orderOperator.User.first_surname + ' ' + orderOperator.User.second_surname}</td>
                                                    </tr>
                                                ))}


                                            </>
                                        )}

                                        {orderHistory.OrderStatus.order_status_code === 'ENVIADA' && (
                                            <>
                                                <tr>
                                                    <td className="field">Fecha y hora de envío</td>
                                                    <td>
                                                        {moment(orderData.fecha_hora_envio).format('DD [de] MMMM [de] YYYY [a las ] hh:mm A')}
                                                    </td>
                                                </tr>
                                            </>
                                        )}

                                        {orderHistory.OrderStatus.order_status_code === 'CONTACTO' && (
                                            <>
                                                <tr>
                                                    <td className="field">Fecha y hora de contacto</td>
                                                    <td>
                                                        {moment(orderData.fecha_hora_contacto).format('DD [de] MMMM [de] YYYY [a las ] hh:mm A')}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        {orderHistory.OrderStatus.order_status_code === 'LLEGADA' && (
                                            <>
                                                <tr>
                                                    <td className="field">Fecha y hora de llegada</td>
                                                    <td>
                                                        {moment(orderData.fecha_hora_llegada).format('DD [de] MMMM [de] YYYY [a las ] hh:mm A')}
                                                    </td>
                                                </tr>
                                            </>
                                        )}

                                        {orderHistory.OrderStatus.order_status_code === 'ENTREGA' && (
                                            <>
                                                <tr>
                                                    <td className="field">Fecha y hora de entrega</td>
                                                    <td>
                                                        {moment(orderData.fecha_hora_entrega).format('DD [de] MMMM [de] YYYY [a las ] hh:mm A')}
                                                    </td>
                                                </tr>
                                            </>
                                        )}

                                        {orderHistory.OrderStatus.order_status_code === 'EVALUADO' && (
                                            <>
                                                <tr>
                                                    <td colSpan={2}>

                                                        <OrderStatusEvaluadoFields
                                                            values={orderData}
                                                            setFieldValue={() => { }}
                                                            errors={{}}
                                                        />
                                                    </td>

                                                </tr>
                                            </>
                                        )}

                                        {orderHistory.OrderStatus.order_status_code === 'CIERRE' && (
                                            <>
                                                <tr>
                                                    <td className="field">Incidente registrado</td>
                                                    <td>
                                                        {orderData.Incident === null ? 'No' : 'Sí'}
                                                    </td>
                                                </tr>
                                                {orderData.Incident !== null && (
                                                    <OrderTimelineItemIncident
                                                        orderData={orderData}
                                                    />
                                                )}

                                            </>
                                        )}

                                        {orderHistory.OrderStatus.order_status_code === 'CANCELADO' && (
                                            <>
                                                <tr>
                                                    <td className="field-40">Motivo de cancelación</td>
                                                    <td>
                                                        {orderData.CancellationType.cancellation_type}
                                                    </td>
                                                </tr>
                                                {orderData.CancellationType.cancellation_type_code === 'SERVICIO_MUERTO' && (
                                                    <>
                                                        <tr>
                                                            <td className="field">Nombre de persona que brinda Autorización (aseguradora {orderData?.Insurance?.insurance_name})</td>
                                                            <td>
                                                                {orderData.servicio_muerto_persona_autoriza}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="field">Fecha y hora de autorización de la cancelación del Servicio Muerto por {orderData?.Insurance?.insurance_name}</td>
                                                            <td>
                                                                {moment(orderData.servicio_muerto_fecha_hora_autorizado).format('DD [de] MMMM [de] YYYY [a las ] hh:mm A')}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="field">Monto de penalización a cobrar</td>
                                                            <td>
                                                                {orderData.servicio_muerto_monto_penalizacion && (
                                                                    '$ ' + formatNumberWithCommas(orderData.servicio_muerto_monto_penalizacion)
                                                                )}

                                                            </td>
                                                        </tr>
                                                    </>
                                                )}

                                                {orderData.CancellationType.cancellation_type_code === 'INCIDENTE' && (
                                                    <OrderTimelineItemIncident
                                                        orderData={orderData}
                                                    />
                                                )}

                                                <tr>
                                                    <td className="field">Observaciones</td>
                                                    <td>{orderData.cancelacion_observaciones} </td>
                                                </tr>

                                            </>
                                        )}

                                        {orderHistory.OrderStatus.order_status_code !== 'CANCELADO' && (
                                            <tr>
                                                <td className="field">Observaciones</td>
                                                <td>{orderHistory.observations ? orderHistory.observations : 'Sin observaciones'} </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>


                    <hr className="mt-10px mb-3" />

                    <div>
                        Movimiento registrado por {orderHistory.User.name + ' ' + orderHistory.User.first_surname + (orderHistory.User.second_surname ? (' ' + orderHistory.User.second_surname) : '')}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default OrderTimelineItem;