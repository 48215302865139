import React from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import { Panel, PanelBody } from '../../../../components/panel/panel';
import OrderStatusEvaluadoFields from './orderStatusEvaluadoFields.js';


function OrderStatusEvaluado({ fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB }) {
    const { id } = useParams();
    const { Formik } = formik;

    const handleRatingClick = (field, value, setFieldValue) => {
        setFieldValue(field, value);
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={yup.object().shape({
                    confirmacion_evaluacion_realizada: yup.boolean(),
                    observations: yup.string().when('$confirmacion_evaluacion_realizada', {
                        is: false,
                        then: (schema) => schema.required('Este campo es obligatorio si la evaluación no es realizada'),
                        otherwise: (schema) => schema
                    }),
                    extinguidor: yup.boolean(),
                    llanta_refaccion: yup.boolean(),
                    gato: yup.boolean(),
                    cable_pasa_corriente: yup.boolean(),
                    senalamientos: yup.boolean(),
                    herramientas: yup.boolean(),
                    herramientas_observaciones: yup.string().when('$herramientas', {
                        is: true,
                        then: (schema) => schema.required('El detalle de las herramientas es requerido'),
                        otherwise: (schema) => schema
                    }),
                    otros: yup.boolean(),
                    otros_observaciones: yup.string().when('$otros', {
                        is: true,
                        then: (schema) => schema.required('El detalle de los objetos entregados es requerido'),
                        otherwise: (schema) => schema
                    }),
                    satisfecho: yup.number().when('$confirmacion_evaluacion_realizada', {
                        is: true,
                        then: (schema) => schema.required('La calificación es requerida'),
                        otherwise: (schema) => schema
                    }),
                    operador_amable: yup.number().when('$confirmacion_evaluacion_realizada', {
                        is: true,
                        then: (schema) => schema.required('La calificación es requerida'),
                        otherwise: (schema) => schema
                    }),
                    grua_limpia: yup.number().when('$confirmacion_evaluacion_realizada', {
                        is: true,
                        then: (schema) => schema.required('La calificación es requerida'),
                        otherwise: (schema) => schema
                    }),
                    recomendaria_servicio: yup.number().when('$confirmacion_evaluacion_realizada', {
                        is: true,
                        then: (schema) => schema.required('La calificación es requerida'),
                        otherwise: (schema) => schema
                    }),
                })}
                initialValues={{
                    confirmacion_evaluacion_realizada: false,
                    observations: ''
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
                        order_status_code: 'EVALUADO',
                        ...values
                    }).then(async response => {
                        const result = await fetchCurrentOrderStatusByOrder();
                        setCurrentOrderStatus(result);
                        console.table("setCurrentOrderStatus", result);

                        if (modalInstance) {
                            
                            modalInstance.hide();
                        }
                        reloadOrderDataFromDB();

                    }).catch(err => {
                        console.log(err.response);
                        Swal.fire({
                            title: 'Ups!',
                            text: 'Ha ocurrido un error',
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                        });

                    });
                }}

            >

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                    <>
                        <div className="modal-header">
                            <h4 className="modal-title">Evaluación del servicio</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div className="modal-body">
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-4">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>{' '}</Form.Label>
                                        <Form.Check
                                            name="confirmacion_evaluacion_realizada"
                                            type="switch"
                                            label="Confirmo que se ha realizado la evaluación"
                                            checked={values.confirmacion_evaluacion_realizada}
                                            className={errors.confirmacion_evaluacion_realizada ? 'is-invalid' : ''}
                                            onChange={(e) => {
                                                setFieldValue('confirmacion_evaluacion_realizada', e.target.checked);
                                            }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.confirmacion_evaluacion_realizada}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <OrderStatusEvaluadoFields
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                />

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>{`Observaciones ${values.confirmacion_evaluacion_realizada ? 'Generales de' : 'del por qué no se realizó'}`} la Evaluación</Form.Label>
                                        <textarea
                                            name="observations"
                                            className={`form-control ${errors.observations ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setFieldValue('observations', e.target.value);
                                            }}
                                            value={values.observations}
                                        ></textarea>

                                        <Form.Control.Feedback type="invalid">
                                            {errors.observations}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Row>
                            </Form>

                        </div>
                        <div className="modal-footer">
                            <Button type="button" data-bs-dismiss="modal" className='btn-white'>Cerrar</Button>
                            <Button type="button" onClick={handleSubmit} disabled={Object.keys(errors).length > 0}>Actualizar estatus</Button>
                        </div>
                    </>
                )
                }
            </Formik >


        </>
    );
}

export default OrderStatusEvaluado;