import React, { Fragment, useState } from 'react';
import axiosClient from '../../../../config/axios.js';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import Accordion from 'react-bootstrap/Accordion';
import CurrencyInput from 'react-currency-input-field';
import * as formik from 'formik';
import * as yup from 'yup';
import { Field, FieldArray } from 'formik';

function RatesByLocation() {
	const navigate = useNavigate();
	const { Formik } = formik;

	const [selectedValue, setSelectedValue] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [casetas, setCasetas] = useState([]);
	const [locationSelected, setLocationSelected] = useState(null);

	const fetchLocations = async (inputValue, callback) => {
		console.log(inputValue);
		try {
			const response = await axiosClient.get(`/locations/datatable`, {
				params: {
					start: 0,
					length: 20,
					sort: 'location_name',
					dir: 'asc',
					search: inputValue
				},
			});
			const options = response.data.data.map(item => ({
				value: item.id,
				label: item.location_name
			}));
			callback(options);
		} catch (error) {
			console.error(error);
			callback([]);
		}
	};


	const handleSelectLocationChange = async (selectedOption) => {
		setTableData([]);
		setSelectedValue(selectedOption);

		// Realiza una llamada a la API con el valor seleccionado
		try {
			const locationResponse = await axiosClient.get(`/locations/${selectedOption.value}`);
			setLocationSelected(locationResponse.data);

			const response = await axiosClient.get(`/rates/findByLocation/${selectedOption.value}`);
			const responseData = response.data; // Asumiendo que la respuesta contiene los datos necesarios

			// Actualiza los datos de la tabla con los datos de la respuesta
			setTableData(responseData.data.insurers);
			setCasetas(responseData.data.casetas);

		} catch (error) {
			console.error('Error:', error);
		}
	};



	return (
		<Formik
			enableReinitialize
			initialValues={{
				insurers: tableData,
				casetas: casetas
			}}
			validationSchema={yup.object().shape({
				color_name: yup.string().required("Ingrese el nombre del Color"),
				casetas: yup.number().min(0, "El monto debe ser igual o mayor a 0").required("Escriba el monto de las casetas"),
			})}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false);
				axiosClient.post('/rates/saveByLocation', values).then(response => {

					Swal.fire({
						title: 'Registro exitoso.',
						text: 'Se ha registrado la nueva Ubicación',
						icon: 'success',
						confirmButtonText: 'Cerrar',
						cancelButtonText: 'Cancelar',
						showCancelButton: false,
					});

				}).catch(err => {
					
					let message = '';
					if (err.response.data.original.code === '23505') {
						message = 'El nombre de la ubicación ya existe. Ingresa uno diferente.';
					} else {
						message = err.response.data.message;
					}
					Swal.fire({
						title: 'Ups!',
						text: message,
						icon: 'error',
						confirmButtonText: 'Cerrar',
						cancelButtonText: 'Cancelar',
						showCancelButton: false,
					});
				});
			}}

		>
			{(formik) => (
				<Form Form >
					<div className="row gx-2 mb-3">
						<div className="col-md-12 pb-2">
							<div className="alert alert-primary alert-dismissible fade show h-100 mb-0">
								Busque una ubicación para visualizar y editar sus tarifas.
								<button type="button" className="btn-close" data-bs-dismiss="alert"></button>
							</div>
						</div>
					</div>
					<Row className="mb-3">
						<Form.Group as={Col} md="12" >
							<Form.Label>Ubicación</Form.Label>
							<AsyncSelect
								loadOptions={fetchLocations}
								defaultOptions
								isSearchable
								placeholder="Escribe para buscar las ubicaciones registradas"
								onChange={handleSelectLocationChange}
							/>

							<Form.Control.Feedback type="invalid">

							</Form.Control.Feedback>
						</Form.Group>
					</Row>

					{locationSelected && (

						<Fragment>
							<Row className="mb-3">
								<Form.Group as={Col} md="12" >
									<Form.Label>Distancia registrada</Form.Label>
									<InputGroup className="mb-3">
										<Form.Control
											type="text"
											value={locationSelected.distance}
											disabled
											readOnly
										/>
										<InputGroup.Text id="basic-addon2">km</InputGroup.Text>
									</InputGroup>
								</Form.Group>

							</Row>

							<Row className="mb-3">
								<Form.Group as={Col} md="12" >
									<Form.Label>Casetas</Form.Label>

									<InputGroup
										className={`mb-3 ${formik.errors.casetas ? 'is-invalid' : ''}`}
									>
										<InputGroup.Text >$</InputGroup.Text>
										<CurrencyInput
											className={`form-control ${formik.errors.casetas ? 'is-invalid' : ''}`}
											prefix=""
											allowNegativeValue={false}
											decimalsLimit={2}  // Limita la cantidad de decimales a 2
											decimalScale={2}
											decimalSeparator="."
											groupSeparator=","
											name="casetas"
											value={formik.values.casetas}
											onValueChange={(value) => formik.setFieldValue('casetas', value)}
											onBlur={formik.handleBlur}
											autoComplete='off'
										/>
									</InputGroup>

									{formik.touched.casetas && formik.errors.casetas ? (
										<div className="invalid-feedback">{formik.errors.casetas}</div>
									) : null}

								</Form.Group>

							</Row>



							<Row className="mb-3">
								<Form.Label>Tarifas por Aseguradora</Form.Label>
								<Accordion defaultActiveKey={['0']} alwaysOpen>
									<FieldArray
										name='insurers'
										render={(arrayHelpers) => (
											<Fragment>
												{
													formik.values.insurers.map((insurance, indexI) => (
														<Accordion.Item eventKey={indexI}>
															<Accordion.Header>{insurance.insurance_name}</Accordion.Header>
															<Accordion.Body>
																<Row className='mb-3'>
																	<Table responsive>
																		<colgroup span="3"></colgroup>
																		<thead>
																			<tr>
																				<th rowspan="2" >Categoría</th>
																				<th colspan="2" className="table-info">Servicio normal</th>
																				{/*<th colspan="2" className='table-active'>Siniestros</th>*/}
																			</tr>
																			<tr>
																				<th className="table-info">Tarifa unitaria por km</th>
																				<th className="table-info">Tarifa fija</th>
																				{/*<th className='table-active'>Tarifa unitaria por km</th>*/}
																				{/*<th className='table-active'>Tarifa fija</th>*/}
																			</tr>
																		</thead>
																		<tbody>
																			<FieldArray
																				name='rates'
																				render={(arrayHelpers) => (
																					<Fragment>
																						{insurance.rates.map((rate, indexJ) => (
																							<tr key={indexJ}>
																								<td>{rate.crane_type_name}</td>
																								<td className="table-info">
																									<InputGroup className="mb-3">
																										<InputGroup.Text >$</InputGroup.Text>
																										<Field
																											type="text"
																											className='form-control'
																											name={`insurers.${indexI}.rates.${indexJ}.rate_kilometer`}
																											id={`insurers.${indexI}.rates.${indexJ}.rate_kilometer`}
																										/>

																										<Form.Control.Feedback type="invalid">

																										</Form.Control.Feedback>
																									</InputGroup>
																								</td>
																								<td className="table-info">

																									<InputGroup className="mb-3">
																										<InputGroup.Text >$</InputGroup.Text>
																										<Field
																											type="text"
																											className='form-control'
																											name={`insurers.${indexI}.rates.${indexJ}.fixed_rate`}
																											id={`insurers.${indexI}.rates.${indexJ}.fixed_rate`}
																										/>
																										<Form.Control.Feedback type="invalid">

																										</Form.Control.Feedback>
																									</InputGroup>
																								</td>
																								{/*
																								<td className="table-active">
																									<InputGroup className="mb-3">
																										<InputGroup.Text >$</InputGroup.Text>
																										<Field
																											type="text"
																											className='form-control'
																											name={`insurers.${indexI}.rates.${indexJ}.siniestro_rate_kilometer`}
																											id={`insurers.${indexI}.rates.${indexJ}.siniestro_rate_kilometer`}
																										/>

																										<Form.Control.Feedback type="invalid">

																										</Form.Control.Feedback>
																									</InputGroup>
																								</td>
																								<td className="table-active">

																									<InputGroup className="mb-3">
																										<InputGroup.Text >$</InputGroup.Text>
																										<Field
																											type="text"
																											className='form-control'
																											name={`insurers.${indexI}.rates.${indexJ}.siniestro_fixed_rate`}
																											id={`insurers.${indexI}.rates.${indexJ}.siniestro_fixed_rate`}
																										/>
																										<Form.Control.Feedback type="invalid">

																										</Form.Control.Feedback>
																									</InputGroup>
																								</td>
																								*/}
																							</tr>
																						)
																						)}
																						<tr className='table-warning'>
																							<td>Auxilio vial</td>
																							<td colspan="1">
																								<Form.Label>Tarifa unitaria por km</Form.Label>
																								<InputGroup className="mb-3">
																									<InputGroup.Text >$</InputGroup.Text>
																									<Field
																										type="text"
																										className='form-control'
																										name={`insurers.${indexI}.aux_vial.rate_kilometer`}
																										id={`insurers.${indexI}.aux_vial.rate_kilometer`}
																									/>

																									<Form.Control.Feedback type="invalid">

																									</Form.Control.Feedback>
																								</InputGroup>
																							</td>
																							<td colspan="1">
																								<Form.Label>Tarifa fija</Form.Label>
																								<InputGroup className="mb-3">
																									<InputGroup.Text >$</InputGroup.Text>
																									<Field
																										type="text"
																										className='form-control'
																										name={`insurers.${indexI}.aux_vial.fixed_rate`}
																										id={`insurers.${indexI}.aux_vial.fixed_rate`}
																									/>
																									<Form.Control.Feedback type="invalid">

																									</Form.Control.Feedback>
																								</InputGroup>
																							</td>
																						</tr>
																					</Fragment>

																				)
																				}
																			/>
																		</tbody>
																	</Table>

																</Row>
															</Accordion.Body>
														</Accordion.Item>

													))
												}
											</Fragment>

										)}
									/>



								</Accordion>
							</Row>

							<Row className="mb-3">
								<Col md="12">

									<Button
										type="button"
										onClick={() => {
											const data = {
												location_selected_id: locationSelected.id,
												casetas: formik.values.casetas,
												insurers: formik.values.insurers
											};

											axiosClient.post('/rates/saveByLocation', data).then(response => {

												Swal.fire({
													title: 'Registro exitoso.',
													text: 'Se han almacenado las tarifas',
													icon: 'success',
													confirmButtonText: 'Cerrar',
													cancelButtonText: 'Cancelar',
													showCancelButton: false,
												});
												navigate('/catalogs/rates');
											}).catch(err => {
												console.log(err.response.data);
												
												let message = err.response.data.message;

												Swal.fire({
													title: 'Ups!',
													text: message,
													icon: 'error',
													confirmButtonText: 'Cerrar',
													cancelButtonText: 'Cancelar',
													showCancelButton: false,
												});
											});


										}}
									>
										Guardar Tarifas
									</Button>
									{' '}
									<Link to={'/catalogs/rates'} className="btn btn-info">Cancelar</Link>
								</Col>
							</Row>
						</Fragment>
					)}

				</Form>
			)
			}
		</Formik >

	);
}

export default RatesByLocation;


