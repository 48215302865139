import { Link, useNavigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import ColorFields from './colorFields';


function NewColor() {
    const navigate = useNavigate();
    const { Formik } = formik;

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Catálogos</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/colors">Colores</Link></li>
                        <li className="breadcrumb-item active">Nuevo Color</li>
                    </ol>
                    <h1 className="page-header mb-0">Registro de nuevo Color</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Registro de nuevo Color</PanelHeader>
                        <PanelBody>
                            <Formik
                                validationSchema={yup.object().shape({
                                    color_name: yup.string().required("Ingrese el nombre del Color"),
                                    color_code: yup.string().required("Seleccione el color"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    
                                    axiosClient.post('/colors/save', values).then(response => {
                                        
                                        Swal.fire({
                                            title: 'Registro exitoso.',
                                            text: 'Se ha registrado el nuevo Color',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/colors');
                                    }).catch(err => {
                                        console.log(err);
                                        let message = '';
                                        if (err.response.data.original.code === '23505') {
                                            message = 'El tono del color que has seleccionado ya existe. Ingresa uno diferente.';
                                        } else {
                                            message = err.response.data.message;
                                        }
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={{
                                    color_name: '',
                                    color_code: '',
                                }}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <ColorFields
                                                values={values}
                                                handleChange={handleChange}
                                                touched={touched}
                                                errors={errors}
                                            />
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar Color</Button>{' '}
                                                <Link to={'/catalogs/colors'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default NewColor;