import React from 'react';
import { Row } from 'react-bootstrap';
import OrderVehicles from '../orderVehicles/orderVehicles.js'


function OrderFieldsDatosBienes({ token, values, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors }) {

    return (
        <Row>
            <OrderVehicles
                token={token}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched}
                errors={errors}
            />
        </Row>
    );
}

export default OrderFieldsDatosBienes