import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';


function EditUser() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;
    const [roles, setRoles] = useState([]);

    const [initialValues, setInitialValues] = useState({
        name: '',
        first_surname: '',
        second_surname: '',
        username: '',
        active: false,
        roles: []
    });

    useEffect(() => {


        axiosClient.get('/roles/all').then(response => {
            const formattedOptions = response.data.data.map(role => ({
                value: role.id,
                label: role.role_name,
            }));
            setRoles(formattedOptions);
        }).catch(error => {
            console.error('Error fetching cranes:', error);
        });

        axiosClient.get(`/users/${id}`).then(response => {
            const { name, first_surname, second_surname, username, active, Roles } = response.data;

            const transformedArrayRoles = Roles.map(obj => ({
                value: obj.id,
                label: obj.role_name
            }));

            setInitialValues({ name, first_surname, second_surname, username, active, roles: transformedArrayRoles });
        }).catch(error => {
            console.error('Error fetching user data:', error);
        });
    }, []);



    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/settings/users">Usuarios</Link></li>
                        <li className="breadcrumb-item active">Edición de Usuario</li>
                    </ol>
                    <h1 className="page-header mb-0">Edición de Usuario</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Edición de Usuario</PanelHeader>
                        <PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    name: yup.string().required("Ingrese un nombre"),
                                    first_surname: yup.string().required("Ingrese el primer apellido"),
                                    second_surname: yup.string(),
                                    username: yup.string().required("Ingrese un nombre de usuario"),
                                    active: yup.boolean()
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.put(`/users/${id}`, values).then(response => {
                                        
                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'El usuario se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/settings/users');
                                    }).catch(err => {
                                        let message = '';
                                        console.log(err);
                                        if (err.response.data.original.code === '23505') {
                                            message = 'El nombre de usuario ya existe. Ingresa uno diferente.';
                                        } else {
                                            message = err.response.data.message;
                                        }
                                        console.log(err.response);
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={initialValues}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" >
                                                <Form.Label>Nombre</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Nombre"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" >
                                                <Form.Label>Primer apellido</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="first_surname"
                                                    placeholder="Primer apellido"
                                                    value={values.first_surname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.first_surname}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.first_surname}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" >
                                                <Form.Label>Segundo apellido</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="second_surname"
                                                    placeholder="Segundo apellido"
                                                    value={values.second_surname}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" >
                                                <Form.Label>Usuario</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Usuario"
                                                    name="username"
                                                    value={values.username}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.username}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.username}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" >
                                                <Form.Label>Usuario habilitado</Form.Label>
                                                <Form.Check
                                                    type="switch"
                                                    label="Usuario habilitado"
                                                    name="active"
                                                    checked={values.active}
                                                    onChange={(e) => {
                                                        setFieldValue('active', e.target.checked);
                                                    }}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="4" >
                                                <Form.Label>Rol</Form.Label>
                                                <Select
                                                    name="role_id"
                                                    options={roles}
                                                    className={errors.roles ? 'is-invalid' : ''}
                                                    placeholder="Selecciona uno o más roles"
                                                    getOptionLabel={option => option.label}
                                                    getOptionValue={option => option.value}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    value={values.roles}
                                                    isMulti
                                                    onChange={(e) => {
                                                        setFieldValue("roles", e);
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.roles}
                                                </Form.Control.Feedback>


                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar cambios</Button>{' '}
                                                <Link to={`/settings/users/changePassword/${id}`} className="btn btn-warning">Cambiar contraseña</Link> {' '}
                                                <Link to={'/settings/users'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>

                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default EditUser;