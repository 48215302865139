import React, { useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

function DropdownProfile() {

	const { token, fullName, logout, username } = useAuth();
	const [showMenu, setShowMenu] = useState(false);
	const navigate = useNavigate();

	const handleLogout = () => {
		// Realiza la lógica de cierre de sesión
		logout();
		navigate('/login');
	};

	const toggleMenu = () => {
		setShowMenu(!showMenu); // Cambiar el estado de showMenu al hacer clic
	};

	return (
		<div className="navbar-item navbar-user dropdown">
			<a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" onClick={toggleMenu} >
				<img src="/assets/img/user/user-13.jpg" alt="" />
				<span>
					<span className="d-none d-md-inline">{fullName}</span>
					<b className="caret"></b>
				</span>
			</a>
			<div className={`dropdown-menu dropdown-menu-end me-1 ${showMenu ? 'show' : ''}`} style={{ position: 'absolute', inset: '0px 0px auto auto', margin: '0px', transform: 'translate(0px, 66px)' }}>
				
				<RouterLink to={'/auth/changePassword'} className="dropdown-item">Cambiar contraseña</RouterLink>
				<div className="dropdown-divider"></div>

				{token ? (
					<a href="#/" className="dropdown-item"
						onClick={handleLogout}>
						Cerrar sesión
					</a>
				) : null}

			</div>
		</div>
	);
};

export default DropdownProfile;
