import { Link, useNavigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import AgreementFields from './agreementFields';


function NewColor() {
    const navigate = useNavigate();
    const { Formik } = formik;

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Catálogos</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/agreements">Convenios</Link></li>
                        <li className="breadcrumb-item active">Nuevo Convenio</li>
                    </ol>
                    <h1 className="page-header mb-0">Registro de nuevo Convenio</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Registro de nueva Color</PanelHeader>
                        <PanelBody>
                            <Formik
                                validationSchema={yup.object().shape({
                                    fecha_inicio_convenio: yup.date().required("Seleccione una fecha de inicio del convenio"),
                                    fecha_fin_convenio: yup.date(),
                                    activo: yup.boolean(),
                                    servicio_local_costo: yup.number("Ingrese un monto"),
                                    auxilio_vial_foraneo_banderazo_costo: yup.number("Ingrese un monto"),
                                    auxilio_vial_foraneo_banderazo_porcentaje: yup.number("Ingrese un monto"),
                                    auxilio_vial_foraneo_casetas_porcentaje: yup.number("Ingrese un monto"),
                                    auxilio_vial_foraneo_combustible_porcentaje: yup.number("Ingrese un monto"),
                                    auxilio_vial_foraneo_km_costo: yup.number("Ingrese un monto"),
                                    auxilio_vial_foraneo_km_porcentaje: yup.number("Ingrese un monto"),
                                    auxilio_vial_foraneo_mantenimiento_banderazo_porcentaje: yup.number("Ingrese un monto"),
                                    auxilio_vial_foraneo_mantenimiento_casetas_porcentaje: yup.number("Ingrese un monto"),
                                    auxilio_vial_foraneo_mantenimiento_porcentaje: yup.number("Ingrese un monto"),
                                    auxilio_vial_local_combustible_porcentaje: yup.number("Ingrese un monto"),
                                    auxilio_vial_local_costo: yup.number("Ingrese un monto"),
                                    auxilio_vial_local_mantenimiento_porcentaje: yup.number("Ingrese un monto"),
                                    banderazo_costo: yup.number("Ingrese un monto"),
                                    corresponsalia_limite_inferior_costo: yup.number("Ingrese un monto"),
                                    corresponsalia_minimo_costo: yup.number("Ingrese un monto"),
                                    corresponsalia_supera_limite_inferior_porcentaje: yup.number("Ingrese un monto"),
                                    dolly: yup.number("Ingrese un monto"),
                                    hora_fraccion_espera: yup.number("Ingrese un monto"),
                                    kilometro_costo: yup.number("Ingrese un monto"),
                                    maniobras_encierro_costo: yup.number("Ingrese un monto"),
                                    servicio_local_mas_45_min_costo: yup.number("Ingrese un monto"),
                                    servicio_muerto_foraneo_banderazo_porcentaje: yup.number("Ingrese un monto"),
                                    servicio_muerto_foraneo_casetas_porcentaje: yup.number("Ingrese un monto"),
                                    servicio_muerto_foraneo_desde_min: yup.number("Ingrese un monto"),
                                    servicio_muerto_foraneo_km_porcentaje: yup.number("Ingrese un monto"),
                                    servicio_muerto_local_banderazo_porcentaje: yup.number("Ingrese un monto"),
                                    servicio_muerto_local_casetas_porcentaje: yup.number("Ingrese un monto"),
                                    servicio_muerto_local_desde_min: yup.number("Ingrese un monto"),
                                    servicio_muerto_local_km_porcentaje: yup.number("Ingrese un monto"),
                                    taxi_foraneo_banderazo_costo: yup.number("Ingrese un monto"),
                                    taxi_foraneo_banderazo_porcentaje: yup.number("Ingrese un monto"),
                                    taxi_foraneo_caseta_porcentaje: yup.number("Ingrese un monto"),
                                    taxi_foraneo_km_costo: yup.number("Ingrese un monto"),
                                    taxi_foraneo_km_porcentaje: yup.number("Ingrese un monto"),
                                    taxi_local_costo: yup.number("Ingrese un monto"),
                                    tiempo_espera_por_hora_costo: yup.number("Ingrese un monto"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    
                                    axiosClient.post('/colors/save', values).then(response => {
                                        Swal.fire({
                                            title: 'Registro exitoso.',
                                            text: 'Se ha registrado el nuevo Color',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/colors');
                                    }).catch(err => {
                                        
                                        let message = '';
                                        if (err.response.data.original.code === '23505') {
                                            message = 'El tono del color que has seleccionado ya existe. Ingresa uno diferente.';
                                        } else {
                                            message = err.response.data.message;
                                        }
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={{
                                    fecha_inicio_convenio: '',
                                    fecha_fin_convenio: '',
                                    activo: true,
                                    servicio_local_costo: '',
                                    auxilio_vial_foraneo_banderazo_costo: '',
                                    auxilio_vial_foraneo_banderazo_porcentaje: '',
                                    auxilio_vial_foraneo_casetas_porcentaje: '',
                                    auxilio_vial_foraneo_combustible_porcentaje: '',
                                    auxilio_vial_foraneo_km_costo: '',
                                    auxilio_vial_foraneo_km_porcentaje: '',
                                    auxilio_vial_foraneo_mantenimiento_banderazo_porcentaje: '',
                                    auxilio_vial_foraneo_mantenimiento_casetas_porcentaje: '',
                                    auxilio_vial_foraneo_mantenimiento_porcentaje: '',
                                    auxilio_vial_local_combustible_porcentaje: '',
                                    auxilio_vial_local_costo: '',
                                    auxilio_vial_local_mantenimiento_porcentaje: '',
                                    banderazo_costo: '',
                                    corresponsalia_limite_inferior_costo: '',
                                    corresponsalia_minimo_costo: '',
                                    corresponsalia_supera_limite_inferior_porcentaje: '',
                                    dolly: '',
                                    hora_fraccion_espera: '',
                                    kilometro_costo: '',
                                    maniobras_encierro_costo: '',
                                    servicio_local_mas_45_min_costo: '',
                                    servicio_muerto_foraneo_banderazo_porcentaje: '',
                                    servicio_muerto_foraneo_casetas_porcentaje: '',
                                    servicio_muerto_foraneo_desde_min: '',
                                    servicio_muerto_foraneo_km_porcentaje: '',
                                    servicio_muerto_local_banderazo_porcentaje: '',
                                    servicio_muerto_local_casetas_porcentaje: '',
                                    servicio_muerto_local_desde_min: '',
                                    servicio_muerto_local_km_porcentaje: '',
                                    taxi_foraneo_banderazo_costo: '',
                                    taxi_foraneo_banderazo_porcentaje: '',
                                    taxi_foraneo_caseta_porcentaje: '',
                                    taxi_foraneo_km_costo: '',
                                    taxi_foraneo_km_porcentaje: '',
                                    taxi_local_costo: '',
                                    tiempo_espera_por_hora_costo: '',
                                }}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors, setFieldValue, setFieldTouched }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <AgreementFields
                                                values={values}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={setFieldTouched}
                                                touched={touched}
                                                errors={errors}
                                            />
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar Color</Button>{' '}
                                                <Link to={'/catalogs/colors'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div >

    );
}

export default NewColor;