import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import RatesByLocation from './ratesByLocation.js';
import RatesByInsurance from './ratesByInsurance';

function ForeignRates() {
	const [tabSelected, setTabSelected] = useState('ratesByLocation');

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
						<li className="breadcrumb-item">Configuración</li>
						<li className="breadcrumb-item">Tarifas</li>
						<li className="breadcrumb-item active">Foráneas</li>
					</ol>
					<h1 className="page-header mb-0">Tarifas foráneas</h1>
				</div>
			</div>



			<div className="row mb-3">
				<div className="col-xl-12">
					<Panel>
						<PanelHeader>Configuración de Tarifas foráneas</PanelHeader>
						<PanelBody>
							<Tabs
								id="controlled-tab-example"
								activeKey={tabSelected}
								onSelect={(k) => setTabSelected(k)}
								className="mb-3"
							>
								<Tab eventKey="ratesByLocation" title="Ubicación">
									<RatesByLocation />
								</Tab>
								<Tab eventKey="ratesByInsurance" title="Aseguradora">
									<RatesByInsurance/>
								</Tab>
							</Tabs>

						</PanelBody>
					</Panel>
				</div>

			</div>


		</div>

	);
}

export default ForeignRates;


