import React from 'react';

function SearchForm() {
	return (
		<div className="navbar-form">
			<form action="" method="POST" name="search_form">
				<div className="form-group">
					
				</div>
			</form>
		</div>
	);
};

export default SearchForm;
