import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../config/axios.js';
import { useAuth } from '../../../context/AuthContext';


function ChangeCurrentUserPassword() {
    const navigate = useNavigate();
    const { Formik } = formik;

    const { token, fullName, logout, username } = useAuth();

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item active">Cambiar contraseña</li>
                    </ol>
                    <h1 className="page-header mb-0">Cambiar contraseña</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Cambiar contraseña</PanelHeader>
                        <PanelBody>
                            <Formik
                                validationSchema={yup.object().shape({
                                    username: yup.string(),
                                    current_password: yup.string().required('La contraseña actual es requerida.'),
                                    new_password: yup.string().required("Ingrese la nueva contraseña."),
                                    confirm_password: yup.string().required("Ingrese la confirmación de la contraseña.").oneOf([yup.ref('new_password'), null], 'Las contraseñas no coinciden.'),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);

                                    axiosClient.put(`/users/changeCurrentPassword`, values).then(response => {
                                        Swal.fire({
                                            title: 'Contraseña actualizada.',
                                            text: 'La contraseña se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/');
                                    }).catch(err => {

                                        const message = err.response.data.message;
                                        
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={{
                                    username: username,
                                    current_password: '',
                                    new_password: '',
                                    confirm_password: ''
                                }}
                            >
                                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                                    <Form noValidate enableReinitialize onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12" >
                                                <Form.Label>Usuario</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Usuario"
                                                    name="username"
                                                    value={values.username}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Row>

                                        <Row className='mb-3'>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Contraseña actual</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Contraseña actual"
                                                    name="current_password"
                                                    value={values.current_password}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.current_password}
                                                    autoComplete="off"
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.current_password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Nueva contraseña</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Nueva contraseña"
                                                    name="new_password"
                                                    value={values.new_password}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.new_password}
                                                    autoComplete="off"
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.new_password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Repetir Contraseña</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Repetir Contraseña"
                                                    name="confirm_password"
                                                    value={values.confirm_password}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.confirm_password}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.confirm_password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>


                                        <Row className="mb-3" >
                                            <Col md="12" >
                                                <Button type="submit" className='float-end' disabled={Object.keys(errors).length > 0}>Actualizar contraseña</Button>{' '}
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default ChangeCurrentUserPassword;