import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';


function NewGrua() {
    const navigate = useNavigate();
    const { Formik } = formik;
    const [craneTypes, setCraneTypes] = useState([]);

    useEffect(() => {
        axiosClient.get(`/craneTypes/datatable`, {
            params: {
                start: 0,
                length: 10,
                search: '',
                sort: 'crane_type_name',
                dir: 'asc',
            },
        }).then(response => {
            const transformedOptions = response.data.data.map(item => ({
                value: item.id,
                label: item.crane_type_name
            }));

            setCraneTypes(transformedOptions); // Suponiendo que la respuesta es un array de objetos
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/ubicaciones">Grúas</Link></li>
                        <li className="breadcrumb-item active">Nueva Grúa</li>
                    </ol>
                    <h1 className="page-header mb-0">Registro de nueva Grúa</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Registro de nueva Grúa</PanelHeader>
                        <PanelBody>
                            <Formik
                                validationSchema={yup.object().shape({
                                    crane_type_id: yup.number().required("Seleccione la categoría de grúa del vehículo"),
                                    crane_number: yup.number("El número de grúa debe ser un número").required("Ingrese el número de Grúa"),
                                    plate: yup.string().required("Ingrese la placa de la grúa"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.post('/cranes/save', values).then(response => {
                                        Swal.fire({
                                            title: 'Registro exitoso.',
                                            text: 'Se ha registrado la nueva Grúa',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/cranes');
                                    }).catch(err => {
                                        console.log(err);
                                        
                                        let message = err.response.data.message;
                                       
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={{
                                    crane_type_id: '',
                                    crane_number: '',
                                    plate: ''
                                }}
                            >
                                {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12" >
                                                <Form.Label>Categoría de grúa</Form.Label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    placeholder="Seleccione una categoría de grúa"
                                                    defaultValue=''
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    name="crane_type_id"
                                                    options={craneTypes}
                                                    onChange={(e) => {
                                                        setFieldValue("crane_type_id", e.value);
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.crane_type_id}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12" >
                                                <Form.Label>Número de Grúa</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="crane_number"
                                                    placeholder="Número de Grúa"
                                                    value={values.crane_number}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.crane_number}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.crane_number}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12" >
                                                <Form.Label>Placas</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="plate"
                                                    placeholder="Placas"
                                                    value={values.plate}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.plate}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.plate}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>



                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar Grúa</Button>{' '}
                                                <Link to={'/catalogs/cranes'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default NewGrua;