import React, { useState } from "react";
import "./styles.css";

const TripleToggleSwitch = (props) => {
    const { keyIndex, states, value, onChange } = props;

    const [switchPosition, setSwitchPosition] = useState(value)
    const [animation, setAnimation] = useState(null)
    const currentPosition = states.find(obj => obj.value === value)?.position;

    const getSwitchAnimation = (value) => {
        onChange(value);
        setSwitchPosition(value);
    };

    return (
        <div className="main-container">
            <div
                className={`switch ${animation} ${currentPosition}-position`}
            ></div>
            {states.map((state, index) => {
                return <span key={index}>
                    <input
                        onChange={(e) => getSwitchAnimation(e.target.value)}
                        name={"switch_" + keyIndex}
                        className={state.position + "-check"}
                        id={state.id + keyIndex}
                        type="radio"
                        value={state?.value !== null ? state?.value : ''}
                    />
                    <label
                        className={`${state.position}-label ${switchPosition === state.value && "black-font"
                            } triple-toggle-switch-label`}
                        htmlFor={state.id + keyIndex}
                    >
                        <span >{state.check_title}</span>
                    </label>
                </span>
            })}
        </div>
    )
}

export default TripleToggleSwitch
