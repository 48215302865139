import React, { useCallback } from 'react';
import { fromLonLat } from 'ol/proj';
import { Point } from 'ol/geom';
import './ol.css';

import { RMap, ROSM, RLayerVector, RFeature, ROverlay, RStyle, RControl } from 'rlayers';
import locationIcon from './location.svg';


function OrderMapPrint({ label, latitude, longitude }) {
    const [collapsed, setCollapsed] = React.useState(false);

    const newCenterLongitude = parseFloat(longitude) + 0.0025; // Ajusta este valor según tu necesidad

    return (
        <RMap
            className='example-map mb-1'
            initial={{ center: fromLonLat([longitude, latitude]), zoom: 15 }}
        >
            <ROSM />
            <RLayerVector zIndex={10}>
                <RStyle.RStyle>
                    <RStyle.RIcon src={locationIcon} anchor={[0.5, 0.8]} />
                </RStyle.RStyle>

                <RFeature
                    geometry={new Point(fromLonLat([longitude, latitude]))}
                    onClick={(e) =>
                        e.map.getView().fit(e.target.getGeometry().getExtent(), {
                            duration: 250,
                            maxZoom: 10
                        })
                    }
                >
                    {label && (
                        <ROverlay className='example-overlay'>
                            {label}
                        </ROverlay>
                    )}
                </RFeature>
            </RLayerVector>
        </RMap>
    );
}

export default OrderMapPrint;