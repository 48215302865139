import React, { Fragment, useState, useEffect } from 'react';
import axiosClient from '../../../../config/axios.js';
import { FieldArray } from 'formik';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Vehicle from './vehicle.js';

function OrderVehicles({ token, values, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors }) {

    const [colors, setColors] = useState([]);
    const [shieldingTypes, setShieldingTypes] = useState([]);
    const [isLoadingVehicleType, setIsLoadingVehicleType] = useState(false);
    const [isLoadingBrand, setIsLoadingBrand] = useState(false);
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [brands, setBrands] = useState([]);
    const [yearsVehicles, setYearsVehicles] = useState([]);



    const fetchYearsVehicles = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 1900;

        const yearOptions = [];
        for (let year = currentYear + 1; year >= startYear; year--) {
            yearOptions.push({ value: year.toString(), label: year.toString() });
        }
        return yearOptions;
    };


    useEffect(() => {
        axiosClient.get('/colors/all').then(response => {

            const formattedOptions = response.data.data.map(color => ({
                value: color.id,
                label: color.color_name,
                color: color.color_code,
                colorContrast: color.color_contrast
            }));
            setColors(formattedOptions);
            //console.log(colors);
        }).catch(error => {
            console.error('Error fetching colors:', error);
        });

        axiosClient.get('/vehicleTypes/all').then(response => {
            const formattedOptions = response.data.data.map(vehicleType => ({
                value: vehicleType.id,
                label: vehicleType.vehicle_type_name
            }));
            setVehicleTypes(formattedOptions);
        }).catch(error => {
            console.error('Error fetching vehicleTypes:', error);
        });

        axiosClient.get('/brands/all').then(response => {
            const formattedOptions = response.data.data.map(brand => ({
                value: brand.id,
                label: brand.brand_name
            }));
            setBrands(formattedOptions);
        }).catch(error => {
            console.error('Error fetching vehicleTypes:', error);
        });

        axiosClient.get('/shieldingTypes/all').then(response => {
            const formattedOptions = response.data.data.map(shieldingType => ({
                value: shieldingType.id,
                label: shieldingType.shielding_type
            }));
            setShieldingTypes(formattedOptions);
        }).catch(error => {
            console.error('Error fetching shieldingTypes:', error);
        });

        setYearsVehicles(fetchYearsVehicles());

    }, []);

    const handleCreateVehicleType = (inputValue, index) => {

        Swal.fire({
            title: '¿Está seguro que desea agregar un nuevo Tipo de Vehículo?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, agregar Tipo de vehículo',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {

                setIsLoadingVehicleType(true);

                axiosClient.post('/vehicleTypes/save', { vehicle_type_name: inputValue }).then(response => {
                    setIsLoadingVehicleType(false);
                    const newOption = {
                        value: response.data.id,
                        label: response.data.vehicle_type_name
                    };

                    setVehicleTypes((prev) => [...prev, newOption]);
                    setFieldValue('vehiculos.' + index + '.vehicle_type_id', newOption);

                }).catch(err => {
                    Swal.fire({
                        title: 'Ups!',
                        text: 'Ha ocurrido un error con el registro de Tipo de Vehículo. Intenta registrarlo en el catálogo.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: false,
                    });
                });
            }
        })

    };

    const handleCreateBrand = (inputValue, index) => {
        Swal.fire({
            title: '¿Está seguro que desea agregar una nueva Marca?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, agregar Marca',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {


                setIsLoadingBrand(true);

                axiosClient.post('/brands/save', { brand_name: inputValue }).then(response => {
                    setIsLoadingBrand(false);
                    const newOption = {
                        value: response.data.id,
                        label: response.data.brand_name
                    };

                    setBrands((prev) => [...prev, newOption]);
                    setFieldValue('vehiculos.' + index + '.brand_id', newOption);

                }).catch(err => {
                    Swal.fire({
                        title: 'Ups!',
                        text: 'Ha ocurrido un error con el registro de la Marca. Intenta registrarla en el catálogo.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: false,
                    });
                });
            }
        })



    };

    const handleCreateVehicleModel = (inputValue, setIsLoadingVehicleModel, setVehicleModels, index) => {
        Swal.fire({
            title: '¿Está seguro que desea agregar un nuevo modelo de vehículo?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, agregar Modelo',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {

                setIsLoadingVehicleModel(true);

                axiosClient.post('/vehicleModels/save', {
                    model_name: inputValue,
                    vehicle_type_id: values.vehiculos[index].vehicle_type_id.value,
                    brand_id: values.vehiculos[index].brand_id.value

                }).then(response => {
                    setIsLoadingVehicleModel(false);
                    const newOption = {
                        value: response.data.id,
                        label: response.data.model_name
                    };

                    setVehicleModels((prev) => [...prev, newOption]);
                    setFieldValue('vehiculos.' + index + '.vehicle_model_id', newOption);

                }).catch(err => {

                    Swal.fire({
                        title: 'Ups!',
                        text: 'Ha ocurrido un error con el registro del Modelo de Vehículo. Intenta registrarlo en el catálogo.',
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        cancelButtonText: 'Cancelar',
                        showCancelButton: false,
                    });
                });
            }
        })




    };



    return (
        <>
            <FieldArray
                name="vehiculos"
                render={arrayHelpers => {
                    const vehiculos = values.vehiculos;
                    return (
                        <>
                            {values.montacarga !== true && (
                                <>
                                    <Row className="mb-3">
                                        <div className="col-md-12">
                                            <Form.Group as={Col} >
                                                <Form.Check
                                                    name="carta_porte"
                                                    type="switch"
                                                    label="Se requiere Carta Porte"
                                                    checked={values.carta_porte}
                                                    onChange={(e) => {
                                                        setFieldValue('carta_porte', e.target.checked);
                                                    }}
                                                    onBlur={handleBlur}
                                                    disabled={values.orderStatuses?.ENTREGA?.has_passed_status}
                                                />
                                            </Form.Group>
                                        </div>

                                    </Row>

                                    <Row className="mb-1">
                                        <div className="col-md-12">
                                            {vehiculos && vehiculos.length > 0
                                                ? vehiculos.map((vehiculo, index) => (

                                                    <Vehicle
                                                        key={`vehicle_${index}`}
                                                        token={token}
                                                        index={index}
                                                        arrayHelpers={arrayHelpers}
                                                        setFieldValue={setFieldValue}
                                                        setFieldTouched={setFieldTouched}
                                                        errors={errors}
                                                        touched={touched}
                                                        values={values}
                                                        handleCreateVehicleType={handleCreateVehicleType}
                                                        handleCreateBrand={handleCreateBrand}
                                                        handleCreateVehicleModel={handleCreateVehicleModel}
                                                        colors={colors}
                                                        vehicleTypes={vehicleTypes}
                                                        brands={brands}
                                                        yearsVehicles={yearsVehicles}
                                                        shieldingTypes={shieldingTypes}
                                                        isLoadingVehicleType={isLoadingVehicleType}
                                                        isLoadingBrand={isLoadingBrand}
                                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                                    />
                                                ))
                                                : null
                                            }
                                        </div>

                                    </Row>
                                    {!values.orderStatuses?.CIERRE?.has_passed_status && !values.orderStatuses?.CANCELADO?.has_passed_status && (
                                        <Row className='mb-3'>
                                            <div className="col-md-12">
                                                <div className="d-flex align-items-center float-end">
                                                    <div >
                                                        <button
                                                            type="button"
                                                            className="btn btn-success btn-icon btn-circle btn-sm "

                                                            onClick={() =>
                                                                arrayHelpers.push({
                                                                    vehicle_type_id: null,
                                                                    brand_id: null,
                                                                    vehicle_model_id: null,
                                                                    anio: '',
                                                                    color_id: '',
                                                                    observaciones: '',
                                                                    placas: '',
                                                                    numero_serie: '',
                                                                    shielding_type_id: '',
                                                                    carga_kg: '',
                                                                    registrar_volumen: false,
                                                                    volumen_largo: '',
                                                                    volumen_ancho: '',
                                                                    volumen_altura: '',
                                                                    volumen_m3: '',
                                                                    estado_llantas_giran: '',
                                                                    estado_volante_gira: '',
                                                                    estado_pie_calle: '',
                                                                    estado_neutral: '',
                                                                    estado_en_cajon: '',
                                                                    asistencia_paso_corriente: '',
                                                                    asistencia_combustible: '',
                                                                    asistencia_cambio_neumatico: '',
                                                                    asistencia_cerrajeria: '',
                                                                })
                                                            }
                                                        >
                                                            <i className="fa-solid fa-plus"></i>
                                                        </button>

                                                    </div>
                                                    <div className='ms-1'>
                                                        Agregar vehículo
                                                    </div>
                                                </div>

                                            </div>
                                        </Row>
                                    )}
                                </>

                            )}





                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Observaciones</Form.Label>

                                    <textarea
                                        name="bienes_observaciones"
                                        className={`form-control ${errors.bienes_observaciones ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            setFieldValue('bienes_observaciones', e.target.value);
                                        }}
                                        value={values.bienes_observaciones}
                                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                    ></textarea>

                                    <Form.Control.Feedback type="invalid">
                                        {errors.bienes_observaciones}
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Row>

                        </>
                    );
                }}
            />
        </>
    );
}

export default OrderVehicles;