import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';


function ChangePassword() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;

    const [initialValues, setInitialValues] = useState({
        name: '',
        first_surname: '',
        second_surname: '',
        username: '',
        active: false
    });

    useEffect(() => {
        axiosClient.get(`/users/${id}`).then(response => {
            const { name, first_surname, second_surname, username, active } = response.data;
            setInitialValues({ name, first_surname, second_surname, username, active });
        }).catch(error => {
            console.error('Error fetching user data:', error);
        });
    }, []);

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/settings/users">Usuarios</Link></li>
                        <li className="breadcrumb-item active">Actualización de contraseña</li>
                    </ol>
                    <h1 className="page-header mb-0">Actualización de contraseña</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Actualización de contraseña</PanelHeader>
                        <PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    username: yup.string().required("Ingrese un nombre de usuario"),
                                    password: yup.string().required("Ingrese la contraseña"),
                                    confirm_password: yup.string().required("Ingrese la confirmación de la contraseña").oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    console.log(values);
                                    axiosClient.put(`/users/password/${id}`, values).then(response => {
                                        
                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'La contraseña se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/settings/users');
                                    }).catch(err => {
                                        let message = '';
                                        console.log(err);
                                        if (err.response.data.original.code === '23505') {
                                            message = 'El nombre de usuario ya existe. Ingresa uno diferente.';
                                        } else {
                                            message = err.response.data.message;
                                        }
                                        console.log(err.response);
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={initialValues}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12" >
                                                <Form.Label>Usuario</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Usuario"
                                                    name="username"
                                                    value={values.username}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.username}
                                                    readOnly
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.username}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Contraseña</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Contraseña"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.password}
                                                    autoComplete="off"
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Repetir Contraseña</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Repetir Contraseña"
                                                    name="confirm_password"
                                                    value={values.confirm_password}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.confirm_password}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {errors.confirm_password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>


                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar cambios</Button>{' '}
                                                <Link to={'/settings/users'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>

                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default ChangePassword;