import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import esLocale from "date-fns/locale/es";

function AgreementFields({ values, handleChange, setFieldValue, setFieldTouched, touched, errors }) {

    return (
        <>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm={2}>
                    Fecha de inicio del convenio
                </Form.Label>
                <Col sm={10}>

                    <DatePicker
                        name="fecha_inicio_convenio"
                        selected={values.fecha_inicio_convenio}
                        onChange={(date) => {
                            setFieldValue('fecha_inicio_convenio', date);
                            setFieldTouched('fecha_inicio_convenio', true);
                        }}
                        onBlur={() => {
                            setFieldTouched('fecha_inicio_convenio', true);
                        }}
                        className={`form-control ${touched.fecha_inicio_convenio && !!errors.fecha_inicio_convenio ? 'is-invalid' : ''}`}
                        locale={esLocale}
                        dateFormat='dd/MM/yyyy'
                        isInvalid={touched.fecha_inicio_convenio && !!errors.fecha_inicio_convenio}
                    />

                </Col>

            </Form.Group>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm={2}>
                    Fecha de término de convenio
                </Form.Label>
                <Col sm={10}>

                    <DatePicker
                        name="fecha_fin_convenio"
                        selected={values.fecha_fin_convenio}
                        onChange={(date) => {
                            setFieldValue('fecha_fin_convenio', date);
                            setFieldTouched('fecha_fin_convenio', true);
                        }}
                        onBlur={() => {
                            setFieldTouched('fecha_fin_convenio', true);
                        }}
                        className={`form-control ${touched.fecha_fin_convenio && !!errors.fecha_fin_convenio ? 'is-invalid' : ''}`}
                        locale={esLocale}
                        dateFormat='dd/MM/yyyy'
                        isInvalid={touched.fecha_fin_convenio && !!errors.fecha_fin_convenio}
                    />

                </Col>

            </Form.Group>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm={2}>

                </Form.Label>
                <Col sm={10}>
                    <Form.Check
                        name="activo"
                        type="switch"
                        label="Convenio activo"
                        checked={values.activo}
                        onChange={(e) => {
                            setFieldValue('activo', e.target.checked);
                            setFieldTouched('activo', true);
                        }}
                    />
                </Col>

            </Form.Group>

            <Form.Label column sm={2}>
                Servicio local
            </Form.Label>


            <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm={2}>
                    Costo fijo
                </Form.Label>
                <Col sm={10}>

                    <Form.Control
                        type="text"
                        name="servicio_local_costo"
                        placeholder=""
                        value={values.grua_local_costo}
                        onChange={handleChange}
                        isInvalid={touched.servicio_local_costo && !!errors.servicio_local_costo}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.servicio_local_costo}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        </>
    );
}

export default AgreementFields;