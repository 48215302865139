import React from 'react';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';

function ShieldingTypeFields({ values, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors }) {
    return (

        <Row className="mb-3">
            <Form.Group as={Col} md="12" >
                <Form.Label>Nombre de la Categoría de Blindaje</Form.Label>
                <Form.Control
                    type="text"
                    name="shielding_type"
                    placeholder="Nombre de la Categoría de Blindaje"
                    value={values.shielding_type}
                    onChange={handleChange}
                    isInvalid={!!errors.shielding_type}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.shielding_type}
                </Form.Control.Feedback>
            </Form.Group>
        </Row>


    );
}

export default ShieldingTypeFields;