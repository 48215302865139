import React, { useEffect, useState } from 'react';
import axiosClient from '../../../config/axios';
import { Link } from 'react-router-dom';
import OrderTable from './ordersTable';

import ReactDOM from 'react-dom';


function Orders() {

	const [orderStatuses, setOrderStatuses] = useState([]);
	const [currentStatus, setCurrentStatus] = useState('TODAS');
	const [loading, setLoading] = useState(true);

	const fetchOrderStatuses = async () => {

		try {
			try {
				const response = await axiosClient.get('/orderStatuses/allWithOrderCount', {
					params: {
						sortColumn: 'id'
					},
				});
				setOrderStatuses(response.data.data);
				setLoading(false);
			} catch (error) {
				console.error(error);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchOrderStatuses();
	}, []);

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
						<li className="breadcrumb-item active">Cartas de Servicio</li>
					</ol>
					<h1 className="page-header mb-0">Cartas de Servicio</h1>
				</div>
				<div className="ms-auto">
					<Link to={'/orders/newOrder'} className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Nueva Carta de Servicio</Link>
				</div>
			</div>


			<div className="row mb-3">
				<div className="col-xl-12">
					<div className="card border-0">
						<ul className="nav nav-tabs nav-tabs-v2 px-3">
							<li className="nav-item me-2">
								<a href='#tab_appointment' data-bs-toggle="tab" className={`nav-link px-2`}>
									<span className='me-2'>Citas</span>
								</a>
							</li>
							<li className="nav-item me-2" onClick={() => setCurrentStatus('TODAS')}>
								<a href='#tab_all' data-bs-toggle="tab" className={`nav-link px-2 ${currentStatus === 'TODAS' ? 'active' : ''}`}>
									<span className='me-2'>Todas</span>
								</a>
							</li>
							{orderStatuses && orderStatuses.map((orderStatus, index) => (
								<li key={`nav_link_${orderStatus.id}`} className="nav-item me-2" onClick={() => setCurrentStatus(orderStatus.order_status_code)}>
									<a href={`#tab_${orderStatus.id}`} data-bs-toggle="tab" className={`nav-link px-2 ${orderStatus.order_status_code === currentStatus ? 'active' : ''}`}>
										{orderStatus.count_orders !== '0' ? (<span className={`badge bg-${orderStatus.color} rounded-pill me-1`}>{orderStatus.count_orders}</span>) : <span className=''></span>}
										<span className='me-2'>{orderStatus.order_status}</span>
									</a>
								</li>
							))}
						</ul>
						<div className="tab-content p-3">
							<div key='tab_pane_appointment' className='tab-pane fade' id='tab_appointment'>
								a
							</div>
							<div key='tab_pane_all' className={`tab-pane fade ${currentStatus === 'TODAS' ? 'active show' : ''}`} id='tab_all'>
								{
									currentStatus === 'TODAS' ?
										<OrderTable
											key={`OrderTable_all`}
											orderStatusCode={null}
										/>
										:
										null
								}
							</div>
							{orderStatuses && orderStatuses.map((orderStatus, index) => (
								<div key={`tab_pane_${orderStatus.id}`} className={`tab-pane fade ${orderStatus.order_status_code === currentStatus ? 'active show' : ''}`} id={`tab_${orderStatus.id}`} >
									{
										orderStatus.order_status_code === currentStatus ?
											<OrderTable
												key={`OrderTable_${orderStatus.id}`}
												orderStatusCode={orderStatus.order_status_code}
											/>
											: null
									}

								</div>
							))}
						</div>

					</div>

				</div>

			</div>


		</div >

	);
}

export default Orders;