import React from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';


function OrderStatusPagado({ fetchCurrentOrderStatusByOrder, setCurrentOrderStatus, modalInstance, reloadOrderDataFromDB, order_status_code, orderValues }) {
    const { id } = useParams();
    const { Formik } = formik;

    return (
        <>
            <Formik
                validationSchema={yup.object().shape({
                    confirmacion_pago_recibido: yup.boolean().test('is-true', 'Se debe confirmar la recepción del pago', value => value === true),
                    observaciones: yup.string()
                })}
                initialValues={{
                    confirmacion_pago_recibido: false,
                    observaciones: ''
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);


                    axiosClient.put(`/orders/updateCurrentOrderStatus/${id}`, {
                        order_status_code,
                        observations: values.observaciones
                    }).then(async response => {
                        const result = await fetchCurrentOrderStatusByOrder();
                        setCurrentOrderStatus(result);
                        console.table("setCurrentOrderStatus", result);

                        if (modalInstance) {

                            modalInstance.hide();
                        }
                        reloadOrderDataFromDB();

                    }).catch(err => {
                        console.log(err.response);
                        Swal.fire({
                            title: 'Ups!',
                            text: 'Ha ocurrido un error',
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            cancelButtonText: 'Cancelar',
                            showCancelButton: false,
                        });

                    });

                }}

            >

                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                    <>
                        <div className="modal-header">
                            <h4 className="modal-title">Registrar pago recibido</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div className="modal-body">
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-4">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>{' '}</Form.Label>
                                        <Form.Check
                                            name="confirmacion_pago_recibido"
                                            type="switch"
                                            label="Confirmo que se ha recibido el pago"
                                            checked={values.confirmacion_pago_recibido}
                                            className={errors.confirmacion_pago_recibido ? 'is-invalid' : ''}
                                            onChange={(e) => {
                                                setFieldValue('confirmacion_pago_recibido', e.target.checked);
                                            }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.confirmacion_pago_recibido}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                {orderValues.excedente_asegurado && order_status_code === 'PAGADO_CONTADO' ? (
                                    <Row className="mb-4">
                                        <Form.Label>Excedente asegurado (neto)</Form.Label>
                                        <Form.Group as={Col} md="12" >
                                            <InputGroup className={`${errors.monto_extra_monto ? 'is-invalid' : ''}`}>
                                                <InputGroup.Text>$</InputGroup.Text>
                                                <CurrencyInput
                                                    autoComplete='off'
                                                    className={`form-control ${errors.monto_extra_monto ? 'is-invalid' : ''}`}
                                                    prefix=""
                                                    allowNegativeValue={false}
                                                    decimalsLimit={2}
                                                    decimalScale={2}
                                                    decimalSeparator="."
                                                    groupSeparator=","
                                                    value={orderValues.excedente_asegurado}
                                                    disabled
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Row>
                                ) : null}


                                <Row className="mb-1">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Observaciones</Form.Label>
                                        <textarea
                                            name="observaciones"
                                            className={`form-control ${errors.observaciones ? 'is-invalid' : ''}`}
                                            onChange={(e) => {
                                                setFieldValue('observaciones', e.target.value);
                                            }}
                                            value={values.observaciones}
                                        ></textarea>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.observaciones}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                            </Form>

                        </div>
                        <div className="modal-footer">
                            <Button type="button" data-bs-dismiss="modal" className='btn-white'>Cerrar</Button>
                            <Button type="button" onClick={handleSubmit} disabled={Object.keys(errors).length > 0}>Actualizar estatus</Button>
                        </div>
                    </>
                )}
            </Formik>


        </>
    );
}

export default OrderStatusPagado;