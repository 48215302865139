import React from 'react';

const SqlViewTablaResultado = ({ datos }) => {
    // Suponiendo que 'datos' es un arreglo de objetos con las filas y columnas obtenidas

    return (
        <table className='table table-bordered'>
            <thead>
                <tr>
                    {Object.keys(datos[0]).map((header) => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {datos.map((fila, index) => (
                    <tr key={index}>
                        {Object.values(fila).map((value, i) => (
                            <td key={i}>{value}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default SqlViewTablaResultado;