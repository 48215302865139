import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

function ColorFields({ values, handleChange, touched, errors }) {
    return (
        <>
            <Form.Group as={Col} md="8">
                <Form.Label>Nombre del Color</Form.Label>
                <Form.Control
                    type="text"
                    name="color_name"
                    placeholder="Nombre del Color"
                    value={values.color_name}
                    onChange={handleChange}
                    isInvalid={touched.color_name && !!errors.color_name}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.color_name}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
                <Form.Label>Color</Form.Label>
                <Form.Control
                    type="color"
                    defaultValue="#563d7c"
                    title="Selecciona un color"
                    name="color_code"
                    value={values.color_code}
                    onChange={handleChange}
                    isInvalid={touched.color_code && !!errors.color_code}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.color_code}
                </Form.Control.Feedback>
            </Form.Group>
        </>
    );
}

export default ColorFields;