import { Link, useNavigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';


function NewBrand() {
    const navigate = useNavigate();
  const { Formik } = formik;

  return (
    <div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
						<li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/marcas">Marcas</Link></li>
						<li className="breadcrumb-item active">Nueva Marca</li>
					</ol>
					<h1 className="page-header mb-0">Registro de nueva Marca</h1>
				</div>
			</div>

			
		
			<div className="row mb-3">
				<div className="col-xl-12">
					<Panel>
						<PanelHeader>Registro de nueva Marca</PanelHeader>
						<PanelBody>
                            <Formik
                                validationSchema={yup.object().shape({
                                    brand_name: yup.string().required("Ingrese el nombre de la Marca"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.post('/brands/save', values).then( response => {
                                        Swal.fire({
                                            title: 'Registro exitoso.',
                                            text: 'Se ha registrado la nueva Marca',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/brandsModels');
                                    }).catch(err => {
                                        console.log(err);
                                        let message = '';
                                        if(err.response.data.original.code === '23505'){
                                            message = 'El nombre de la marca ya existe. Ingresa uno diferente.';
                                        } else {
                                            message=err.response.data.message;
                                        }
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={{
                                    brand_name: ''
                                }}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12" >
                                                <Form.Label>Nombre de la Marca</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="brand_name"
                                                    placeholder="Nombre de la Marca"
                                                    value={values.brand_name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.brand_name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.brand_name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        
                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar Marca</Button>{' '}
                                                <Link to={'/catalogs/brandsModels'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                            
						</PanelBody>
					</Panel>
				</div>
				
			</div>
					
			
		</div>
    
  );
}

export default NewBrand;