import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DateTime from 'react-datetime';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import { useAuth } from '../../../../context/AuthContext.js';

function OrderFieldsInformacionGeneral({ opcionesEsCita, values, bases, handleChange, handleBlur, setFieldTouched, setFieldValue, touched, errors }) {
    const { id } = useParams();
    const { userId, fullName } = useAuth();

    const fechaHoraContacto = useRef(null);
    const fechaHoraEntrega = useRef(null);
    const fechaHoraCita = useRef(null);
    const alertaAntesHoraCita = useRef(null);

    moment.locale('es');

    useEffect(() => {
        if (!id && !values.base_id) {
            setFieldValue('base_id', {
                value: userId,
                label: fullName
            })
        }
    }, [values.base_id]);

    useEffect(() => {
        if (values.fecha_hora_cita && values.alerta_antes_hora_cita) {

            setFieldValue('fecha_hora_alerta_cita',
                moment(values.fecha_hora_cita).subtract({
                    hours: moment(values.alerta_antes_hora_cita).hours(),
                    minutes: moment(values.alerta_antes_hora_cita).minutes()
                })
            );
           
        }
    }, [values.fecha_hora_cita]);


    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Fecha en la que se atenderá el servicio</Form.Label>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={opcionesEsCita[0]}
                        isDisabled={values.orderStatuses?.ENVIADA?.has_passed_status}
                        isLoading={false}
                        isClearable={false}
                        isSearchable={false}
                        name="es_cita"
                        value={values.es_cita}
                        options={opcionesEsCita}
                        onBlur={handleBlur}
                        onChange={(e) => {
                            setFieldValue("es_cita", e);
                            setFieldValue("fecha_hora_cita", "");
                            setFieldValue("alerta_antes_hora_cita", "");
                            setFieldValue("fecha_hora_alerta_cita", "");
                            setFieldValue("fecha_hora_contacto", "");
                            setFieldValue("fecha_hora_entrega", "");
                        }}

                    />

                </Form.Group>
            </Row>

            {values.es_cita && values.es_cita.value ?
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" >
                        <Form.Label>Fecha y hora de la cita</Form.Label>
                        <DateTime
                            name="fecha_hora_cita"
                            ref={fechaHoraCita}
                            inputProps={{
                                placeholder: 'Fecha y hora de la cita',
                                disabled: values.orderStatuses?.ENVIADA?.has_passed_status
                            }}
                            closeOnSelect={true}
                            locale="es"
                            value={values.fecha_hora_cita}
                            className={errors.fecha_hora_cita ? 'is-invalid' : ''}
                            dateFormat="dddd DD/MMMM/YYYY"
                            timeFormat="hh:mm A"
                            initialViewMode="days"
                            onOpen={(e) => {
                                fechaHoraCita.current.navigate('days');
                                setFieldTouched('fecha_hora_cita', true);
                            }}
                            onChange={(e) => {
                                setFieldValue("fecha_hora_cita", e ? e : '');
                            }}
                            isInvalid={touched.fecha_hora_cita && !!errors.fecha_hora_cita}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.fecha_hora_cita}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" >
                        <Form.Label>Alerta antes de la cita</Form.Label>
                        <DateTime
                            name="alerta_antes_hora_cita"
                            ref={alertaAntesHoraCita}
                            inputProps={{
                                placeholder: 'Fecha y hora de la cita',
                                disabled: values.orderStatuses?.ENVIADA?.has_passed_status
                            }}
                            closeOnSelect={true}
                            locale="es"
                            value={values.alerta_antes_hora_cita}
                            className={errors.alerta_antes_hora_cita ? 'is-invalid' : ''}
                            dateFormat={false}
                            timeFormat="HH:mm"
                            initialViewMode="time"
                            onOpen={(e) => {
                                alertaAntesHoraCita.current.navigate('time');
                                setFieldTouched('alerta_antes_hora_cita', true);
                            }}
                            onChange={(e) => {
                                setFieldValue("alerta_antes_hora_cita", e ? e : '');



                                setFieldValue("fecha_hora_alerta_cita", moment(values.fecha_hora_cita).subtract({
                                    hours: moment(e).hours(),
                                    minutes: moment(e).minutes()
                                }));
                            }}
                            isInvalid={touched.alerta_antes_hora_cita && !!errors.alerta_antes_hora_cita}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.alerta_antes_hora_cita}
                        </Form.Control.Feedback>

                        {values?.fecha_hora_cita && values?.alerta_antes_hora_cita && (
                            <>
                                Alerta programada para el {values.fecha_hora_alerta_cita ? moment(values.fecha_hora_alerta_cita).format("dddd DD/MMMM/YYYY hh:mm a") : ''}
                                {values.fecha_hora_alerta_cita && moment(values.fecha_hora_alerta_cita).hour() === 0 && moment(values.fecha_hora_alerta_cita).minute() === 0 ? ' (en el primer minuto del ' + moment(values.fecha_hora_alerta_cita).format("DD/MMMM/YYYY") + ')' : ''}
                            </>
                        )}


                    </Form.Group>
                </Row>

                : null}



            <Row className="mb-3">

                <Form.Group as={Col} md="6" >
                    <Form.Label>Expediente No.</Form.Label>
                    <Form.Control
                        type="text"
                        name="expediente"
                        placeholder="Número de expediente"
                        value={values.expediente}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.expediente}
                        autoComplete="off"
                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.expediente}
                    </Form.Control.Feedback>

                </Form.Group>
                <Form.Group as={Col} md="6" >
                    <Form.Label>Folio de pago</Form.Label>
                    <Form.Control
                        type="text"
                        name="Folio de pago"
                        placeholder="Folio de pago"
                        value={values.reporte}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errors.reporte}
                        autoComplete="off"
                        disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.reporte}
                    </Form.Control.Feedback>

                </Form.Group>
            </Row>

            {values.es_cita && !values.es_cita.value && values.orderStatuses?.CONTACTO?.has_passed_status && (
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" >
                        <Form.Label>Fecha y hora de contacto</Form.Label>
                        <DateTime
                            name="fecha_hora_contacto"
                            ref={fechaHoraContacto}
                            inputProps={{
                                placeholder: 'Fecha y hora de contacto',
                                disabled: values.orderStatuses?.CONTACTO?.has_passed_status
                            }}
                            closeOnSelect={false}
                            locale="es"
                            value={values.fecha_hora_contacto}
                            className={errors.fecha_hora_contacto ? 'is-invalid' : ''}
                            dateFormat="dddd DD/MMMM/YYYY"
                            timeFormat="hh:mm A"
                            initialViewMode="days"
                            onOpen={(e) => {
                                fechaHoraContacto.current.navigate('days');
                                setFieldTouched('fecha_hora_contacto', true);
                            }}
                            onChange={(e) => {
                                setFieldValue("fecha_hora_contacto", e ? e : '');
                            }}
                            isInvalid={touched.fecha_hora_contacto && !!errors.fecha_hora_contacto}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.fecha_hora_contacto}
                        </Form.Control.Feedback>


                    </Form.Group>

                    {values.es_cita && !values.es_cita.value && values.orderStatuses?.ENTREGA?.has_passed_status && (

                        <Form.Group as={Col} md="6" >
                            <Form.Label>Fecha y hora de entrega</Form.Label>
                            <DateTime
                                name="fecha_hora_entrega"
                                ref={fechaHoraEntrega}
                                inputProps={{
                                    placeholder: 'Fecha y hora de entrega',
                                    disabled: values.orderStatuses?.ENTREGA?.has_passed_status
                                }}
                                closeOnSelect={true}
                                locale="es"
                                value={values.fecha_hora_entrega}
                                className={errors.fecha_hora_entrega ? 'is-invalid' : ''}
                                dateFormat="dddd DD/MMMM/YYYY"
                                timeFormat="hh:mm A"
                                initialViewMode="days"
                                onOpen={(e) => {
                                    fechaHoraEntrega.current.navigate('days');
                                    setFieldTouched('fecha_hora_entrega', true);
                                }}
                                onChange={(e) => {
                                    setFieldValue("fecha_hora_entrega", e ? e : '');
                                }}
                                isInvalid={touched.fecha_hora_entrega && !!errors.fecha_hora_entrega}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.fecha_hora_entrega}
                            </Form.Control.Feedback>

                        </Form.Group>
                    )}


                </Row>


            )}



            <Row>
                <Form.Group as={Col} md="12" >
                    <Form.Label>Usuario Base</Form.Label>
                    <Select
                        name="base_id"
                        options={bases}
                        className={errors.base_id ? 'is-invalid' : ''}
                        placeholder="Selecciona un usuario base"
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        isClearable={true}
                        isSearchable={true}
                        value={values.base_id}
                        onBlur={handleBlur}
                        onChange={(e) => {
                            setFieldValue("base_id", e);
                        }}
                        isDisabled={true}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.base_id}
                    </Form.Control.Feedback>

                </Form.Group>
            </Row>

        </>
    );
}

export default OrderFieldsInformacionGeneral;