import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import AssistanceVehicleFields from './assistanceVehicleFields';


function EditAssistanceVehicle() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;

    const [initialValues, setInitialValues] = useState({
        vehicle_type_id: '',
        brand_id: '',
        vehicle_model_id: '',
        anio: '',
        placas: '',
        numero_serie: ''
    });

    useEffect(() => {
        axiosClient.get(`/assistanceVehicles/${id}`).then(response => {
            const { anio, placas, numero_serie, VehicleModel } = response.data;

            console.log("response data getting vehicle data", response.data);

            setInitialValues({
                vehicle_type_id: {
                    value: VehicleModel.VehicleType.id,
                    label: VehicleModel.VehicleType.vehicle_type_name,
                },
                brand_id: {
                    value: VehicleModel.Brand.id,
                    label: VehicleModel.Brand.brand_name,
                },
                vehicle_model_id: {
                    value: VehicleModel.id,
                    label: VehicleModel.model_name,
                },
                anio: { value: anio, label: anio },
                placas,
                numero_serie
            });
            console.log("initialValues", initialValues);
        }).catch(error => {
            console.error('Error fetching assistance vehicles data:', error);
        });
    }, [id]);

    /*useEffect(() => {
        setInitialValues(prevState => ({
            ...prevState,
            vehicle_type_id: ''
        }));
    }, [initialValues]);*/

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/locations">Vehículo de Asistencia</Link></li>
                        <li className="breadcrumb-item active">Edición de Vehículo de Asistencia</li>
                    </ol>
                    <h1 className="page-header mb-0">Edición de Vehículo de Asistencia</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Edición de Vehículo de Asistencia</PanelHeader>
                        <PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    vehicle_type_id: yup.object().required("Selecciona o registra un tipo de vehículo"),
                                    brand_id: yup.object().required("Selecciona o registra una marca de vehículo"),
                                    vehicle_model_id: yup.object().required("Selecciona o registra un modelo de vehículo"),
                                    anio: yup.object().required('Selecciona un año'),
                                    placas: yup.string().required('El número de placa es obligatorio'),
                                    numero_serie: yup.string().required('El número de serie es obligatorio'),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.put(`/assistanceVehicles/${id}`, values).then(response => {
                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'El Vehículo de Asistencia se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/assistanceVehicles');
                                    }).catch(err => {
                                        let message = '';
                                        console.log(err);
                                        message = err.response.data.message;
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={initialValues}
                            >
                                {({ handleSubmit, handleChange, setFieldValue, setFieldTouched, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>

                                        <AssistanceVehicleFields
                                            values={values}
                                            handleChange={handleChange}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                            touched={touched}
                                            setFieldTouched={setFieldTouched}
                                        />

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar Vehículo de Asistencia</Button>{' '}
                                                <Link to={'/catalogs/assistanceVehicles'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default EditAssistanceVehicle;