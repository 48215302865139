import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment-duration-format';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
import axiosClient from '../../../config/axios.js';
import OrderFields from './orderFields';
import { orderValidationSchema, orderInitialValues, opcionesEsCita } from './orderFieldsYupValidation';
import OrderStatus from './orderStatus/orderStatus.js';
import OrderStatusCancelar from './orderStatus/orderStatusCancelar.js'

import { useReactToPrint } from 'react-to-print';

import { ComponentToPrint } from './orderPrint/ComponentToPrint.js';

import { Col } from 'react-bootstrap';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';



function EditOrder() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;
    const modalRefCancel = useRef();
    const modalRefPrint = useRef();

    const [modalInstance, setModalInstance] = useState(null);

    const [initialValues, setInitialValues] = useState(orderInitialValues);
    const [insuranceSelected, setInsuranceSelected] = useState(null);
    const [fechaHoraContacto, setFechaHoraContacto] = useState(null);
    const [fechaHoraReporte, setFechaHoraReporte] = useState(null);
    const [currentOrderStatus, setCurrentOrderStatus] = useState(null);
    const [operadores, setOperadores] = useState([]);
    const [statusEvaluacionActivo, setStatusEvaluacionActivo] = useState(false);


    const componentRef = useRef();



    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    /*
        const handlePrint = () => {
    
            const content = componentRef.current;
            console.log("content", content);
    
            const options = {
                filename: 'my-document.pdf',
                margin: [10,0,10,0],
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: {
                    unit: 'mm',
                    format: 'letter',
                    orientation: 'portrait',
                },
            };
    
            html2pdf().set(options).from(content).toPdf().save();
        };
    */



    const formatVehicle = (vehicles) => {
        const formatted = vehicles.map((item) => {
            return {
                id: item.id,
                vehicle_type_id: {
                    value: item.VehicleModel.VehicleType.id,
                    label: item.VehicleModel.VehicleType.vehicle_type_name,
                },
                brand_id: {
                    value: item.VehicleModel.Brand.id,
                    label: item.VehicleModel.Brand.brand_name,
                },
                vehicle_model_id: {
                    value: item.VehicleModel.id,
                    label: item.VehicleModel.model_name,
                },
                anio: { value: item.anio, label: item.anio },
                color_id: {
                    value: item?.Color?.id,
                    label: item?.Color?.color_name,
                    color: item?.Color?.color_code,
                    color_contrast: item?.Color?.color_contrast,
                },
                observaciones: item.observaciones,
                placas: item.placas,
                numero_serie: item.numero_serie,
                shielding_type_id: item.ShieldingType && item.ShieldingType.id ? {
                    value: item.ShieldingType?.id,
                    label: item.ShieldingType?.shielding_type,
                } : '',
                carga_kg: item.carga_kg ? item.carga_kg : '',
                registrar_volumen: item.registrar_volumen,
                volumen_largo: item.volumen_largo,
                volumen_ancho: item.volumen_ancho,
                volumen_altura: item.volumen_altura,
                volumen_m3: item.volumen_m3,
                estado_llantas_giran: item.estado_llantas_giran === true ? true : (item.estado_llantas_giran === false ? false : ''),
                estado_volante_gira: item.estado_volante_gira === true ? true : (item.estado_volante_gira === false ? false : ''),
                estado_pie_calle: item.estado_pie_calle === true ? true : (item.estado_pie_calle === false ? false : ''),
                estado_neutral: item.estado_neutral === true ? true : (item.estado_neutral === false ? false : ''),
                estado_en_cajon: item.estado_en_cajon === true ? true : (item.estado_en_cajon === false ? false : ''),

                asistencia_paso_corriente: item.asistencia_paso_corriente === true ? true : (item.asistencia_paso_corriente === false ? false : ''),
                asistencia_combustible: item.asistencia_combustible === true ? true : (item.asistencia_combustible === false ? false : ''),
                asistencia_cambio_neumatico: item.asistencia_cambio_neumatico === true ? true : (item.asistencia_cambio_neumatico === false ? false : ''),
                asistencia_cerrajeria: item.asistencia_cerrajeria === true ? true : (item.asistencia_cerrajeria === false ? false : ''),
            };
        });

        return formatted;
    }

    const formatOperador = (orderOperators) => {

        const formatted = orderOperators.map((item) => {
            return {
                value: item.User.id,
                label: item.User.name + ' ' + item.User.first_surname + (item.User.second_surname ? (' ' + item.User.second_surname) : ''),
                actividad: item.actividad ? item.actividad : '',
                encargado: item.encargado
            };
        });
        return formatted;
    }

    const formatMontacargas = (orderLiftTrucks) => {
        const formatted = orderLiftTrucks.map((item) => {
            return {
                value: item.LiftTruck.id,
                label: item.LiftTruck.lift_truck_number + ' ' + item.LiftTruck.description
            };
        });
        return formatted;
    }

    const formatVehiculosAsistencia = (orderAssistanceVehicles) => {
        orderAssistanceVehicles.map((item) => {
            console.log("itemmmm", item);
        });
        const formatted = orderAssistanceVehicles.map((item) => {
            return {
                value: item.assistance_vehicle_id,
                label: item.AssiVehi.VehicleModel.VehicleType.vehicle_type_name + ' ' + item.AssiVehi.VehicleModel.Brand.brand_name + ' ' + item.AssiVehi.VehicleModel.model_name + ' (' + item.AssiVehi.placas + ')',
            };
        });
        return formatted;
    }

    const formatAmounts = (amounts) => {
        const formatted = amounts.map((item) => {
            return {
                id: item.id,
                monto_extra_descripcion: item.description,
                monto_extra_monto: item.amount
            };
        });

        return formatted;
    }

    const formatOrderLocations = (order_locations) => {
        console.log("order_locations edit", order_locations);
        const formatted = order_locations.map((order_location) => {

            return {
                id: order_location.id,
                index: order_location.index,
                cuadro_busqueda: order_location.cuadro_busqueda,
                direccion: order_location.direccion,
                referencia: order_location.referencia,
                latitude: order_location.latitude,
                longitude: order_location.longitude,
                location_rate_id: order_location.location_rate,
                inside_polygon_local_rate: order_location.inside_polygon_local_rate,
                removable: true
            };
        });

        return formatted;
    }

    const fetchCurrentOrderStatusByOrder = async () => {
        try {
            const response = await axiosClient.get(`/orders/getCurrentOrderStatusByOrder/${id}`);

            return response.data;
        } catch (error) {
            throw new Error('Error fetchCurrentOrderStatusByOrder: ', error);
        }
    };

    const reloadOrderDataFromDB = () => {
        axiosClient.get(`/orders/${id}`).then(response => {
            console.log("response order data", response);
            const {
                id,
                fecha_hora_reporte,
                fecha_hora_termino,
                folio,
                current_order_status,
                es_cita,
                fecha_hora_cita,
                fecha_hora_alerta_cita,

                expediente,
                reporte,
                PaymentType,
                fecha_hora_contacto,
                fecha_hora_envio,
                fecha_hora_llegada,
                fecha_hora_entrega,

                //información del servicio ,
                grua,
                grua_vial,
                auxilio_vial,
                montacarga,
                corresponsalia,
                tipo_servicio_observaciones,

                //Datos de los bienes ,
                carta_porte,

                bienes_observaciones,

                //Datos de contacto y seguimiento,
                ClientType,
                Insurance,
                autorizacion_cabina,
                empresa,
                cliente_vip,
                beneficiario_servicio,
                telefono_principal,
                numero_poliza,
                numero_siniestro,
                numero_folio,
                monto_aseguradora_paga,
                excedente_asegurado,
                datos_contacto_seguimiento_observaciones,

                //Ubicación de la atención ,
                ServiceLocationType,
                edit_locations_finished,

                //Cálculo de montos ,
                km,
                Crane,
                base,

                grua_tarifa_kilometro,
                grua_subtotal,
                grua_banderazo,
                grua_casetas,

                aux_vial_tarifa_kilometro,
                aux_vial_subtotal,
                aux_vial_banderazo,
                aux_vial_casetas,

                montacargas_costo_hora,
                montacargas_horas,
                montacargas_subtotal,
                montacargas_traslado,

                corresponsalia_subtotal,
                monto_blindaje,
                monto_carga_volumen,

                manejar_precios_netos,
                manejar_tarifas_establecidas,
                venta_publico,
                subtotal,
                iva,
                total,
                OrderLiftTrucks,
                OrAssisVehi,
                OrderOperators,
                OrderLocations,
                OrderAmounts,
                Vehicles,
            } = response.data;

            console.log("OrAssisVehi", OrAssisVehi);

            console.log("fecha_hora_cita && fecha_hora_alerta_cita", fecha_hora_cita);
            console.log("fecha_hora_cita && fecha_hora_alerta_cita", fecha_hora_alerta_cita);
            console.log("fecha_hora_cita && fecha_hora_alerta_cita", moment().startOf('day').add(moment.duration(moment(fecha_hora_cita).diff(moment(fecha_hora_alerta_cita)))));

            setInitialValues({
                id: id,
                fecha_hora_reporte: fecha_hora_reporte,
                folio: folio,
                current_order_status_id: current_order_status,
                es_cita: opcionesEsCita.find(option => option.value === es_cita),
                //es_cita: es_cita ? { value: true, label: 'PROGRAMAR CITA' } : { value: false, label: 'ATENCIÓN INMEDIATA' },
                fecha_hora_cita: fecha_hora_cita ? moment(fecha_hora_cita).toDate() : '',
                fecha_hora_alerta_cita: '', //se deja a propósito debido a que se calcula al cambiar alerta_antes_hora_cita //fecha_hora_alerta_cita ? moment(fecha_hora_alerta_cita).toDate() : '',
                alerta_antes_hora_cita: fecha_hora_cita && fecha_hora_alerta_cita ? moment().startOf('day').add(moment.duration(moment(fecha_hora_cita).diff(moment(fecha_hora_alerta_cita)))) : '',
                expediente: expediente ? expediente : '',
                reporte: reporte ? reporte : '',
                tipo_pago_id: PaymentType ? {
                    value: PaymentType ? PaymentType.id : '',
                    label: PaymentType ? PaymentType.payment_type_name : '',
                    code: PaymentType ? PaymentType.payment_type_code : ''
                } : '',

                fecha_hora_contacto: fecha_hora_contacto ? moment(fecha_hora_contacto).toDate() : '',
                fecha_hora_envio: fecha_hora_envio ? moment(fecha_hora_envio).toDate() : '',
                fecha_hora_llegada: fecha_hora_llegada ? moment(fecha_hora_llegada).toDate() : '',
                fecha_hora_entrega: fecha_hora_entrega ? moment(fecha_hora_entrega).toDate() : '',
                fecha_hora_termino: fecha_hora_termino ? moment(fecha_hora_termino).toDate() : '',

                //información del servicio ,
                grua,
                grua_vial,
                auxilio_vial,
                montacarga,
                corresponsalia,
                tipo_servicio_observaciones,

                //Datos de los bienes ,
                carta_porte,

                bienes_observaciones: bienes_observaciones ? bienes_observaciones : '',

                //Datos de contacto y seguimiento,
                client_type_id: {
                    value: ClientType.id,
                    label: ClientType.client_type,
                    client_type_code: ClientType.client_type_code
                },
                insurance_id: Insurance ? {
                    value: Insurance.id,
                    label: Insurance.insurance_name,
                } : '',
                autorizacion_cabina: autorizacion_cabina ? autorizacion_cabina : '',
                empresa: empresa ? empresa : '',
                cliente_vip,
                beneficiario_servicio: beneficiario_servicio ? beneficiario_servicio : '',
                telefono_principal: telefono_principal ? telefono_principal : '',
                numero_poliza: numero_poliza ? numero_poliza : '',
                numero_siniestro: numero_siniestro ? numero_siniestro : '',
                numero_folio: numero_folio ? numero_folio : '',
                excedente_asegurado: excedente_asegurado === 0 ? 0 : (excedente_asegurado ? excedente_asegurado : null),
                monto_aseguradora_paga: monto_aseguradora_paga === 0 ? 0 : (monto_aseguradora_paga ? monto_aseguradora_paga : null),
                datos_contacto_seguimiento_observaciones: datos_contacto_seguimiento_observaciones ? datos_contacto_seguimiento_observaciones : '',

                //Ubicación de la atención ,
                service_location_type_id: {
                    value: ServiceLocationType?.id,
                    label: ServiceLocationType?.service_location_type,
                    service_location_code: ServiceLocationType?.service_location_code
                },
                edit_locations_finished,

                //Cálculo de montos ,
                km,
                crane_id: Crane != null ? {
                    value: Crane.id,
                    label: Crane.crane_number + ' (' + Crane.CraneTypes.map(craneType => craneType.crane_type_name).join(', ') + ')',
                    crane_type_name: Crane.CraneTypes.map(craneType => craneType.crane_type_name).join(', ')
                } : null,

                base_id: base ? {
                    value: base.id,
                    label: (base.name + ' ' + base.first_surname + ' ' + (base.second_surname ? base.second_surname : '')).trim(),
                } : '',


                //Montos
                grua_tarifa_kilometro: grua_tarifa_kilometro === 0 ? 0 : (grua_tarifa_kilometro ? grua_tarifa_kilometro : ''),
                grua_subtotal: grua_subtotal === 0 ? 0 : (grua_subtotal ? grua_subtotal : ''),
                grua_banderazo: grua_banderazo === 0 ? 0 : (grua_banderazo ? grua_banderazo : ''),
                grua_casetas: grua_casetas === 0 ? 0 : (grua_casetas ? grua_casetas : ''),

                aux_vial_tarifa_kilometro: aux_vial_tarifa_kilometro === 0 ? 0 : (aux_vial_tarifa_kilometro ? aux_vial_tarifa_kilometro : ''),
                aux_vial_subtotal: aux_vial_subtotal === 0 ? 0 : (aux_vial_subtotal ? aux_vial_subtotal : ''),
                aux_vial_banderazo: aux_vial_banderazo === 0 ? 0 : (aux_vial_banderazo ? aux_vial_banderazo : ''),
                aux_vial_casetas: aux_vial_casetas === 0 ? 0 : (aux_vial_casetas ? aux_vial_casetas : ''),

                montacargas_costo_hora: montacargas_costo_hora === 0 ? 0 : (montacargas_costo_hora ? montacargas_costo_hora : ''),
                montacargas_horas: montacargas_horas === 0 ? 0 : (montacargas_horas ? montacargas_horas : ''),
                montacargas_subtotal: montacargas_subtotal === 0 ? 0 : (montacargas_subtotal ? montacargas_subtotal : ''),
                montacargas_traslado: montacargas_traslado === 0 ? 0 : (montacargas_traslado ? montacargas_traslado : ''),

                corresponsalia_subtotal: corresponsalia_subtotal === 0 ? 0 : (corresponsalia_subtotal ? corresponsalia_subtotal : ''),

                monto_blindaje: monto_blindaje === 0 ? 0 : (monto_blindaje ? monto_blindaje : ''),
                monto_carga_volumen: monto_carga_volumen === 0 ? 0 : (monto_carga_volumen ? monto_carga_volumen : ''),

                manejar_precios_netos: manejar_precios_netos ? manejar_precios_netos : false,
                manejar_tarifas_establecidas: manejar_tarifas_establecidas ? manejar_tarifas_establecidas : false,
                venta_publico,
                subtotal,
                iva,
                total,

                montacargas: formatMontacargas(OrderLiftTrucks),
                vehiculos_asistencia: formatVehiculosAsistencia(OrAssisVehi),
                operadores: formatOperador(OrderOperators),
                montosExtras: formatAmounts(OrderAmounts),
                vehiculos: formatVehicle(Vehicles),
                order_locations: formatOrderLocations(OrderLocations)
            });

            console.log("vehiculos_asistencia", formatVehiculosAsistencia(OrAssisVehi));
            setStatusEvaluacionActivo(current_order_status.order_status_code === 'PAGADO');
        }).catch(error => {
            console.error('Error fetching order data:', error);
        });

    }

    useEffect(() => {

        axiosClient.get('/users/allOperators').then(response => {

            const formattedOptions = response.data.data.map(operator => ({
                value: operator.id,
                label: operator.name + ' ' + operator.first_surname + ' ' + operator.second_surname,
            }));
            setOperadores(formattedOptions);
        }).catch(error => {
            console.error('Error fetching allOperators:', error);
        });

        reloadOrderDataFromDB();
    }, [id]);

    const handleCancelOrder = (insurance_id, fecha_hora_contacto, fecha_hora_reporte) => {
        setInsuranceSelected(insurance_id);
        setFechaHoraContacto(fecha_hora_contacto);
        setFechaHoraReporte(fecha_hora_reporte);
        const modal = modalRefCancel.current;
        if (modal) {
            const modalInstance = new bootstrap.Modal(modal);
            setModalInstance(modalInstance);
            modalInstance.show();
        }

    };

    const handlePrintOrder = () => {
        const modal = modalRefPrint.current;
        if (modal) {
            const modalInstance = new bootstrap.Modal(modal);
            setModalInstance(modalInstance);
            modalInstance.show();
        }
    }

    return (
        <div>



            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item"><Link to="/orders">Órdenes</Link></li>
                        <li className="breadcrumb-item active">Edición de Orden</li>
                    </ol>
                    <h1 className="page-header mb-0">Edición de Orden
                        <Link to={`/orders/history/${id}`} className='ms-1'><button className="btn btn-default btn-icon btn-sm"><i className="fa fa-clock"></i></button></Link>
                    </h1>
                </div>
            </div>




            <div className="row mb-3">
                <div className="col-xl-12">
                    <Formik
                        enableReinitialize
                        validateOnChange
                        validationSchema={orderValidationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);

                            axiosClient.put(`/orders/${id}`, values).then(response => {

                                Swal.fire({
                                    title: 'Cambios guardados.',
                                    text: 'La orden se ha actualizado exitosamente',
                                    icon: 'success',
                                    confirmButtonText: 'Cerrar',
                                    cancelButtonText: 'Cancelar',
                                    showCancelButton: false,
                                });
                                navigate('/orders');
                            }).catch(err => {
                                let message = err;

                                console.log(err.response);
                                Swal.fire({
                                    title: 'Ups!',
                                    text: message,
                                    icon: 'error',
                                    confirmButtonText: 'Cerrar',
                                    cancelButtonText: 'Cancelar',
                                    showCancelButton: false,
                                });
                            });
                        }}
                        initialValues={initialValues}
                    >
                        {({ handleSubmit, handleChange, handleBlur, validateForm, values, touched, errors, setFieldValue, setFieldTouched }) => (

                            <>

                                <div>
                                    <div className="modal fade " ref={modalRefPrint}>

                                        <div className="modal-dialog modal-print">
                                            <div className="modal-content ">

                                                <Panel>
                                                    <PanelBody>
                                                        <Row>
                                                            <Col md="12">
                                                                <button className="btn btn-yellow float-end" onClick={handlePrint}><span className='fa fa-print' /> Imprimir</button>
                                                            </Col>
                                                        </Row>
                                                    </PanelBody>
                                                </Panel>


                                                <div ref={componentRef} id={'print'}>
                                                    <ComponentToPrint
                                                        id={id}
                                                        orderData={values}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <OrderStatus
                                    fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                    reloadOrderDataFromDB={reloadOrderDataFromDB}
                                    tipo_pago_id={values.tipo_pago_id}
                                    operadores={operadores}
                                    touched={touched}
                                    fecha_hora_envio={values.fecha_hora_envio}
                                    fecha_hora_contacto={values.fecha_hora_contacto}
                                    fecha_hora_llegada={values.fecha_hora_llegada}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    values={values}
                                    currentOrderStatus={currentOrderStatus}
                                    setCurrentOrderStatus={setCurrentOrderStatus}

                                />

                                <Form noValidate onSubmit={handleSubmit} encType="multipart/form-data">
                                    <Row className="mb-3">
                                        <OrderFields
                                            operadores={operadores}
                                            values={values}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                            touched={touched}
                                            errors={errors}
                                            opcionesEsCita={opcionesEsCita}
                                            fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                            reloadOrderDataFromDB={reloadOrderDataFromDB}
                                            handleCancelOrder={handleCancelOrder}
                                            handlePrintOrder={handlePrintOrder}
                                            currentOrderStatus={currentOrderStatus}
                                            setCurrentOrderStatus={setCurrentOrderStatus}
                                            validateForm={validateForm}
                                        />
                                    </Row>
                                </Form>
                            </>

                        )}
                    </Formik>
                </div>

            </div>



            <div className="modal modal-message fade" ref={modalRefCancel}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        {id && (
                            <OrderStatusCancelar
                                fetchCurrentOrderStatusByOrder={fetchCurrentOrderStatusByOrder}
                                setCurrentOrderStatus={setCurrentOrderStatus}
                                modalInstance={modalInstance}
                                reloadOrderDataFromDB={reloadOrderDataFromDB}
                                aseguradora={insuranceSelected}
                                fechaHoraContacto={fechaHoraContacto}
                                fechaHoraReporte={fechaHoraReporte}
                            />
                        )}
                    </div>
                </div>
            </div>


        </div>

    );
}

export default EditOrder;