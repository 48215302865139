import React from 'react';
import { FieldArray } from 'formik';
import { Row } from 'react-bootstrap';
import OrderAmountsFieldArrayAdicional from './orderAmountsFieldArrayAdicional';
import OrderAmountsFieldArrayGenerico from './orderAmountsFieldArrayGenerico';
import '../orderCustom.css';

function OrderAmountsFieldArray({ values, setFieldValue, setFieldTouched, handleChange, errors, tarifasGruaCalculadas, tarifasCasetas, tarifasAuxVialCalculadas, disabled }) {

    return (
        <FieldArray
            name="montosExtras"
            render={arrayHelpers => {
                const montosExtras = values.montosExtras;
                return (
                    <>
                        <Row className='mb-3'>
                            <div className='col-md-12'>
                                <div className="table-responsive">
                                    <table className="table table-amount">
                                        <thead>
                                            <tr>
                                                <th width="10%"></th>
                                                <th width="30%"></th>
                                                <th width="30%"></th>
                                                <th width="30%">SUBTOTAL</th>
                                            </tr>
                                        </thead>



                                        {values.grua && (
                                            <>
                                                {values.service_location_type_id.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false ? (
                                                    <tbody key="montos_grua_foraneo">
                                                        <OrderAmountsFieldArrayGenerico
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                            handleChange={handleChange}
                                                            errors={errors}
                                                            tipoServicio='GRÚA'
                                                            primeraVariableLabel='TARIFA POR KILÓMETRO'
                                                            segundaVariableLabel='KILOMETRAJE'
                                                            segundaVariableSufix='km.'
                                                            terceraVariableLabel='BANDERAZO'
                                                            cuartaVariableLabel='CASETAS'
                                                            primeraVariableKey='grua_tarifa_kilometro'
                                                            segundaVariableKey='km'
                                                            terceraVariableKey='grua_banderazo'
                                                            cuartaVariableKey='grua_casetas'
                                                            subTotalKeyName='grua_subtotal'
                                                            tarifasCalculadas={tarifasGruaCalculadas}
                                                            tarifasCasetas={tarifasCasetas}
                                                            disabled={disabled}
                                                        />
                                                    </tbody>
                                                ) : (
                                                    <tbody key="montos_grua_local">
                                                        <OrderAmountsFieldArrayGenerico
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                            handleChange={handleChange}
                                                            errors={errors}
                                                            tipoServicio='GRÚA'
                                                            terceraVariableLabel={values.service_location_type_id.service_location_code === 'LOCAL' ? 'TARIFA LOCAL' : 'TARIFA FORÁNEA ESTABLECIDA'}
                                                            terceraVariableKey='grua_subtotal'
                                                            disabled={disabled}
                                                        />
                                                    </tbody>
                                                )}
                                            </>



                                        )}


                                        {values.auxilio_vial && (
                                            <>
                                                {values.service_location_type_id.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false ? (

                                                    <tbody key="montos_aux_vial">
                                                        <OrderAmountsFieldArrayGenerico
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                            handleChange={handleChange}
                                                            errors={errors}
                                                            tipoServicio='AUXILIO VIAL'
                                                            primeraVariableLabel='TARIFA POR KILÓMETRO'
                                                            segundaVariableLabel='KILOMETRAJE'
                                                            segundaVariableSufix='km.'
                                                            terceraVariableLabel='BANDERAZO'
                                                            cuartaVariableLabel='CASETAS'
                                                            primeraVariableKey='aux_vial_tarifa_kilometro'
                                                            segundaVariableKey='km'
                                                            terceraVariableKey='aux_vial_banderazo'
                                                            cuartaVariableKey='aux_vial_casetas'
                                                            subTotalKeyName='aux_vial_subtotal'
                                                            tarifasCalculadas={tarifasAuxVialCalculadas}
                                                            tarifasCasetas={tarifasCasetas}
                                                            disabled={disabled}
                                                        />

                                                    </tbody>

                                                ) : (
                                                    <tbody key="montos_grua_local">
                                                        <OrderAmountsFieldArrayGenerico
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                            handleChange={handleChange}
                                                            errors={errors}
                                                            tipoServicio='AUXILIO VIAL'
                                                            terceraVariableLabel={values.service_location_type_id.service_location_code === 'LOCAL' ? 'TARIFA LOCAL' : 'TARIFA FORÁNEA ESTABLECIDA'}
                                                            terceraVariableKey='aux_vial_subtotal'
                                                            disabled={disabled}
                                                        />
                                                    </tbody>
                                                )}
                                            </>
                                        )}


                                        {values.montacarga === true && (
                                            <>
                                                {values.manejar_tarifas_establecidas === false ? (
                                                    
                                                    <tbody key="montos_montacarga_calculo">
                                                        <OrderAmountsFieldArrayGenerico
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                            handleChange={handleChange}
                                                            errors={errors}
                                                            tipoServicio='MONTACARGA'
                                                            primeraVariableLabel='TARIFA POR HORA O FRACCIÓN'
                                                            segundaVariableLabel='HORAS'
                                                            segundaVariableSufix='horas'
                                                            terceraVariableLabel='TRASLADO'
                                                            primeraVariableKey='montacargas_costo_hora'
                                                            segundaVariableKey='montacargas_horas'
                                                            terceraVariableKey='montacargas_traslado'
                                                            subTotalKeyName='montacargas_subtotal'
                                                            casetasKey={null}
                                                            tarifasCalculadas={tarifasAuxVialCalculadas}
                                                            tarifasCasetas={tarifasCasetas}
                                                            disabled={disabled}
                                                        />
                                                    </tbody>
                                                ) : (
                                                    <tbody key="montos_montacarga_tarifa_fija">
                                                        <OrderAmountsFieldArrayGenerico
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                            handleChange={handleChange}
                                                            errors={errors}
                                                            tipoServicio='MONTACARGA'
                                                            terceraVariableLabel={'TARIFA ESTABLECIDA'}
                                                            terceraVariableKey='montacargas_subtotal'
                                                            disabled={disabled}
                                                        />
                                                    </tbody>
                                                )
                                                }
                                            </>
                                        )}


                                        {values.corresponsalia && (
                                            <tbody key="montos_corresponsalia">
                                                <OrderAmountsFieldArrayGenerico
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                    handleChange={handleChange}
                                                    errors={errors}
                                                    tipoServicio='CORRESPONSALÍA'
                                                    terceraVariableLabel='GASTOS CORRESPONSALÍA'
                                                    terceraVariableKey='corresponsalia_subtotal'
                                                    disabled={disabled}
                                                />
                                                {/*
                                                    <OrderAmountsFieldArrayCorresponsalia
                                                        values={values}
                                                        handleChange={handleChange}
                                                        errors={errors}
                                                    />
                                                     */}
                                            </tbody>

                                        )}


                                        {values.someWithShielding && (
                                            <tbody key="montos_shielding">
                                                <OrderAmountsFieldArrayGenerico
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                    handleChange={handleChange}
                                                    errors={errors}
                                                    tipoServicio='BLINDAJE'
                                                    terceraVariableLabel='BLINDAJE'
                                                    terceraVariableKey='monto_blindaje'
                                                    disabled={disabled}
                                                />
                                            </tbody>
                                        )}

                                        {(values.someWithCarga || values.someWithVolumen) && (
                                            <tbody key="montos_carga_volumen">
                                                <OrderAmountsFieldArrayGenerico
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                    handleChange={handleChange}
                                                    errors={errors}
                                                    tipoServicio={`${values.someWithCarga && values.someWithVolumen ? 'CARGA Y VOLUMEN' : values.someWithCarga ? 'CARGA' : 'VOLUMEN'}`}
                                                    terceraVariableLabel={`${values.someWithCarga && values.someWithVolumen ? 'CARGA Y VOLUMEN' : values.someWithCarga ? 'CARGA' : 'VOLUMEN'}`}
                                                    terceraVariableKey='monto_carga_volumen'
                                                    disabled={disabled}
                                                />
                                            </tbody>
                                        )}

                                        {montosExtras && montosExtras.length > 0
                                            ? montosExtras.map((montoExtra, index) => (

                                                <OrderAmountsFieldArrayAdicional
                                                    key={`monto_extra_${index}`}
                                                    index={index}
                                                    values={values}
                                                    errors={errors}
                                                    arrayHelpers={arrayHelpers}
                                                    montosExtras={montosExtras}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                    disabled={disabled}
                                                />

                                            ))
                                            : null
                                        }
                                    </table>
                                </div>
                            </div>
                        </Row>


                        {!disabled && (
                            <Row className='mb-3'>
                                <div className="col-md-12">
                                    <div className="d-flex align-items-center float-end">
                                        <div >
                                            <button
                                                type="button"
                                                className="btn btn-success btn-icon btn-circle btn-sm "

                                                onClick={() =>
                                                    arrayHelpers.push({
                                                        id: "",
                                                        monto_extra_descripcion: "",
                                                        monto_extra_monto: 0,
                                                    })
                                                }
                                            >
                                                <i className="fa-solid fa-plus"></i>
                                            </button>

                                        </div>
                                        <div className='ms-1'>
                                            Agregar Adicional
                                        </div>
                                    </div>

                                </div>
                            </Row>
                        )}


                        <Row>
                            <table className="table table-amount">
                                <thead>
                                    <tr>
                                        <th width="10%"></th>
                                        <th width="30%"></th>
                                        <th width="30%"></th>
                                        <th width="30%"></th>
                                    </tr>
                                </thead>
                                {1 === 0 && values?.client_type_id?.client_type_code !== 'PARTICULAR' && (values.tipo_pago_id.code !== 'CONTADO') && (
                                    <tbody key="montos_excedente_asegurado">
                                        <OrderAmountsFieldArrayGenerico
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                            handleChange={handleChange}
                                            errors={errors}
                                            tipoServicio='EXCEDENTE ASEGURADO (NETO)'
                                            terceraVariableLabel='EXCEDENTE ASEGURADO (NETO)'
                                            terceraVariableKey='excedente_asegurado'
                                            disabled={values.orderStatuses?.PAGADO_CONTADO?.has_passed_status}
                                        />
                                    </tbody>
                                )}
                            </table>


                        </Row>

                    </>
                );
            }}
        />
    );
}

export default OrderAmountsFieldArray;