import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import axiosClient from '../../../../config/axios.js';
import OrderTimelineItem from './orderTimelineItem.js'

function OrderHistory() {
    const { id } = useParams();
    const [orderHistories, setOrderHistories] = useState([]);
    const [orderData, setOrderData] = useState(null);

    useEffect(() => {
        axiosClient.get(`/orderStatuses/getOrderStatusHistoriesFromOrder/${id}`).then(response => {
            setOrderHistories(response.data.data);
        }).catch(error => {
            console.error('Error fetching order data:', error);
        });

        axiosClient.get(`/orders/${id}`).then(response => { 
            setOrderData(response.data);
        }).catch(error => {
            console.error('Error fetching order data:', error);
        });
    }, [id]);

    return (
        <>


            <div>
                <div className="d-flex align-items-center mb-3">
                    <div>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                            <li className="breadcrumb-item"><Link to="/orders">Órdenes</Link></li>
                            <li className="breadcrumb-item active">Historial de movimientos</li>
                        </ol>
                        <h1 className="page-header mb-0">Historial de movimientos</h1>
                    </div>
                </div>



                <Row>
                    <div className="timeline">
                        {orderData && orderHistories && orderHistories.map((orderHistory, index) => (
                            <OrderTimelineItem
                                key={index}
                                orderHistory={orderHistory}
                                orderData={orderData}
                            />
                        ))}
                    </div>
                </Row>

            </div>
        </>


    );
}

export default OrderHistory;