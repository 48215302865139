import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useRoutes, useNavigate } from "react-router-dom";
import AppRoute from './config/app-route.jsx';
import { AuthProvider, useAuth } from './context/AuthContext.js';
import AxiosResponseInterceptor from './config/AxiosResponseInterceptor.js';

// bootstrap
import 'bootstrap';

// css
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import 'bootstrap-social/bootstrap-social.css';


const container = document.getElementById('root');
const root = createRoot(container);

function App() {

	const navigate = useNavigate();
	const { token } = useAuth();

	useEffect(() => {
		if (!token || token === 'null') {
			console.log("Token inválido");
			navigate('/login');
		}
	}, [token, navigate]);

	let element = useRoutes(AppRoute);

	return element;
}

root.render(
	<AuthProvider>
		<BrowserRouter>
			<App />
			<AxiosResponseInterceptor />
		</BrowserRouter>

	</AuthProvider>
);
