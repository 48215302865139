import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import VehicleModelFields from './vehicleModelFields.js';


function EditVehicleModel() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { Formik } = formik;
    
    const [initialValues, setInitialValues] = useState({
        model_name: '',
        brand_id: '',
        vehicle_type_id: ''
    });

    useEffect(() => {
        axiosClient.get(`/vehicleModels/${id}`).then(response => {
                const { model_name, brand_id, vehicle_type_id } = response.data;
                setInitialValues({ model_name, brand_id, vehicle_type_id });
            }).catch(error => {
                console.error('Error fetching vehicleModel data:', error);
            });
    }, [id]);

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/vehicleModels">Modelos</Link></li>
                        <li className="breadcrumb-item active">Edición de Modelo</li>
                    </ol>
                    <h1 className="page-header mb-0">Edición de Modelo</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Edición de Modelo</PanelHeader>
                        <PanelBody>
                            <Formik
                                enableReinitialize
                                validationSchema={yup.object().shape({
                                    model_name: yup.string().required("Ingrese el nombre del Modelo"),
                                    brand_id: yup.number().required("Seleccione la marca del vehículo"),
                                    vehicle_type_id: yup.number().required("Seleccione el tipo de vehículo"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.put(`/vehicleModels/${id}`, values).then(response => {
                                        Swal.fire({
                                            title: 'Cambios guardados.',
                                            text: 'El modelo se ha actualizado exitosamente',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/brandsModels');
                                    }).catch(err => {
                                        let message = '';
                                        console.log(err);
                                        if (err.response.data.original.code === '23505') {
                                            message = 'El nombre del modelo ya existe. Ingresa uno diferente.';
                                        } else {
                                            message = err.response.data.message;
                                        }
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={initialValues}
                            >
                                {({ handleSubmit, handleChange, values, setFieldValue, errors, touched }) => (
                                    <Form noValidate onSubmit={handleSubmit}>

                                        <VehicleModelFields
                                            values={values}
                                            handleChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                        />

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar Modelo</Button>{' '}
                                                <Link to={'/catalogs/brandsModels'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div >

            </div >



        </div >

    );
} 
 
export default EditVehicleModel;