import { Link, useNavigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import ShieldingTypeFields from './shieldingTypeFields';


function NewCraneType() {
    const navigate = useNavigate();
    const { Formik } = formik;

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/crane-types">Categoría de grúas</Link></li>
                        <li className="breadcrumb-item active">Nueva Categoría de grúa</li>
                    </ol>
                    <h1 className="page-header mb-0">Registro de nueva Categoría de grúa</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Registro de nueva Categoría de grúa</PanelHeader>
                        <PanelBody>
                            <Formik
                                validationSchema={yup.object().shape({
                                    shielding_type: yup.string().required("Ingrese el nombre de la Categoría de Blindaje"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.post('/shieldingTypes/save', values).then(response => {
                                        
                                        Swal.fire({
                                            title: 'Registro exitoso.',
                                            text: 'Se ha registrado la nueva Categoría de grúa',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/shieldingTypes');
                                    }).catch(err => {
                                        console.log(err.response.data);
                                        
                                        let message = '';
                                        if (err.response.data.original.code === '23505') {
                                            message = 'El nombre de la Categoría de grúa ya existe. Ingresa uno diferente.';
                                        } else {
                                            message = err.response.data.message;
                                        }
                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={{
                                    shielding_type: '',
                                }}
                            >

                                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched }) => (
                                    <Form noValidate onSubmit={handleSubmit} encType="multipart/form-data">

                                        <ShieldingTypeFields
                                            values={values}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                            touched={touched}
                                            errors={errors}
                                        />

                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar Categoría de Blindaje</Button>{' '}
                                                <Link to={'/catalogs/shieldingTypes'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default NewCraneType;