import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';


function LiftTruckFields({values, handleChange, errors}) {
    const navigate = useNavigate();
    const { Formik } = formik;

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Número de Montacarga</Form.Label>
                    <Form.Control
                        type="text"
                        name="lift_truck_number"
                        placeholder="Número de Montacarga"
                        value={values.lift_truck_number}
                        onChange={handleChange}
                        isInvalid={!!errors.lift_truck_number}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.lift_truck_number}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                        type="text"
                        name="description"
                        placeholder="Descripción"
                        value={values.description}
                        onChange={handleChange}
                        isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.description}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
        </>

    );
}

export default LiftTruckFields;