import React from 'react';
import Select from 'react-select';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CurrencyInput from 'react-currency-input-field';
import InputGroup from 'react-bootstrap/InputGroup';
import { ErrorMessage, FieldArray } from 'formik';

function OrderAmountsVariables({ gruas, operadores, montacargas, vehiculosAsistencia, values, handleChange, setFieldTouched, setFieldValue, errors }) {

    const handleSwitchChange = (index, encargado) => {
        if (!encargado) {
            setFieldValue(`operadores.${index}.encargado`, false);
        } else {
            const newOperadores = values.operadores.map((operador, idx) => ({
                ...operador,
                encargado: idx === index,
            }));
            setFieldValue('operadores', newOperadores);
        }
    };

    return (
        <>
            {values.service_location_type_id.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false && (
                <Row className="mb-3">

                    <Form.Group as={Col} md="12" >
                        <Form.Label>Distancia ajustada para tarifa (km.)</Form.Label>
                        <InputGroup >

                            <CurrencyInput
                                autoComplete='off'
                                allowDecimals={false}
                                className={`form-control ${errors.km ? 'is-invalid' : ''}`}
                                prefix=""
                                allowNegativeValue={false}
                                decimalsLimit={2}  // Limita la cantidad de decimales a 2
                                decimalScale={2}
                                decimalSeparator="."
                                groupSeparator=","
                                name="km"
                                value={values.km}
                                onValueChange={(value) => setFieldValue('km', value)}
                                disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                            />
                            <InputGroup.Text >km.</InputGroup.Text>
                        </InputGroup>
                        <span className='invalid-feedback' style={{ display: 'block' }}>
                            <ErrorMessage name="km" />
                        </span>

                    </Form.Group>


                </Row>
            )}




            {(values.someWithShielding || values.someWithCarga || values.someWithVolumen) && (
                <Row className='mb-1'>
                    <div className="col-md-6 pb-2">
                        <div className="alert alert-warning alert-dismissible fade show mb-0">
                            Se encuentra registrado al menos un vehículo con blindaje, carga y/o volumen
                            <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
                        </div>
                    </div>

                </Row>
            )}


            <Row className="mb-3" >


                <Form.Group as={Col} md="6" className="mb-3">
                    <Form.Label>{values.montacarga ? 'Grúa que trasladará el montacargas' : 'Grúa No.'}</Form.Label>
                    <Select
                        name="crane_id"
                        options={gruas}
                        className={errors.crane_id ? 'is-invalid' : ''}
                        placeholder="Selecciona una grúa"
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        isClearable={true}
                        isSearchable={true}
                        value={values.crane_id}
                        onChange={(e) => {
                            setFieldTouched("crane_id", true);
                            setFieldValue("crane_id", e);
                        }}
                        isDisabled={values.orderStatuses?.CIERRE?.has_passed_status}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.crane_id}
                    </Form.Control.Feedback>


                </Form.Group>

                {values.auxilio_vial ?
                    <Form.Group as={Col} md="6" name="orderAmountsVariables_vehiculoAsistenciaVial">
                        <Form.Label>Vehículo{values.vehiculos_asistencia && values.vehiculos_asistencia.length > 1 ? 's' : ''} de asistencia Vial</Form.Label>
                        <Select
                            name="vehiculos_asistencia"
                            options={vehiculosAsistencia}
                            className={errors.vehiculos_asistencia ? 'is-invalid' : ''}
                            placeholder="Selecciona uno o más vehículos de asistencia"
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            isClearable={true}
                            isSearchable={true}
                            value={values.vehiculos_asistencia}
                            isMulti
                            onChange={(e) => {
                                setFieldValue("vehiculos_asistencia", e ? e : '');
                            }}
                            isDisabled={values.orderStatuses?.CIERRE?.has_passed_status}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.vehiculos_asistencia}
                        </Form.Control.Feedback>

                    </Form.Group>
                    : ''
                }

                {values.montacarga ?
                    <Form.Group as={Col} md="6" name="orderAmountsVariables_sinAsignarMontacargas">
                        <Form.Label>Montacargas</Form.Label>
                        <Select
                            name="montacargas"
                            options={montacargas}
                            className={errors.montacargas ? 'is-invalid' : ''}
                            placeholder="Selecciona uno o más montacargas"
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            isClearable={true}
                            isSearchable={true}
                            value={values.montacargas}
                            isMulti
                            onChange={(e) => {
                                setFieldValue("montacargas", e ? e : '');
                            }}
                            isDisabled={values.orderStatuses?.ENVIADA?.has_passed_status}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.montacargas}
                        </Form.Control.Feedback>

                    </Form.Group>
                    : ''
                }


                <Form.Group as={Col} md="6" name="orderAmountsVariables_sinAsignarOperador">
                    <Form.Label>Operador{`${values.operadores && values.operadores.length > 1 ? 'es' : ''}`}</Form.Label>
                    <Select
                        name="operadores"
                        options={operadores}
                        className={errors.operadores ? 'is-invalid' : ''}
                        placeholder="Selecciona uno o más operadores"
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        isClearable={true}
                        isSearchable={true}
                        value={values.operadores}
                        isMulti
                        onChange={(e) => {
                            setFieldValue("operadores", e ? e : '');
                        }}
                        isDisabled={values.orderStatuses?.CIERRE?.has_passed_status}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.operadores}
                    </Form.Control.Feedback>

                    {values?.operadores?.length > 1 && (
                        <Row>

                            <FieldArray
                                name="operadores_array"
                                render={arrayHelpers => {
                                    const operadores = values.operadores;
                                    return (
                                        <Col sm='12'>
                                            <Row className='mt-2'>
                                                <Col sm='1'>
                                                    <label>Encargado</label>
                                                </Col>
                                            </Row>
                                            {operadores && operadores.length > 0
                                                ? operadores.map((operador, index) => (
                                                    <Row className='mt-2' key={`row_activ_operador_${index}`}>
                                                        <Col sm='1'>
                                                            <Form.Check
                                                                name={`operadores.${index}.encargado`}
                                                                type="switch"
                                                                label=""
                                                                checked={values.operadores[index].encargado}
                                                                onChange={(e) => {
                                                                    setFieldTouched(`operadores.${index}.encargado`, true);
                                                                    //setFieldValue(`operadores.${index}.encargado`, e.target.checked);
                                                                    handleSwitchChange(index, e.target.checked);
                                                                }}
                                                                disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                                            />
                                                        </Col>
                                                        <Col sm='4'>
                                                            <label className="form-label col-form-label text-end float-end">{operador?.label}</label>
                                                        </Col>
                                                        <Col sm='7'>
                                                            <textarea
                                                                name={`operadores.${index}.actividad`}
                                                                className={`form-control ${errors.operadores && errors.operadores[index] && errors.operadores[index].actividad ? 'is-invalid' : ''}`}
                                                                placeholder='Actividad realizada por el operador en la presente Orden'
                                                                onChange={(e) => {
                                                                    setFieldValue(`operadores.${index}.actividad`, e.target.value);
                                                                }}
                                                                value={values.operadores[index].actividad}
                                                                disabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}

                                                            ></textarea>
                                                        </Col>
                                                    </Row>
                                                ))
                                                : null
                                            }

                                        </Col>
                                    )
                                }}
                            />
                        </Row>
                    )}

                </Form.Group>

            </Row>



        </>
    );
}

export default OrderAmountsVariables;