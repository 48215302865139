import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import './ComponentToPrint.css';

function OrderContactoPrint({ orderData }) {

    return (


        <form className="row row-cols-sm-auto align-items-center mb-0 mt-0" action="/" method="POST">


            <Col sm='2'>
                <div className="form-floating ">
                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.client_type_id?.label} />
                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">TIPO DE CLIENTE</label>
                </div>
            </Col>


            {orderData?.client_type_id?.client_type_code === 'ASEGURADORA' && (

                <Col sm='2'>
                    <div className="form-floating ">
                        <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.insurance_id?.label} />
                        <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">ASEGURADORA</label>
                    </div>
                </Col>

            )}

            {orderData?.cliente_vip && (
                <div className="form-check mt-0 mb-0 col-sm-2">
                    <input className="form-check-input my-small-input mt-1" type="checkbox" onChange={() => { }} checked={orderData?.cliente_vip ? true : false} />
                    <label className="form-check-label fs-9px" htmlFor="flexCheckDefault">
                        CLIENTE VIP
                    </label>
                </div>
            )}

            <Col sm='2'>
                <div className="form-floating ">
                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.numero_poliza} />
                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">PÓLIZA</label>
                </div>
            </Col>
            <Col sm='2'>
                <div className="form-floating ">
                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.numero_siniestro} />
                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">SINIESTRO</label>
                </div>
            </Col>

            <Col sm='2'>
                <div className="form-floating ">
                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.numero_folio} />
                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">FOLIO</label>
                </div>
            </Col>


            <Col sm='4'>
                <div className="form-floating ">
                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.beneficiario_servicio} />
                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">BENEFICIARIO</label>
                </div>
            </Col>

            <Col sm='4'>
                <div className="form-floating ">
                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.empresa} />
                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">EMPRESA</label>
                </div>
            </Col>

            <Col sm='3'>
                <div className="form-floating">
                    <div className="input-group smaller">
                        <div className="input-group-text"><i className="fa fa-phone"></i></div>
                        <input type="text" className=" form-control bg-white smaller my-small-floating-input form-print" disabled value={orderData?.telefono_principal} />
                    </div>
                    <label htmlFor="floatingInput" className="d-flex align-items-center floating-grouped-icon-left form-print">TELÉFONO</label>
                </div>

            </Col>


            <Col sm='4'>
                <div className="form-floating ">
                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.autorizacion_cabina} />
                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">AUTORIZACIÓN DE CABINA</label>
                </div>
            </Col>

            <Col sm='2'>
                <div className="form-floating ">
                    <input type="text" className="form-control my-small-floating-input form-print" id="floatingInput" disabled value={orderData?.tipo_pago_id?.label} />
                    <label htmlFor="floatingInput" className="d-flex align-items-center my-small-label-floating-input form-print">TIPO DE PAGO</label>
                </div>
            </Col>
            {orderData?.datos_contacto_seguimiento_observaciones && (
                <Col sm='6'>
                    <Form.Group as={Col} md="12" className='simple-text'>
                        <Form.Label>OBSERVACIONES SOBRE LOS DATOS DE CONTACTO:</Form.Label>
                        <span>{' ' + orderData?.datos_contacto_seguimiento_observaciones}</span>
                    </Form.Group>
                </Col>
            )}

        </form>
    );
}

export default OrderContactoPrint;