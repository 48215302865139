import React from 'react';

import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import { Panel, PanelBody } from '../../../../components/panel/panel';


function OrderStatusEvaluadoFields({ values, setFieldValue, errors }) {
    const { id } = useParams();
    const { Formik } = formik;

    const handleRatingClick = (field, value, setFieldValue) => {
        setFieldValue(field, value);
    };

    return (
        <>
            


            {values.confirmacion_evaluacion_realizada && (
                <>
                    <Panel>
                        <PanelBody>
                            <Row className="mb-3">
                                <h5>
                                    Elementos entregados al beneficiario del servicio
                                </h5>
                            </Row>

                            <Row className="mb-2">
                                <Form.Group as={Col} md="3" sm="4" xs="12" name="orderEvaluation">
                                    <Form.Check
                                        name="extinguidor"
                                        type="switch"
                                        label="Extinguidor"
                                        checked={values.extinguidor}
                                        onChange={(e) => {
                                            setFieldValue('extinguidor', e.target.checked);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="3" sm="4" xs="12">
                                    <Form.Check
                                        name="llanta_refaccion"
                                        type="switch"
                                        label="Llanta de refacción"
                                        checked={values.llanta_refaccion}
                                        onChange={(e) => {
                                            setFieldValue('llanta_refaccion', e.target.checked);
                                        }}

                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="3" sm="4" xs="12">
                                    <Form.Check
                                        name="gato"
                                        type="switch"
                                        label="Gato"
                                        checked={values.gato}
                                        onChange={(e) => {
                                            setFieldValue('gato', e.target.checked);
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="3" sm="4" xs="12">
                                    <Form.Check
                                        name="cable_pasa_corriente"
                                        type="switch"
                                        label="Cable pasa corriente"
                                        checked={values.cable_pasa_corriente}
                                        onChange={(e) => {
                                            setFieldValue('cable_pasa_corriente', e.target.checked);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="3" sm="4" xs="12">
                                    <Form.Check
                                        name="senalamientos"
                                        type="switch"
                                        label="Señalamientos"
                                        checked={values.senalamientos}
                                        onChange={(e) => {
                                            setFieldValue('senalamientos', e.target.checked);
                                        }}
                                    />
                                </Form.Group>

                            </Row>

                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" sm="12" xs="12">
                                    <Form.Group as={Col} md="12">
                                        <Form.Check
                                            name="herramientas"
                                            type="switch"
                                            label={`Herramientas${values.herramientas ? '. Especifique:' : ''}`}
                                            checked={values.herramientas}
                                            onChange={(e) => {
                                                setFieldValue('herramientas', e.target.checked);
                                            }}
                                        />
                                    </Form.Group>
                                    {values.herramientas && (
                                        <Form.Group as={Col} md="12">

                                            <textarea
                                                name="herramientas_observaciones"
                                                className={`form-control ${errors.herramientas_observaciones ? 'is-invalid' : ''}`}
                                                onChange={(e) => {
                                                    setFieldValue('herramientas_observaciones', e.target.value);
                                                }}
                                                value={values.herramientas_observaciones}
                                            ></textarea>

                                            <Form.Control.Feedback type="invalid">
                                                {errors.herramientas_observaciones}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    )}
                                </Form.Group>
                            </Row>

                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" sm="12" xs="12">
                                    <Form.Group as={Col} md="12">
                                        <Form.Check
                                            name="otros"
                                            type="switch"
                                            label={`Otros${values.otros ? '. Especifique:' : ''}`}
                                            checked={values.otros}
                                            onChange={(e) => {
                                                setFieldValue('otros', e.target.checked);
                                            }}
                                        />
                                    </Form.Group>
                                    {values.otros && (
                                        <Form.Group as={Col} md="12">

                                            <textarea
                                                name="otros_observaciones"
                                                className={`form-control ${errors.otros_observaciones ? 'is-invalid' : ''}`}
                                                onChange={(e) => {
                                                    setFieldValue('otros_observaciones', e.target.value);
                                                }}
                                                value={values.otros_observaciones}
                                            ></textarea>

                                            <Form.Control.Feedback type="invalid">
                                                {errors.otros_observaciones}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    )}
                                </Form.Group>

                            </Row>
                        </PanelBody>
                    </Panel>


                    <Panel>
                        <PanelBody className="bg-light rounded-bottom">
                            <Row className="mb-3">
                                {errors && Object.keys(errors).length > 0 && (
                                    <div className="alert alert-warning fade show">Todas las calificaciones son obligatorias</div>
                                )}

                                <fieldset>
                                    <div className="row mb-3">
                                        <label className="form-label col-form-label col-md-5">Quedó satisfecho con el servicio</label>

                                        <div className="col-md-7">
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.satisfecho === 1 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('satisfecho', 1, setFieldValue)}><i className="fa-solid fa-face-frown"></i></button>
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.satisfecho === 2 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('satisfecho', 2, setFieldValue)}><i className="fa-solid fa-face-meh"></i></button>
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.satisfecho === 3 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('satisfecho', 3, setFieldValue)}><i className="fa-solid fa-face-grin-wide"></i></button>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="form-label col-form-label col-md-5">Operador amable y profesional</label>
                                        <div className="col-md-7">
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.operador_amable === 1 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('operador_amable', 1, setFieldValue)}><i className="fa-solid fa-face-frown"></i></button>
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.operador_amable === 2 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('operador_amable', 2, setFieldValue)}><i className="fa-solid fa-face-meh"></i></button>
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.operador_amable === 3 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('operador_amable', 3, setFieldValue)}><i className="fa-solid fa-face-grin-wide"></i></button>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="form-label col-form-label col-md-5">Grúa limpia y equipada</label>
                                        <div className="col-md-7">
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.grua_limpia === 1 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('grua_limpia', 1, setFieldValue)}><i className="fa-solid fa-face-frown"></i></button>
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.grua_limpia === 2 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('grua_limpia', 2, setFieldValue)}><i className="fa-solid fa-face-meh"></i></button>
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.grua_limpia === 3 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('grua_limpia', 3, setFieldValue)}><i className="fa-solid fa-face-grin-wide"></i></button>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="form-label col-form-label col-md-5">Recomendaría nuestro servicio</label>
                                        <div className="col-md-7">
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.recomendaria_servicio === 1 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('recomendaria_servicio', 1, setFieldValue)}><i className="fa-solid fa-face-frown"></i></button>
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.recomendaria_servicio === 2 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('recomendaria_servicio', 2, setFieldValue)}><i className="fa-solid fa-face-meh"></i></button>
                                            <button type="button" className={`btn default btn-icon btn-circle btn-lg me-2 btn-${values.recomendaria_servicio === 3 ? 'primary' : 'default'}`} onClick={() => handleRatingClick('recomendaria_servicio', 3, setFieldValue)}><i className="fa-solid fa-face-grin-wide"></i></button>
                                        </div>
                                    </div>
                                </fieldset>


                            </Row>

                        </PanelBody>
                    </Panel>


                </>
            )}

            

        </>
    );
}

export default OrderStatusEvaluadoFields;