import { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

const AxiosResponseInterceptor = () => {
    const { logout } = useAuth();

    useEffect(() => {

        const interceptor = axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                
                if (error.response && error.response.status === 401) {
                    logout();
                }

                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, [logout]);

    return null;
};

export default AxiosResponseInterceptor;