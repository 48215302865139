import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import axiosClient from '../../../../config/axios.js';
import FormLiftTruck from './liftTruckFields';
import LiftTruckFields from './liftTruckFields';


function NewLiftTruck() {
    const navigate = useNavigate();
    const { Formik } = formik;


    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                        <li className="breadcrumb-item">Configuración</li>
                        <li className="breadcrumb-item"><Link to="/catalogs/liftTrucks">Montacargas</Link></li>
                        <li className="breadcrumb-item active">Nuevo Montacargas</li>
                    </ol>
                    <h1 className="page-header mb-0">Registro de nuevo Montacargas</h1>
                </div>
            </div>



            <div className="row mb-3">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Registro de nuevo Montacargas</PanelHeader>
                        <PanelBody>
                            <Formik
                                validationSchema={yup.object().shape({
                                    lift_truck_number: yup.number("El número de Montacargas debe ser un número").required("Ingrese el número de Montacargas"),
                                    description: yup.string().required("Ingrese la descripción del Montacargas"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    axiosClient.post('/liftTrucks/save', values).then(response => {
                                        Swal.fire({
                                            title: 'Registro exitoso.',
                                            text: 'Se ha registrado el nuevo Montacargas',
                                            icon: 'success',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                        navigate('/catalogs/liftTrucks');
                                    }).catch(err => {
                                        console.log(err);

                                        let message = err.response.data.message;

                                        Swal.fire({
                                            title: 'Ups!',
                                            text: message,
                                            icon: 'error',
                                            confirmButtonText: 'Cerrar',
                                            cancelButtonText: 'Cancelar',
                                            showCancelButton: false,
                                        });
                                    });
                                }}
                                initialValues={{
                                    lift_truck_number: '',
                                    description: ''
                                }}
                            >
                                {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>

                                        <LiftTruckFields
                                            values={values}
                                            handleChange={handleChange}
                                            errors={errors}
                                        />



                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button type="submit">Guardar Montacargas</Button>{' '}
                                                <Link to={'/catalogs/liftTrucks'} className="btn btn-info">Cancelar</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>

                        </PanelBody>
                    </Panel>
                </div>

            </div>


        </div>

    );
}

export default NewLiftTruck;