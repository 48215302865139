import React, { useEffect, useState } from 'react';
import axiosClient from '../../../../config/axios.js';

import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';

function VehicleModels() {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortField, setSortField] = useState('id'); // Campo de ordenamiento predeterminado
	const [sortDirection, setSortDirection] = useState('asc'); // Sentido de orden predeterminado



	const deleteVehicleModel = id => {
		Swal.fire({
			title: '¿Está seguro que desea borrar el Modelo de Vehículo seleccionado?',
			text: "Una vez que se haya borrado el Modelo de Vehículo, no podrá recuperarlo.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí, eliminar Modelo de Vehículo',
			cancelButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
				axiosClient.delete(`/vehicleModels/${id}`).then(response => {
					fetchData();
					Swal.fire(
						'Modelo de Vehículo borrada',
						'El Modelo de Vehículo se ha borrado exitosamente',
						'success'
					)
				})

			}
		})
	}

	const fetchData = async (currentPage = 1, search = '', sortField, sortDirection) => {
		try {
			const response = await axiosClient.get(`/vehicleModels/datatable`, {
				params: {
					start: (currentPage - 1) * rowsPerPage,
					length: rowsPerPage,
					search: search,
					sort: sortField,
					dir: sortDirection,
				},
			});

			setData(response.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setLoading(true);
		fetchData(currentPage, searchValue, sortField, sortDirection);
	}, [currentPage, rowsPerPage, sortField, sortDirection]);

	const columns = [
		{ name: 'ID', selector: (row, i) => row.id, sortable: true, sortField: 'id' },
		{ name: 'Tipo de vehículo', selector: (row, i) => row.VehicleType.vehicle_type_name, sortable: true, sortField: 'vehicle_type_name' },
		{ name: 'Marca', selector: (row, i) => row.Brand.brand_name, sortable: true, sortField: 'brand_name' },
		{ name: 'Modelo de Vehículo', selector: (row, i) => row.model_name, sortable: true, sortField: 'model_name' },
		{
			name: 'Editar', button: true,
			cell: row => <Link to={`/catalogs/models/edit/${row.id}`}><i className='fa fa-pencil' /></Link>
		},
		{
			name: 'Eliminar', button: true,
			cell: row => <a href="#" onClick={() => deleteVehicleModel(row.id)}><i className='fa fa-trash' /></a>
		}
	];

	const customStyles = {
		headCells: {
			style: {
				color: '#202124',
				fontSize: '14px',
				backgroundColor: '#EEEEEE',
				border: 'none'
			},
		}
	};

	const paginationComponentOptions = {
		rowsPerPageText: 'Filas por página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: false,
		selectAllRowsItemText: 'Todos',
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			setLoading(true);
			setCurrentPage(1);
			fetchData(currentPage, searchValue, sortField, sortDirection);
		}
	};

	return (

		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<h1 className="page-header mb-0">Modelo de Vehículos</h1>
				</div>
				<div className="ms-auto">
					<Link to={'/catalogs/models/newVehicleModel'} className="btn btn-success btn-rounded px-4 rounded-pill"><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Nuevo Modelo de Vehículo</Link>
				</div>
			</div>



			<div className="row mb-3">
				<div className="col-xl-12">
					
					<DataTable
						title=""
						className="table"
						columns={columns}
						data={data.data}
						progressPending={loading}
						sortServer
						pagination
						paginationServer
						paginationPerPage={rowsPerPage}
						paginationTotalRows={data.recordsTotal} // Esto es solo un valor estimado
						paginationDefaultPage={currentPage}
						subHeader
						customStyles={customStyles}
						pointerOnHover
						paginationComponentOptions={paginationComponentOptions}
						noDataComponent="Sin información por mostrar"
						progressComponent={
							<Spinner animation="border" brand="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						}
						subHeaderComponent={
							<div className='row'>
								<div className='col-sm-12 col-md-12'>
									<div className="input-group mb-3">
										<input
											type="text"
											className="form-control form-control-sm"
											placeholder="Buscar..."
											value={searchValue}
											onChange={e => setSearchValue(e.target.value)}
											onKeyPress={handleKeyPress}
										/>
										<div className="input-group-text btn btn-info"
											onClick={() => {
												setLoading(true);
												setCurrentPage(1);
												fetchData(currentPage, searchValue, sortField, sortDirection); // Realizar búsqueda aquí
											}}><i className="fa fa-search"></i></div>
									</div>
								</div>
							</div>
						}
						onChangePage={(page) => {
							setCurrentPage(page);
						}}
						onSearch={(value) => {
							setSearchValue(value);
							setLoading(true);
							setCurrentPage(1); // Resetea la página al hacer una búsqueda
						}}
						onChangeRowsPerPage={(newPerPage, page) => {
							setRowsPerPage(newPerPage);
							setCurrentPage(1); // Resetea la página al cambiar la cantidad de registros por página
						}}
						onSort={(column, sortDirection) => {
							setSortDirection(sortDirection);
							setSortField(column.sortField);
							setLoading(true);
							setCurrentPage(1); // Resetea la página al hacer una búsqueda
						}}


					/>
				</div>

			</div>


		</div>

	);
}

export default VehicleModels;