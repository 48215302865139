import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import MapEditPosition from '../../components/mapEditPosition';

function LocationFields({ values, handleChange, setFieldValue, touched, errors }) {

    const handleMapChange = (lat, lng) => {
        // Actualiza los valores del formulario usando setFieldValue de Formik
        setFieldValue('latitude', lat);
        setFieldValue('longitude', lng);
    };

    return (
        <>
            <Form.Group as={Col} md="8">
                <Form.Label>Nombre de la Ubicación</Form.Label>
                <Form.Control
                    type="text"
                    name="location_name"
                    placeholder="Nombre de la Ubicación"
                    value={values.location_name}
                    onChange={handleChange}
                    isInvalid={touched.location_name && !!errors.location_name}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.location_name}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
                <Form.Label>Distancia (km.)</Form.Label>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Distancia"
                        aria-label="Distancia"
                        name="distance"
                        value={values.distance}
                        onChange={handleChange}
                        isInvalid={touched.distance && !!errors.distance}
                    />
                    <InputGroup.Text>km.</InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                        {errors.distance}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>

            <MapEditPosition
                initialLat={values.latitude}
                initialLng={values.longitude}
                onChange={handleMapChange}
            />
        </>
    );
}

export default LocationFields;