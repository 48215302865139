import React from 'react';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function OrderFieldsRegistrarUbicacionOrigenDestino({ values, touched, handleChange, handleBlur, setFieldValue, errors }) {

    return (
        <>
            <Col sm={6}>
                {!values.id && values.origen_latitud && (
                    <>
                        <Row className="mb-3">

                            <Form.Group as={Col} className="mb-3" >
                                <Form.Check
                                    name="registrar_ubicacion_origen"
                                    type="switch"
                                    label="Registrar Ubicación del Origen (A)"
                                    checked={values.registrar_ubicacion_origen}
                                    onChange={(e) => {
                                        setFieldValue('registrar_ubicacion_origen', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            </Form.Group>
                        </Row>

                        {values.registrar_ubicacion_origen && (
                            <>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Nombre de la nueva ubicación de origen a registrar (A)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="nombre_ubicacion_origen"
                                            placeholder="Nombre de la ubicación"
                                            value={values.nombre_ubicacion_origen}
                                            onChange={handleChange}
                                            isInvalid={!!errors.nombre_ubicacion_origen}
                                            autoComplete="off"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.nombre_ubicacion_origen}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Distancia de la nueva ubicación de origen registrar (A)</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Distancia"
                                                aria-label="Distancia"
                                                name="distancia_ubicacion_origen"
                                                value={values.distancia_ubicacion_origen}
                                                onChange={handleChange}
                                                isInvalid={!!errors.distancia_ubicacion_origen}
                                            />
                                            <InputGroup.Text >km.</InputGroup.Text>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.distancia_ubicacion_origen}
                                            </Form.Control.Feedback>
                                        </InputGroup>

                                    </Form.Group>
                                </Row>
                            </>
                        )}

                    </>
                )}
            </Col>
            <Col sm={6}>
                {!values.id && values.destino_latitud && (
                    <>
                        <Row className="mb-3">

                            <Form.Group as={Col} className="mb-3" >
                                <Form.Check
                                    name="registrar_ubicacion_destino"
                                    type="switch"
                                    label="Registrar Ubicación del Destino (B)"
                                    checked={values.registrar_ubicacion_destino}
                                    onChange={(e) => {
                                        setFieldValue('registrar_ubicacion_destino', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            </Form.Group>
                        </Row>

                        {values.registrar_ubicacion_destino && (
                            <>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Nombre de la nueva ubicación de destino a registrar (B)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="nombre_ubicacion_destino"
                                            placeholder="Nombre de la ubicación"
                                            value={values.nombre_ubicacion_destino}
                                            onChange={handleChange}
                                            isInvalid={!!errors.nombre_ubicacion_destino}
                                            autoComplete="off"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.nombre_ubicacion_destino}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Distancia de la nueva ubicación de destino a registrar (B)</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Distancia"
                                                aria-label="Distancia"
                                                name="distancia_ubicacion_destino"
                                                value={values.distancia_ubicacion_destino}
                                                onChange={handleChange}
                                                isInvalid={!!errors.distancia_ubicacion_destino}
                                            />
                                            <InputGroup.Text >km.</InputGroup.Text>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.distancia_ubicacion_destino}
                                            </Form.Control.Feedback>
                                        </InputGroup>

                                    </Form.Group>
                                </Row>
                            </>
                        )}

                    </>
                )}
            </Col>
        </>
    );
}

export default OrderFieldsRegistrarUbicacionOrigenDestino;